@use "../../../master.scss";

.profile-picture-section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;
}

.edit-profile-picture-wrapper {
  height: 138px !important;
  width: 138px !important;
  position: relative;
  display: flex;
  max-width: fit-content;

  .profile-picture {
    border-radius: 75px;
    height: 138px !important;
    width: 138px !important;
  }

  .edit-img-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.account-update-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 38px;
}

.inputs-section {
  margin-top: 40px;

  .ant-form-item-has-error {
    .react-tel-input {
      .form-control {
        border-color: #ff4d4f;
      }
    }
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 12px;
      padding: 21px 45px;
      border-color: #f8f9fb;
      background: #f8f9fb;
      color: #70808b;
    }

    .form-control::placeholder {
      font-family: "Lato";
      font-weight: 500;
      font-size: 14px;

      color: #70808b;
    }

    .flag-dropdown {
      border-radius: 50px;
      border: none;
      margin: 5px;
      background: transparent;
    }

    .flag-dropdown:hover {
      background: #f8f9fb !important;
    }

    .selected-flag:hover {
      background: #f8f9fb;
    }

    .open {
      z-index: 3 !important;

      .selected-flag {
        background: #f8f9fb;
      }
    }
  }
}

.edit-account-form {

  // display: flex;
  // flex: 1;
  // flex-wrap: wrap;
  .child {
    // flex: 1 0 21%;
    margin-top: 10px;
  }
}

.work-address {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1c2b48;
  margin-top: 20px;
}

.width-full {
  width: 100%;
  margin-bottom: 20px;
}

.personal-info-heading {
  font-family: "Lato";
  font-weight: 700;
  font-size: 24px;
  color: #1c2b48;
  margin-bottom: 0;
}

.address-container {
  .address-card {
    width: 334px;
    max-width: 90%;
    height: 89px;
    border-radius: 15px;
    border: 1px solid rgba(28, 43, 72, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    margin-top: 20px;
    padding: 20px;

    .text {
      width: 72%;

      h4,
      p {
        font-family: "DM Sans";
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      h4 {
        font-size: 16px;
        color: #363645;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        color: #93969e;
      }
    }

    .icons {
      display: flex;
      gap: 10px;

      .edit {
        height: 23px;
        width: 23px;
      }
    }
  }
}

.add-address-icon {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;

  p {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #6f7ced;
  }
}

.edit-profile-button {
  .edit-profile-btn {
    padding: 0;
    padding: 12px 0px;
    transition: all 0.2s ease-in-out;

    label {
      padding: 12px 30px;
      cursor: pointer;
      font-family: "Poppins";
      transition: all 0.2s ease-in-out;
    }
  }

  .edit-profile-btn:hover {
    background-color: #6f7ced;

    label {
      color: white;
    }
  }
}

.two-factor-button {
  .edit-profile-btn {
    display: flex;
    align-items: center;
    padding: 0;
    padding: 12px 20px !important;
    transition: all 0.2s ease-in-out;
    // background-color: #6f7ced;
    // color: white;

    label {
      margin-right: 10px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-family: "Poppins";
    }

    .ant-switch {
      min-width: 84px !important;
      background: rgba(111, 124, 237, 0.1) !important;
      display: flex !important;
      align-items: center !important;
      padding: 6px 0 6px 10px !important;
      box-shadow: none !important;

      .ant-click-animating-node {
        box-shadow: none !important;
      }

      .ant-switch-inner {
        color: #000 !important;
        font-size: 10px !important;
        font-family: "Lato" !important;
        font-weight: 700;
      }

      .ant-switch-handle::before {
        background: #000 !important;
      }
    }

    .ant-switch-checked {
      .ant-switch-inner {
        color: #6f7ced !important;
      }

      .ant-switch-handle::before {
        background: #6f7ced !important;
      }
    }
  }

  // .edit-profile-btn:hover {
  //   label {
  //     color: #6f7ced;
  //   }

  //   background-color: white;
  // }
}