@use "../../master.scss";

footer {
  background-image: url(../../assets/images/home/footer_bg.png);
  background-size: cover;
  overflow: hidden;
  background-position: center top;

  .footer_wrapper {
    position: relative;

    .top_btn {
      position: absolute;
      top: 110px;
      left: 0;
      right: 0;
      width: 69px;
      height: 69px;
      margin: auto;
      cursor: pointer;
      z-index: 9;
    }

    .rights_sec {
      text-align: center;
      padding-top: 24px;
      position: relative;
      padding-bottom: 16px;

      p {
        color: #1c2b48;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
        text-transform: capitalize;
        opacity: 0.8;
        margin: 0;
      }
    }

    .social_link_wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 250px;

      p {
        margin: 0;
        margin-bottom: 36px;
        text-align: center;
        color: #1c2b48;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
        text-transform: capitalize;
        opacity: 0.8;
      }

      .logo {
        margin-bottom: 35px;
        width: 225px;

        img {
          width: 100%;
        }
      }

      .social_icons {
        display: flex;
        align-items: center;
        margin-bottom: 38px;
        column-gap: 30px;
        a,
        svg {
          width: 32px;
          height: 32px;
        }
      }

      hr {
        position: absolute;
        bottom: 0;
        margin: auto;
        background: #243d4c;
        opacity: 20%;
        position: absolute;
        margin: auto;
        opacity: 0.1;
        background: #243d4c;
        width: 190%;
      }
    }
  }
}
