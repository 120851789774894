.delete-address {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 20px 30px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .address-detail {
        position: relative;
        margin-top: 25px;
        background: #f8fafb;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        padding: 20px 18px;
        margin-bottom: 30px;

        h4 {
          font-weight: 600;
          font-size: 15px;
          padding-bottom: 5px;
          margin-bottom: 5px;
          color: #1c2b48;
          font-family: "Lato";
          border-bottom: 1px dashed rgba(28, 43, 72, 0.1);
        }

        p {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          color: rgb(28, 43, 72, 0.5);
        }

      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;

      .cancel-btn {
        cursor: pointer;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: rgb(28, 43, 72, 0.5);
      }

      button {
        background: transparent;
        font-weight: 600;
        font-size: 18px;
        color: rgb(28, 43, 72, 0.5);
        border-radius: 12px;
        padding: 12px;

        &.delete-btn {
          opacity: 1;
          background-color: #f06b6b;
          font-weight: 500;
          font-size: 16px;
          min-width: 160px;
          color: #fff;

          &:hover {
            background-color: #d14e4e !important;
          }
          &:disabled {
            background-color: #f07e7e !important;
          }
        }
      }
    }
  }
}