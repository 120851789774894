.home-wrapper {
	font-family: "Poppins";
	section {
		padding: 59px 0;
	}
	h1 {
		color: #1c2b48;
		font-family: "Poppins";
		text-transform: capitalize;
		font-size: 55px;
		font-weight: 700;
		line-height: 1.5;
		width: 100%;
		@media (max-width: 768px) {
			font-size: 36px;
			line-height: 1.3;
		}
		span {
			background: -webkit-linear-gradient(#6f7ced, #624fd1);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-family: "Poppins";
			text-transform: capitalize;
		}
	}
	.hero-section {
		background-size: cover; /* <------ */
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url("../../../assets/backgrounds/home.png");
		position: relative;
		padding-top: 173px;
		.content {
			display: flex;
			align-items: "stretch";
			justify-content: center;
			flex-direction: column;
			max-width: 880px;
			width: 100%;
			margin: auto;

			h1,
			h3,
			p {
				font-family: "Poppins";
				color: #1c2b48;
				text-align: left;
				text-transform: capitalize;
				span {
					color: #6f7ced;
				}
			}

			h1 {
				margin: 0;
			}

			h3 {
				text-transform: initial;
				font-size: 32px;
				font-weight: 500;
				margin: 20px 0;
				@media (max-width: 768px) {
					font-size: 28px;
					line-height: 1.3;
				}
			}
			p {
				font-size: 16px;
				font-weight: 500;
				margin: 0;
			}
			.list-wrap {
				display: flex;
				margin-bottom: 109px;
				justify-content: space-between;
				@media (max-width: 768px) {
					flex-direction: column;
				}
				.list-inner-wrap {
					display: flex;
					flex-direction: column;
					gap: 19px;
					flex: 4;
				}
			}
			.list-title {
				color: #1c2b48;
				font-family: "Poppins";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0.32px;
				text-transform: initial;
				margin-bottom: 0;
			}
			.hero-bullets {
				padding-left: 30px;
				margin-bottom: 0;
				li {
					color: #1c2b48;
					font-family: "Poppins";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.32px;
					margin: 3px 0;
				}
			}
			.get-started-btn {
				background: #6f7ced;
				box-shadow: 0px 10px 21px rgba(111, 124, 237, 0.24);
				border-radius: 8px;
				font-weight: 500;
				font-size: 16px;
				max-width: 160px;
				width: 100%;
				height: 100%;
				min-height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				align-self: end;
				flex: 1;
			}
		}
		.home-hero-img {
			max-width: 938px;
			width: 100%;
			height: 399px;
			margin: auto;
			background: url("../../../assets/images/heroContent.png") no-repeat;
			background-size: cover;
			border-radius: 15px;
			position: relative;

			.bg-opacity {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				border-radius: 15px;
				background: linear-gradient(
					180deg,
					rgba(251, 253, 255, 0) 0%,
					#fbfdff 100%
				);
			}
		}
		.blur-effect {
			height: 300px;
			background: linear-gradient(
				180deg,
				rgba(251, 253, 255, 0) 0%,
				#fbfdff 100%
			);
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
}
