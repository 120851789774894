.invite-modal-user-autocomplete-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  .thumb {
    width: 32px;
    height: 32px;
  }
  .option-user-info {
    h3,
    p {
      margin: 0;
      color: #1c2b48;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    h3 {
      font-size: 14px;
      font-weight: 500;
    }
    p {
      opacity: 0.5;
      font-size: 10px;
    }
  }
}
