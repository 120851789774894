.create-transaction-document-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px 30px 40px 30px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .create-doc-form {
        .ant-input.textarea {
          height: 130px !important;
          resize: none;
          &::placeholder,
          &::-moz-placeholder {
            color: rgba(28, 43, 72, 0.5) !important;
            font-family: "Lato" !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            opacity: 1;
          }
        }

        .selected-partymembers {
          display: flex;
          overflow-x: auto;
          position: relative;
          padding-bottom: 25px;

          .member-box {
            margin-top: 10px;
            padding: 7px 10px;
            position: relative;
            background: #6f7ced;
            border-radius: 10px;
            min-width: 135px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            color: #fff;
            font-size: 12px;
            margin-right: 10px;

            .thumb {
              width: 20px;
              height: 20px;
              border-radius: 50px;
              overflow: hidden;
              display: flex;
              align-content: center;
              justify-content: center;
              margin-right: 5px;

              .blocked-icon {
                width: 8px;
                height: 8px;
              }
            }

            .close-btn {
              right: -8px;
              top: -6px;
              width: 18px;
              height: 18px;
              font-size: 11px;
            }

            b {
              max-width: 85px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .selected-partymembers {
              display: flex;
              overflow-x: auto;
              position: relative;
              padding-bottom: 10px;

              .member-box {
                margin-top: 10px;
                padding: 7px 10px;
                position: relative;
                background: #6f7ced;
                border-radius: 10px;
                min-width: 135px;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                color: #fff;
                font-size: 12px;
                margin-right: 10px;

                .thumb {
                  width: 20px;
                  height: 20px;
                  border-radius: 50px;
                  overflow: hidden;
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  margin-right: 5px;

                  .blocked-icon {
                    width: 8px;
                    height: 8px;
                  }
                }

                .close-btn {
                  right: -8px;
                  top: -6px;
                  width: 18px;
                  height: 18px;
                  font-size: 11px;
                }

                b {
                  max-width: 85px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
            }
          }
        }

        .upload-doc-box {
          position: relative;
          background: #f8fafb;
          border: 1px dashed rgba(28, 43, 72, 0.5);
          border-radius: 10px;
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          overflow: hidden;

          .drop_area {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 99;
          }

          .content {
            text-align: center;
            width: 120px;

            svg {
              width: 30px;
              height: 30px;
            }

            p {
              margin: 0;
              margin-top: 5px;
              color: #1c2b48;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              opacity: 0.5;
            }
          }

          &.active {
            border-color: #6f7ced;

            .content {
              width: 100%;

              svg path {
                fill: #6f7ced;
                opacity: 1;
              }

              p {
                color: #6f7ced;
                opacity: 1;
              }
            }
          }
        }

        .ant-form-item-has-error .upload-doc-box {
          border-color: #ff4d4f;
        }

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;

          .ant-checkbox-inner {
            margin-top: -5px;
            border: 2px solid #6f7ced;
            border-radius: 4px;
            width: 18px;
            height: 18px;
          }
        }
        .enabled {
          opacity: 1;
          width: 100%;
        }

        .checklist-check-col {
          border-top: 1px solid rgba(28, 43, 72, 0.1);
          padding-top: 20px;
          .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled,
          .ant-checkbox-disabled,
          span {
            cursor: pointer;
          }
        }
        .check-fields {
          margin-top: 15px;

          .ant-input.textarea {
            height: 72px !important;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
      margin-top: 25px;

      button {
        background: #6f7ced;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        border-radius: 12px;
        padding: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;

        color: #fcfbfe;
      }
    }
  }
}
