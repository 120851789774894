.home-contact-sec {
	position: relative;
	&:before {
		content: "";
		width: 336px;
		height: 379px;
		border-radius: 379px;
		opacity: 0.15;
		background: #6f7ced;
		filter: blur(150px);
		position: absolute;
		right: 0px;
		bottom: -120px;
	}
}

.contact_us_sec {
	border-radius: 36px;
	background: #6f7cee;
	box-shadow: 0px 26px 50px 0px rgba(111, 124, 238, 0.2);
	text-align: center;
	padding: 56px 30px;
	h3 {
		color: #fff;
		text-align: center;
		font-size: 55px;
		font-weight: 700;
		line-height: 70px;
		letter-spacing: 1.1px;
		text-transform: capitalize;
		width: 100%;
		max-width: 625px;
		margin: auto;
		font-family: "Poppins";
		@media (max-width: 768px) {
			font-size: 30px;
			line-height: 40px;
		}
	}
	a {
		border-radius: 8px;
		background: #fff;
		box-shadow: 0px 10px 21px 0px rgba(111, 124, 237, 0.24);
		height: 50px;
		max-width: 160px;
		width: 100%;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 30px auto 0;
		color: #6f7cee;
		text-align: center;
		font-family: "Lato";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: capitalize;
		text-decoration: none;
		&:hover {
			color: #6f7cee;
		}
	}
}
