.data-room {
  padding-bottom: 20px;
  margin: 30px 35px;

  .room-detail {
    justify-content: space-between !important;

    .left-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      padding-right: 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        height: 80%;
        background: rgba(28, 43, 72, 0.1);
        width: 1px;

        @media (max-width: 1194px) {
          display: none;
        }
      }

      .description {
        display: flex;
        align-items: center;
        gap: 15px;

        h4 {
          max-width: 550px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 5px;
        }

        .edit-del {
          display: flex;
          gap: 8px;
          align-self: flex-end;

          p {
            opacity: 0.5;
            margin-top: 3px;
            line-height: 2;
          }

          svg {
            height: 24px;
            width: 24px;
          }
          .filled-icon {
            height: 26px;
            width: 26px;
          }
        }
      }

      .search-box {
        background: #ffffff;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        padding: 12px 15px;
        width: 380px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          color: #6f7ced;
          font-size: 20px;
          margin-right: 10px;
        }

        input {
          background: transparent;
          border: none;
          font-weight: 500;
          font-size: 14px;
          color: #1c2b48;
          opacity: 0.5;
          width: 300px;
          padding: 0 4px;
          margin-right: 15px;

          @media (max-width: 1194px) {
            width: calc(100% - 60px);
          }
        }

        @media (max-width: 1194px) {
          // width: calc(100% + 204px);
          margin-top: 30px;
        }
      }

      @media (max-width: 1194px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &.full-width {
        width: 100%;
        padding-right: 0;

        &::before {
          display: none;
        }
      }
    }

    .right-wrapper {
      width: 20%;
      padding-left: 20px;
      display: flex;
      justify-content: flex-start;

      div {
        width: 100%;

        button {
          cursor: pointer;
          width: 100%;
          border: none;
          background: #6f7ced;
          border-radius: 12px;
          padding: 12px;
          font-weight: 500;
          font-size: 16px;
          color: #fcfbfe;
        }
      }

      @media (max-width: 1194px) {
        width: 192px;
      }
    }

    @media (max-width: 1194px) {
      align-items: flex-start !important;
    }
  }

  .dataroom-activity-logs {
    position: sticky;
    top: 110px;
  }

  .folder-content-wrapper {
    .dataroom-notes-wrapper {
      position: sticky;
      top: 110px;
      z-index: 6;
    }
  }

  .data-room-link-box {
    padding: 14px 16px 14px 11px;
    background: #6f7ced;
    width: 100%;
    display: flex;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;

    .link-left-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;

      p {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        font-family: "Lato" !important;
      }

      .ant-avatar-group {
        .ant-avatar {
          border: 2px solid #6f7ced;
        }
      }
    }

    .link-right-wrapper {
      width: 31px;
      height: 31px;
      border: 2px dashed #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .room-details-header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(28, 43, 72, 0.1);
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;

    .room-header-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: calc(100% - 292px);
      // gap: 50px;
      flex-grow: 1;
      .room-creator-info {
        display: flex;
        align-items: center;
        gap: 15px;
        max-width: 40%;

        .thumb {
          height: 37px;
          width: 37px;
          border-radius: 50%;
        }

        .room-creator-info-text {
          width: calc(100% - 52px);

          p {
            font-family: "Lato" !important;
            font-weight: 500;
            font-size: 14px;
            color: #1c2b48;
            opacity: 0.5;
            margin: 0;
          }

          h3 {
            font-family: "Lato" !important;
            font-weight: 500;
            font-size: 16px;
            text-transform: capitalize;
            color: #1c2b48;
            margin: 0;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .data-room-users {
        border-radius: 12px;
        padding: 10px 16px 10px 18px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        h5 {
          margin: 0;
          font-family: "Lato" !important;
          font-weight: 700;
          font-size: 18px;
          min-width: 140px;
          color: #1c2b48;
        }

        .action {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: calc(100% - 60px);
          border-right: 1px solid rgba(28, 43, 72, 0.1);
          padding-right: 5px;

          .group-list {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% - 33px);

            li {
              display: block;
              border-radius: 50px;
              color: #fff;
              font-weight: 600;
              font-size: 10px;
              margin-left: -10px;
              border: 1px solid #fff;

              .thumb {
                width: 29px;
                height: 29px;
                border-radius: 50px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              &.view-all {
                padding: 8px 10px;
                cursor: pointer;
                border-radius: 200px;
                min-width: 80px;
                background: #6f7ced;
                z-index: 1;
              }
            }
          }

          .add-group-btn {
            min-width: 23px;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(111, 124, 237, 0.01);
            border: 1px dashed #6f7ced;
            border-radius: 30px;
            color: #6f7ced;
            font-size: 13px;
            cursor: pointer;
            margin-left: 8px;
            margin-right: 10px;
          }

          @media (max-width: 1194px) {
            border-right: none;
            padding-right: 0;
          }
        }

        @media (max-width: 1194px) {
          padding: 10px 0;
        }
      }
    }

    .room-details-header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        min-width: 137px;
        padding: 13px;
        border-radius: 12px;
        font-size: 16px;
        font-family: "Lato" !important;
        width: max-content;
        display: flex;
        justify-content: center;
        border: 1px solid #6f7ced;

        &.btn-style:disabled {
          border-color: transparent;
        }
      }

      .btn-secondary {
        background: transparent;
        color: #6f7ced;

        &.btn-style:disabled {
          background-color: transparent !important;
          opacity: 0.5;
          border-color: #6f7ced;
        }
      }

      @media (max-width: 1194px) {
        margin-top: 15px;
        width: 100%;

        button {
          padding: 10px;
        }
      }
    }

    @media (max-width: 1194px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .data-room-content-wrapper {
    // display: flex;
    // flex-direction: column;
    // height: calc(100vh - 390px);
    overflow: hidden;
    width: 65%;

    // gap: 10px;
    .card-title {
      font-weight: 700;
      font-size: 16px;
      font-family: "Lato" !important;
      color: #1c2b48;
    }

    .room-detail-folder-wrapper {
      // height: 38%;
      margin-bottom: 7%;
    }

    .room-detail-document-wrapper {
      // height: 55%;
    }
  }

  .links-room-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .links-listing-wrapper {
      width: 65%;

      @media (max-width: 1194px) {
        width: 50%;

        .ant-col {
          width: 100%;

          .link-card-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  .rooms-list {
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    height: calc(100vh - 320px);
    overflow-y: scroll;
  }

  .links {
    flex: 1;
    height: calc(100vh - 320px);
    overflow-y: scroll;

    .link-heading {
      border-bottom: 1px solid rgb(28, 43, 72, 0.2);
      padding: 15px 0;
    }

    .link {
      padding: 15px;
      padding-left: 40px;
      margin-inline: -20px;
    }

    .link-background {
      background-color: #f2f5fa;
    }
  }

  h4,
  p {
    margin-bottom: 0;
    font-family: "Lato";
    color: #1c2b48;
  }

  h4 {
    font-weight: 500;
    font-size: 15px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .active {
    background-color: rgb(232, 234, 237, 0.5);
  }

  .horizontal-line {
    margin: 0;
    margin-inline: 30px;
  }
}
