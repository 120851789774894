.checklist-wrapper {
  padding-bottom: 20px;
  margin: 30px 35px;

  * {
    font-family: "Lato", sans-serif !important;
  }

  .room-detail {
    justify-content: space-between !important;

    .left-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 55%;
      padding-right: 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        height: 80%;
        background: rgba(28, 43, 72, 0.1);
        width: 1px;
      }

      .description p {
        opacity: 0.5;
        margin-top: 3px;
      }

      .search-box {
        background: #ffffff;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        padding: 12px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          color: #6f7ced;
          font-size: 20px;
          margin-right: 10px;
        }

        input {
          background: transparent;
          border: none;
          font-weight: 500;
          font-size: 14px;
          color: #1c2b48;
          width: calc(100% - 55px);
          padding: 0 4px;
          border-right: 1px solid rgba(28, 43, 72, 0.3);
          margin-right: 15px;
          &::placeholder {
            color: rgba(28, 43, 72, 0.8) !important;
            font-weight: 500;
          }
        }

        .filter-btn {
          width: 25px;
          margin: 0;
          position: relative;
          cursor: pointer;
        }
      }
    }

    .right-wrapper {
      width: 45%;
      display: flex;
      padding-left: 20px;
      justify-content: flex-start;
      gap: 15px;

      div {
        width: 47%;
        div {
          width: 100%;
        }
        button {
          width: 100%;
        }
        &:first-child {
          button {
            background: transparent;
            color: #6f7ced;
          }
        }
      }

      button {
        cursor: pointer;
        width: 50%;
        border: 1px solid #6f7ced;
        background: transparent;
        border-radius: 12px;
        padding: 8px 5px;
        font-weight: 500;
        font-size: 16px;
        color: #6f7ced;

        &:last-child {
          margin-left: 14px;
          background: #6f7ced;
          color: #fcfbfe;
        }
      }
    }

    .dtl-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .top-heading {
        margin-left: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #1c2b48;
          margin: 0;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }
    }
  }

  .activtybox-wrapper {
    position: sticky;
    top: 110px;
  }
}
