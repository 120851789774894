.captcha-wrap {
  max-width: 337px;
  margin: auto;

  iframe {
    .rc-anchor-light.rc-anchor-normal,
    .rc-anchor-light.rc-anchor-compact {
      border-radius: 12px !important;
      border: 2px solid #dadada !important;
      background: #f2f2f2 !important;
    }
  }
}

.contact-wrapper {
  font-family: "Poppins";

  h1 {
    color: #1c2b48;
    font-family: "Poppins";
    text-transform: capitalize;
    font-size: 55px;
    font-weight: 700;
    line-height: 70px;

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 1.3;
    }

    span {
      color: #6f7ced;
      font-family: "Poppins";
      text-transform: capitalize;
    }
  }

  .form_sec {
    background-size: cover;
    /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../../assets/backgrounds/home.png");
    margin-top: 80px;
    padding: 5% 0;
    position: relative;

    &:before {
      content: "";
      width: 336px;
      height: 379px;
      border-radius: 379px;
      opacity: 0.15;
      background: #6f7ced;
      filter: blur(150px);
      position: absolute;
      right: 0px;
      bottom: 0;
    }

    .blur-effect {
      height: 50%;
      background: linear-gradient(
        180deg,
        rgba(251, 253, 255, 0) 0%,
        #fbfdff 100%
      );
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .content {
      text-align: center;

      h1 {
        font-size: 65px;
        line-height: 80px;

        span {
          display: inline-block;
          width: 100%;
        }

        @media (max-width: 768px) {
          font-size: 36px;
          line-height: 1.3;
        }
      }

      p {
        color: rgb(28 43 72 / 80%);
        text-align: center;
        font-size: 16px;
        font-family: Poppins;
        letter-spacing: 0.32px;
        text-transform: capitalize;
        max-width: 520px;
        margin: auto;
      }
    }

    .social_icons {
      margin: 40px 0;
      display: flex;
      align-items: center;
      column-gap: 46px;
      justify-content: center;

      a,
      svg {
        width: 32px;
        height: 32px;
      }
    }

    .form_wrapper {
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(30px);
      display: flex;
      justify-content: center;
      border-radius: 36px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      max-width: 1018px;
      width: auto;
      padding: 3%;
      align-items: center;
      margin: auto;
      z-index: 1;
      position: relative;

      @media (max-width: 991px) {
        backdrop-filter: unset;
        border: none;
      }

      form {
        width: 100%;

        .feild_container {
          width: 100%;
          display: flex;
          flex-direction: column;

          @media (max-width: 768px) {
            width: 100%;
          }

          .field {
            border-radius: 4px;
            padding: 20px 17px;
            color: rgba(0, 0, 0, 0.5);
            font-family: "Montserrat";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // text-transform: uppercase;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            background: rgb(111 124 237 / 10%);
            outline: none;
            width: 100%;
            height: auto;
          }

          .phone-field {
            border-radius: 4px;
            border: 1px solid #6f7ced1a;
            padding: 0 8px;
            background: #6f7ced1a;
            color: #00000080;

            .flag-dropdown {
              background: transparent;
              border: none;

              .selected-flag {
                background: transparent !important;
              }
            }

            input {
              background: transparent;
              border: none;
              height: 55px;
            }
          }

          .ant-form-item-has-error .field,
          .ant-form-item-has-error textarea,
          .ant-form-item-has-error .phone-field {
            border-color: #ff4d4f !important;
          }

          label {
            margin-bottom: 7px;
            color: #1c2b48;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }

          textarea {
            border-radius: 4px;
            padding: 20px 17px;
            color: rgba(0, 0, 0, 0.5);
            font-family: "Montserrat";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // text-transform: uppercase;
            border: 1px solid #ebebeb;
            background: rgb(111 124 237 / 10%);
            outline: none;
            resize: none;
            width: 100%;
            height: 145px;
            &::placeholder {
              font-family: "Montserrat";
              font-size: 10px !important;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              color: rgba(0, 0, 0, 0.5) !important;
            }
          }

          .form_btn {
            text-align: center;
            margin-top: 10px;

            button {
              padding: 15px 59px;
              border-radius: 12px;
              background: #6f7ced;
              color: white;
              border: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
