.review-sec {
	padding-bottom: 0 !important;

	.new-container {
		display: flex;

		.carousel-container {
			.owl-carousel {
				.owl-stage-outer {
					overflow: initial !important;
					margin-left: 20px;
				}
			}
			.owl-item {
				margin-bottom: 40px;

				&:hover {
					.review-box {
						box-shadow: 0px 39px 53px 0px rgba(15, 23, 40, 0.08);
					}
				}
			}
		}
	}

	.container {
		h1 {
			max-width: 460px;
			margin-top: 81px;
		}
	}

	.review-header {
		@media (max-width: 600px) {
			flex-direction: column;
		}
	}

	.review-header,
	#review-nav {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 20px;

		button {
			background: transparent;
			border: 0;
			cursor: pointer;

			img {
				filter: grayscale(1);

				&:hover {
					filter: grayscale(0);
				}
			}

			&.owl-next {
				img {
					transform: rotate(180deg);
				}
			}
		}
	}

	.reviews-row {
		overflow-x: clip;
		padding: 20px 0;

		.owl-stage-outer {
			.owl-stage {
				display: flex;
				// flex-wrap: wrap;
			}
		}

		.review-box {
			max-width: 100%;
			width: 320px;
			border-radius: 25px;
			padding: 35px;
			display: flex;
			flex-direction: column;
			min-height: 100%;
			position: relative;

			.client_info_wrapper {
				display: flex;
				align-items: center;
				margin-bottom: 24px;

				.client_img {
					width: 75px;
					height: 75px;
					border-radius: 100%;
					margin-right: 20px;
					overflow: hidden;

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}

				.name {
					width: calc(100% - 120px);

					h4 {
						width: 100%;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						color: #2c3d5f;
						font-size: 18px;
						font-family: "Poppins";
						font-weight: 500;
						text-transform: capitalize;
						margin: 0;
					}

					p {
						width: 100%;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						color: #2c3d5f;
						opacity: 0.5;
						font-size: 14px;
						font-family: "Poppins";
						font-weight: 300;
						line-height: 1.5;
						text-transform: capitalize;
						margin: 5px 0 0;
						color: #2c3d5f;
					}
				}

				.colins {
					position: absolute;
					right: 11px;
					top: 58px;
				}
			}

			.review {
				min-height: 150px;

				p {
					color: #1c2b48;
					font-family: "Poppins";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.28px;
					opacity: 0.800000011920929;
					margin: 0 0 10px;
					display: -webkit-box;
					-webkit-line-clamp: 7;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.rating {
				margin-top: 15px;
			}
		}
	}

	.empty-text {
		color: #1c2b48;
		font-family: "Poppins";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.32px;
		text-align: center;
		margin-bottom: 0;
	}
}
