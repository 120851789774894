@use "../../../../master.scss";

.accout-input-parent {
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #363645;
  }

  .data {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #93969e;
  }

  .icon-data-wrapper {
    display: flex;

    .thumb {
      height: 24px;
      min-width: 24px;
      max-width: 24px;
      border-radius: 50px;
      margin-right: 10px;
      cursor: auto;
    }

    .data {
      padding-top: 2px;
    }

    .react-tel-input {
      pointer-events: none;

      .form-control {
        width: 100%;
        border-radius: 12px;
        padding-left: 25px;
        border: none;
        font-family: "Lato";
        font-weight: 400;
        font-size: 16px;
        color: #93969e;
        margin-bottom: 0;
        height: auto;
      }

      .flag-dropdown {
        border-radius: 50px;
        border: none;
        margin: 0;
        background: transparent !important;

        .arrow {
          display: none;
        }

        .selected-flag {
          padding: 0;
          background: none !important;
        }
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
