@use "../../../master.scss";

.personal-info-card {
  padding: 32px;
  margin-top: 30px;
}

.sub-heading-parent {
  display: flex;
  align-items: center;
}

.sub-heading {
  flex: 1;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1c2b48;
}

.public-profile {
  display: flex;
  align-items: center;
  padding-right: 30px;

  .pp-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6f7ced;
    margin-right: 20px;
    font-family: master.$font-poppins;
  }
}

.edit-profile-btn {
  background: rgba(111, 124, 237, 0.01);
  border: 1px solid #6f7ced;
  border-radius: 12px;
  margin-left: 25px;
  font-family: master.$font-lato;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  line-height: 19px;
  text-transform: capitalize;
  color: #6f7ced;
}

.line {
  border: 1px solid #dddddd;
  margin-top: 12px;
}

.detail-section {
  display: flex;
  margin-top: 25px;

  * {
    font-family: DM Sans !important;
  }
}

.profile-picture-section {
  margin-right: 50px;
}

.profile-picture-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px !important;
  width: 100px !important;
  border-radius: 50px;
  overflow: hidden;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: none;
}

.social-section-Wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #363645;
  }
}

.social-icons-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;

  svg {
    margin: 0 4px;
    cursor: pointer;
  }
}

.info-section {
  flex: 1;
  margin-top: 30px;

  .child {
    flex: 1 0 21%;
    padding: 0 5px;
  }
}

.change-password-card {
  margin-top: 50px;
  .noti-setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 10px;
    border-radius: 10px;
    background: #f8f9fb;
    .text {
      color: #363645;
      font-family: "DM Sans";
      font-size: 16px;
      font-weight: 700;
    }
    .ant-switch {
      background-color: #636571;
    }
    .ant-switch-checked {
      background-color: #6f7ced;
    }
  }
}

.current-password-text {
  margin-top: 23px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #363645;
}

.current-password-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
  height: 59px;
  background: #f8f9fb;
  border-radius: 15px;
  color: #70808b;
  cursor: pointer;

  svg {
    cursor: pointer;
  }
}

.parent-section {
  padding: 0 20px 20px;

  * {
    font-family: "Lato";
  }

  .title {
    margin-bottom: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .data {
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    line-break: strict;
  }

  .horizontal-line {
    margin-top: 12px;
  }

  .change-password-card {
    padding: 30px 20px;
  }
}

.public-profile-toggle-wrapper {
  display: flex;
  gap: 10px;
  .ant-switch-checked {
    background-color: #6f7ced;
  }

  .create-transaction-info-icon {
    cursor: pointer;

    &:hover * {
      fill: #6f7ced;
    }
  }
}
