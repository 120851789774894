@use "../../master.scss";

.approval-request-modal {
  .title {
    text-align: center;
  }

  button {
    width: max-content;
    padding: 10px 30px;
  }

  .details {
    gap: 40px;
  }

  .request-card {
    width: auto;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
}
