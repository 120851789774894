.notes-card-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 14px;
  max-width: 100%;
  gap: 10px;
  position: relative;
  margin-bottom: 10px;
  background: #ffec94;

  p {
    color: #1c2b48;
  }

  .card-fold {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    border-bottom-left-radius: 8px;
    background: linear-gradient(45deg, #dbc56c 50.49%, #fff 50.94%);
  }

  .notes-card-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #dbc56c;
    padding-bottom: 10px;

    .creator-info {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: calc(100% - 50px);
      overflow: hidden;

      .thumb {
        height: 30px;
        min-width: 30px;
        max-width: 30px;
        border: 2px solid #dbc56c;
        border-radius: 50%;
      }

      p {
        font-family: "Lato" !important;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .description {
      font-family: "Lato" !important;
      font-weight: 400;
      font-size: 12px;
    }

    &.transparent {
      border-color: transparent !important;
    }
  }

  .notes-card-bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      color: #1c2b48 !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    .note-link {
      color: #fff;
    }

    .time-options-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        cursor: pointer;
      }

      .notes-time {
        background: rgba(255, 255, 255, 0.9);
        padding: 5px 10px;
        border-radius: 15px;
        color: #8c7600;
      }
    }

    .working-groups-options {
      padding-bottom: 5px;
      display: flex;

      p {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: rgba(28, 43, 72, 0.8);
        cursor: pointer;
        margin: 0;
      }
    }
  }

  &.owner {
    background: #6f7ced;

    p {
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
    a {
      color: #fff !important;
    }

    .card-fold {
      background: linear-gradient(45deg, #505dcf 50.49%, #fff 50.94%);
    }

    .notes-card-top {
      border-color: #505dcf;

      .creator-info img {
        border-color: #505dcf;
      }
    }

    .notes-card-bottom .notes-time {
      color: #2734a6;
    }
  }
}
