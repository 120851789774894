@font-face {
  font-family: "color-emoji";
  src: local("Apple Color Emoji"), local("Segoe UI Emoji"),
    local("Segoe UI Symbol"), local("Noto Color Emoji");
}

.chat-box-container {
  padding: 30px 13px 0 13px;
  border-radius: 12px;
  border: 1px solid rgba(67, 68, 69, 0.1);
  background: #fff;
  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 220px);
  height: 100%;
  position: relative;

  .chat-blocked-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;

    // background: rgba(111, 124, 237, 0.1);
    background: rgb(227 230 255);
    border-radius: 6px 6px 12px 12px;
    padding: 13px 0 12px;
    p {
      color: #434445;
      font-family: "Lato";
      font-size: 14px;
      font-weight: 600;
    }
    .unblock-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 30px;
      border-radius: 12px;
      border: 1px solid #6f7ced;
      background: rgba(64, 98, 228, 0.01);
      color: #6f7ced;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 500;
    }
  }
  .chat-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(67, 68, 69, 0.2);
    padding-bottom: 12px;
    .group-member-options {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        color: #1c2b48;
        font-family: "Lato";
        font-size: 13px;
        font-weight: 500;
        margin: 0;
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 17px;
      .room-name-wrapper {
        .visibility {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          p {
            color: rgba(0, 0, 0, 0.5);
            font-family: "Lato";
            font-size: 12px;
            font-weight: 400;
            margin: 0;
          }

          .visible-people-list {
            margin: 0;
            .thumb,
            .view-more {
              width: 20px;
              height: 20px;
              margin-right: -7px;
            }
          }
        }
        h3 {
          margin: 0;
          color: #434445;
          font-family: "Lato";
          font-size: 16px;
          font-weight: 600;
          max-width: 400px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }
      }

      .thumb {
        width: 50px;
        height: 50px;
      }
    }
  }

  .chat-wrapper {
    max-height: calc(100vh - 430px);
    min-height: calc(100vh - 430px);
    overflow: auto;
    padding-right: 5px;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 10px;
    .scroll-box {
      height: 1px;
      width: 100%;
      background: transparent;
    }

    .chat-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 30px;
      gap: 50px;
      height: 100%;
      margin-bottom: -40px;

      .chat-date {
        width: 100%;
        text-align: center;
        color: #434445;
        font-family: "Lato";
        font-size: 14px;
        font-weight: 400;
      }

      .highlight {
        width: 100%;
        font-size: 10px;
        text-align: center;
        opacity: 0.5;
      }
    }
  }

  .chat-input-container {
    margin-top: auto;

    .show-typing-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      margin-top: 15px;
      flex-wrap: wrap;
      .typer {
        margin: 0;
        color: #434445;
        font-family: "Lato";
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 22px;
        opacity: 0.5;
        white-space: pre-wrap;
        word-break: break-all;
      }
      .type-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d9d9d9;
        border-radius: 15px 30px 30px 0px;
        height: 19px;
        width: 45px;
      }
    }
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 3px;
      max-width: 100%;
      overflow: auto;
      user-select: none;
      border: 1px solid rgba(28, 43, 72, 0.3);
      border-radius: 10px;
      padding: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .chat-doc-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        gap: 10px;
        min-width: 240px;
        border: 1px solid rgba(28, 43, 72, 0.3);
        padding: 10px;
        border-radius: 10px;

        .logo {
          width: 32px;
          height: 32px;
          margin-bottom: 0;
        }

        .fileName {
          margin: 0;
          font-family: "Poppins";
          font-weight: 500;
          font-size: 14px;
          color: "option";
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .option {
          position: absolute;
          height: 15px;
          width: 15px;
          right: 10px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      .img-attachment {
        height: 80px;
        min-width: 80px;
        max-width: 80px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;

        .remove-btn {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 14px;
          height: 14px;
          background: #000;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }

    .chat-input-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 1px solid rgba(67, 68, 69, 0.5);
      padding-bottom: 17px;
      gap: 17px;
      margin-top: 15px;
      .inputBox_inputWrapper-input {
        max-height: 52px;
        width: 100%;
        max-width: calc(100% - 120px);
        overflow-y: auto;
        .inputBox_inputWrapper-input__highlighter {
          max-height: 52px;
        }
        textarea {
          width: 100%;
          border: none;
          font-family: "Poppins", "color-emoji" !important;
          resize: none;
          height: 25px;
          overflow-y: auto !important;
          &::placeholder {
            color: rgba(67, 68, 69, 0.5) !important;
            font-family: "Poppins" !important;
            font-size: 14px !important;
            font-weight: 400 !important;
          }

          &:disabled {
            background: transparent;
          }
        }
      }

      .chat-options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 17px;
      }

      .emoji-wrapper {
        position: relative;
        display: flex;

        .emoji-dropdown-wrapper {
          position: absolute !important;
          top: -450px;
          left: -330px;
        }
      }

      .file-picker-wrapper {
        display: flex;
      }
    }
  }
  .ant-row {
    height: 100%;
    .mess-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 30px;
      padding-top: 10px;
    }
  }
}
