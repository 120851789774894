.resources-container {
  padding: 0 20px;

  p,
  h3 {
    font-family: "Lato" !important;
  }

  .top-heading-nav {
    h3 {
      font-weight: 700;
      font-size: 18px;

      color: #1c2b48;
    }

    p {
      font-weight: 500;
      font-size: 14px;

      color: #1c2b48;

      opacity: 0.5;
    }
  }

  .top-container {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 15px;

    p,
    h3 {
      margin: 0;
    }
  }

  .resource-content {
    padding: 28px 21px;
    margin-top: 14px;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .filter-container {
      padding-bottom: 23px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.2);
      display: flex;
      flex-direction: column;
      gap: 10px;

      .filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          font-size: 18px;
          font-weight: 700;
        }

        svg {
          transition: all 0.4s;
        }

        .arrow-invert {
          transform: rotate(-180deg);
        }
      }

      .filtertypes-list {
        height: 0px;
        overflow: hidden;
        transition: .4s all;

        &.active {
          height: 160px;
        }
      }
    }

    .folder-container {
      h3 {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .resources-infinite-scroll {
    width: 100%;
  }
}