.document-card-wrapper {
  display: flex;
  padding: 14px;
  max-width: 100%;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  gap: 15px;
  margin-right: 5px;

  p {
    color: #1c2b48;
    margin: 0;
  }

  .card-left-wrapper {
    width: calc(100% - 50px);
    display: flex;
    justify-content: space-between;

    .folder-name-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 65%;

      svg {
        min-width: 36px;
      }

      p {
        font-weight: 700;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 50px);
      }
    }

    .creator-info {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 35%;
      padding-left: 10px;

      .thumb {
        height: 23px;
        width: 23px;
        border-radius: 50%;
      }

      p {
        font-family: "Lato" !important;
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 35px);
      }

      .created {
        font-size: 12px;
        color: #1c2b48;
        opacity: 0.5;
        margin-bottom: 2px;
      }
    }
  }

  .options-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    border-left: 1px solid rgba(28, 43, 72, 0.1);
    padding-left: 15px;

    .data-room-visibility-group {
      margin: 0 !important;
    }

    svg {
      cursor: pointer;
    }
  }
}
