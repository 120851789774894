@use "../../master.scss";

.top-header {
  position: sticky;
  width: 100%;
  z-index: 999;
  background: #fff !important;
  border-bottom: 1px solid rgba(28, 43, 72, 0.1);

  .head-container,
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .head-container {
    padding-inline: 20px;
    padding: 20px;
    padding-bottom: 8px;
    height: 80px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    svg {
      margin-top: -14px;
      width: 44px;
      height: 44px;
    }

    img {
      width: 100%;
      height: 36px;
      max-width: 140px;
    }

    p {
      font-family: master.$font-montserrat;
      color: master.$text-color-primary;
      font-weight: 600;
      font-size: 15px;
      text-transform: capitalize;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;

    & > li {
      color: master.$text-color;
      list-style: none;
      font-family: master.$font-lato;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: master.$primary-color;
      }

      &.active {
        position: relative;
        color: master.$primary-color;
      }

      &.active:before {
        content: "";
        background-image: url("../../assets/images/header-icon.svg");
        width: 14px;
        height: 14px;
        position: absolute;
        bottom: -8px;
        left: -5px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    li:last-of-type {
      color: #fcfbfe;
      padding: 8px 24px;
      background: master.$primary-color;
      border-radius: 12px;

      &.active {
        color: #fff;
        background: rgba(28, 43, 72, 0.5) !important;
      }

      &.active::before {
        content: none;
      }
    }
  }
}

.login-dashboard {
  display: flex;
  align-items: center;
  font-family: master.$font-lato;
  gap: 20px;

  p {
    margin-bottom: 0px;
    color: master.$default-text-color;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    font-family: master.$font-lato !important;
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg,
  img {
    cursor: pointer;
  }
}

.line {
  height: 24px;
  width: 0px;
  opacity: 0.1;
  border: 1px solid #000000;
  // transform: rotate(90deg);
}

.notification-icon {
  margin-bottom: -6px;
  position: relative;

  .count {
    position: absolute;
    top: -9px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f06b6b;
    border: 1px solid #ffffff;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
  }

  &:focus-within {
    .notification-drop-down {
      @extend .show-dropdown;
    }
  }
}

.profile-dropdown-container {
  position: relative;
  display: flex;
  align-items: center;

  .thumb {
    margin-right: 20px;
    width: 34px;
    height: 34px;
  }

  &:focus-within {
    .profile-dropdown {
      @extend .show-dropdown;
    }
  }
}

.notification-drop-down {
  position: absolute;
  top: 55px;
  right: -60px;
  width: 300px;
  @extend .drop-down-box;
  font-family: master.$font-poppins;
  padding-bottom: 0;
  padding-right: 0;
  color: #1b2559;
  display: flex;
  flex-direction: column;
  gap: 10px;
  visibility: hidden;
  opacity: 0;
  z-index: 8;
  padding-right: 2px;

  /* width */
  & ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  & ::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;

    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }

    p {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-decoration-line: underline;
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  .notifications-list {
    // overflow-y: auto;
    // height: 305px;
    width: 100%;
    position: relative;
    padding-right: 5px;

    .notification-card {
      background: rgba(232, 234, 237, 0.5);
      border-radius: 6px;
      display: flex;
      padding: 10px;
      position: relative;
      margin: 5px 0;
      &:last-child {
        border: none;
      }
      .thumb {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .content {
        width: calc(100% - 35px);

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          font-size: 12px;
          color: #1b2559;
          line-height: 18px;
          text-align: left;
          display: block;
          max-width: calc(100% - 65px);
        }

        .detail {
          font-weight: 400;
          font-size: 10px;
          color: #1b2559;
          line-height: 15px;
          text-align: left;
          display: block;
          overflow: hidden;
          line-break: strict;
          word-break: break-word;
        }
      }

      .last-seen {
        position: absolute;
        right: 10px;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #93969e;
        margin-bottom: 0px;
      }

      &.seen {
        background: transparent !important;
        border-bottom: 1px solid rgb(163 174 208 / 30%);
        border-radius: 0;
      }
    }
  }

  &::before {
    content: "";
    border-radius: 8px 0px 0px 8px;
    background: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 52px;
    // height: 100%;
    // transform: rotate(-90deg);
    width: 100%;
    z-index: 99;
  }
}

.profile-dropdown {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 120px;
  margin-bottom: -120px;
  visibility: hidden;
  opacity: 0;
  @extend .drop-down-box;
  z-index: 8;

  P {
    font-family: master.$font-lato;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgb(28, 43, 72, 0.8);
    cursor: pointer;
  }

  div {
    height: 1px;
    background: rgb(28, 43, 72, 0.1);
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.triangle-up {
  position: absolute;
  top: -20px;
  right: 25px;
}

.fixed-header {
  position: fixed;
  left: 0;
  top: 0;
  height: 100px;
}
