@use "../../master.scss";

.signing-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
  background-color: #f0f2f5;

  .react-tel-input {

    .form-control {
      width: 100%;
      border-radius: 12px;
      padding: 24px 45px;
      border-color: rgba(28, 43, 72, 0.1);
    }

    .flag-dropdown {
      border-radius: 50px;
      border: none;
      margin: 5px;
      background: transparent;
    }

  }

  .logo-wrapper {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .form-wrapper {
    padding: 40px 30px;
    width: 489px;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    border-radius: 12px;
    margin-top: 150px;

    &.verification {
      width: 519px;
    }

    .form {
      margin: 10px 0;
      width: 100%;

      .ant-row {
        margin: 0 !important;
      }

      .required-field {
        .ant-form-item-control-input-content {
          position: relative;
          display: flex;
          align-items: center;

          &::after {
            content: "*";
            color: red;
            position: absolute;
            right: 10px;
            font-size: 16px;
            margin-top: 5px;
            z-index: 1;
          }
        }

        .ant-row {
          width: 100%;
        }

        .ant-select {
          margin: 5px 0;
        }

        .ant-select-selection-search-input {
          color: #000 !important
        }

        .signup-country-select {
          display: flex;
          align-items: center;

          .ant-select-selector {
            border: 1px solid rgba(28, 43, 72, 0.1);
            border-radius: 12px;
            padding: 24px 42px;

            .ant-select-selection-search {
              margin-left: 32px;
            }
          }

          .ant-select-arrow {
            right: 30px;
          }

          .ant-select-selection-placeholder {
            font-size: 16px;
          }

          &::before {
            position: absolute;
            left: 11px;
            content: "";
            background-image: url(../../assets/icons/country.svg);
            background-position: center;
            background-size: cover;
            width: 20px;
            z-index: 1;
            height: 22px;
          }
        }
      }

      .otp-wrapper {
        .ant-form-item-control {
          align-items: center;
        }
      }

      .ant-form-item-control-input-content {
        .ant-input-affix-wrapper {
          padding-right: 20px;
        }
      }

      .ant-form-item-has-error {
        .ant-input-affix-wrapper {
          border-color: #ff4d4f !important;
        }
      }

      .ant-input-affix-wrapper {
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 12px;
      }

      .ant-input-affix-wrapper-disabled {
        input {
          color: #000 !important;
        }
      }

      input::placeholder {
        font-family: master.$font-lato;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(28, 43, 72, 0.2);
      }

      .ant-input-affix-wrapper-disabled {
        color: red !important;
      }

      .input-icon {
        color: master.$default-text-color;
        margin-right: 10px;
        height: 20px;
      }
    }
  }

  .forgot-pass,
  .register-here {
    text-align: center;
    display: block;
    text-align: end;
    font-size: 16px;

    label {
      color: master.$primary-color;
      font-family: "Lato";
      font-weight: 500;
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
    }

    p {
      font-family: "Lato";
      font-size: 16px;
    }

    span {
      font-family: "Lato";
    }
  }

  .register-here {
    margin-top: 25px;
    text-align: center;
  }

  .email-send {
    font-size: 13px;
    text-align: center;
    margin-bottom: 30px;
  }
}

.auth-title {
  font-size: 35px;
  color: master.$default-text-color;
  text-align: center;
  font-weight: 600;
  line-height: 48px;
  text-transform: capitalize;
  margin-bottom: 0px;

  span {
    display: block;
    font-family: master.$font-poppins;
  }

  span:nth-child(2) {
    color: master.$primary-color;
  }
}

.auth-subtitle {
  font-family: master.$font-lato;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: master.$default-text-color;
  text-align: center;
  opacity: 0.7;
  width: 100%;
  margin: 0 auto;
  // margin-bottom: 25px;
  margin: 14px 0 40px 0;
}

.login-btn {
  background: master.$primary-color  !important;
  color: #fcfbfe;
  width: 100%;
  border-radius: 12px;
  font-family: master.$font-lato;
  font-weight: 500;
  font-size: 16px;
  margin-top: 25px;
  padding: 12px;
}

.two-fields {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.back-btn {
  margin-bottom: 30px;

  span,
  a {
    background: rgb(111, 124, 237, 0.1);
    border-radius: 6px;
    padding: 10px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: master.$primary-color;
    display: inline-flex;
    align-items: center;
    font-family: master.$font-lato  !important;
    min-width: 110px;
    cursor: pointer;

    img {
      margin-right: 10px;
    }
  }
}

.custom-otp-input input {
  width: 100% !important;
  border: 1px solid rgba(28, 43, 72, 0.1) !important;
  background: transparent;
  border-radius: 12px;
  outline: none;
  height: 100% !important;
}

.custom-otp-input input:focus {
  border: 2px solid #6f7ced !important;
}

.custom-otp-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otpCodeInput {
  width: 58px;
  height: 54px;
  border-radius: 10px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    outline: "none";
  }
}

.time-container {
  gap: 20px;

  p {
    font-family: master.$font-lato;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #6f7ced;
    cursor: pointer;
  }
}

.select-job-title-list-wrap {
  background: #f8fafb;
  border: 1px solid #6f7ced;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 55px;
}

.select-job-title-list-wrap ul {
  margin-bottom: 0;
}

.select-job-title-list-wrap li {
  list-style: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  color: #1c2b48;
  opacity: 0.8;
  margin-bottom: 0px;
}

.auth-copyright {
  font-size: 16px;
  font-family: "Lato";
}

.timer-text {
  font-family: "Lato";
}