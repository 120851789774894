.login-invite-box {
  border: 1px solid #cccdd6;
  border-radius: 10px;
  padding: 20px;
  margin: 23px 0;

  h3,
  p {
    margin-bottom: 0px;
  }

  h3 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
  }

  .details {
    display: flex;
    border-top: 1px solid #cccdd6;
    padding-top: 15px;
    justify-content: space-between;
    gap: 10px;

    .detail-card {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
      .thumb {
        height: 33px;
        width: 33px;
      }
    }

    p {
      font-family: "Poppins";
      font-weight: 500;
      white-space: nowrap;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text-1 {
      font-size: 10px;
      color: #6f7ced;
    }

    .text-2 {
      font-size: 12px;
      color: #000000;
    }

    .text-3 {
      font-size: 12px;
      color: #1c2b48;
      font-family: "Lato" !important;
    }

    .vertical-line {
      width: 1px;
      background: #cccdd6;
    }
  }
}
