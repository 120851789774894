@use "../../master.scss";

.buttons-container {
  display: flex;
  background: rgb(28 43 72 / 10%);
  border-radius: 12px;
  margin-bottom: 15px;

  div {
    width: 160px;
    height: 45px;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    flex-direction: column;
  }

  .active-btn {
    background: #6f7ced;

    p,
    h6 {
      color: #ffffff !important;
    }
  }

  p {
    font-family: "Lato";
    margin: 0;
    color: #1c2b48;
  }

  h6 {
    font-family: "Lato";
    margin: 0;
    color: #1c2b48;

  }
}