.doc-grid-wrapper {
  max-height: 280px;
  overflow-y: auto;

  .checklist-doc-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    padding: 22px 12px 9px 10px;
    background: rgba(198, 235, 252, 0.2);

    h3 {
      font-size: 14px;
      font-weight: 700;
    }

    .card-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
      padding-bottom: 15px;
      width: 100%;

      .top-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        width: calc(100% - 40px);

        .doc-icon {
          padding: 9px 10px 10px 9px;
          background: rgba(111, 124, 237, 0.1);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .doc-info {
          width: calc(100% - 46px);

          h3,
          p {
            font-family: "Lato";
            margin: 0;
            color: #1c2b48;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .upload-text {
            .thumb {
              width: 16px;
              height: 16px;
              border: 2px solid #fff;
              border-radius: 50%;
              margin: 0 2px;
              svg {
                height: 6px;
                width: 6px;
              }
            }

            font-weight: 400;
            font-size: 13px;
            color: rgba(28, 43, 72, 0.5);
            display: flex;
            align-items: center;

            .username {
              width: calc(100% - 200px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #1c2b48;
            }
          }
        }
      }

      .enabled {
        width: 30px;
      }
    }

    .card-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 9px;
      width: 100%;
      user-select: none;
    }

    &.even {
      background: rgba(214, 214, 215, 0.2);
    }
  }
}

.checklist-doc-table {
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  background: #fff;
  padding-bottom: 10px;

  .table-head {
    border-bottom: 1px solid rgba(28, 43, 72, 0.2);
    display: flex;
    align-items: center;

    .head-ttl {
      display: block;
      padding: 12px 24px;
      font-weight: 600 !important;
      font-size: 14px !important;
      color: #1c2b48;
      //   width: 35%;

      svg {
        margin-left: 10px;
      }

      &:first-child {
        width: calc(45% - 3px);
      }

      &:nth-child(2) {
        width: 28%;
      }

      &:nth-child(3) {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .table-body {
    max-height: 220px;
    overflow-y: auto;

    .body-row {
      padding: 0 24px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.1);
      display: flex;
      flex-wrap: wrap;

      .body-col {
        padding: 15px 0;
        display: flex;
        align-items: center;
        width: 35%;

        .doc-icon {
          padding: 7px 8px 8px 7px;
          background: rgba(111, 124, 237, 0.1);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }

        .doc-name {
          max-width: calc(100% - 70px);

          h3 {
            font-weight: 700;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        }

        .uploadBy {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 11px;
          overflow: hidden;

          .thumb {
            height: 22px;
            min-width: 22px;
            max-width: 22px;
            border-radius: 50%;
            svg {
              height: 10px;
              width: 10px;
            }
          }

          .upload-info {
            max-width: calc(100% - 30px);
            overflow: hidden;

            p {
              font-weight: 500;
              font-size: 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              // max-width: 100%;
              line-height: 17px;
            }

            .upload-from {
              font-size: 12px;
              opacity: 0.5;
            }
          }
        }

        .action {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;

          .view-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #6f7ced;
            padding: 9px 13px;
            border-radius: 12px;

            p {
              color: #6f7ced;
              font-family: "Lato";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              text-align: center;
            }
          }
        }

        &:first-child {
          width: calc(45% + 3px);
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:last-child {
          width: calc(25% - 3px);
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      //   &:first-child {
      //     padding-top: 20px;
      //   }

      &:last-child {
        border-color: transparent;
      }
    }
  }
}
