@use "../../../../../master.scss";

.deal-room {
  * {
    font-family: "Lato", sans-serif !important;
  }

  margin: 30px 35px;
  .status-wrapper {
    padding: 0 10px 0 10px;
    width: 90%;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .ant-table table {
    border: none;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: unset;
  }

  .ant-table-column-sorter {
    padding-top: 7px;
    color: #000;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #6f7ced;
  }

  .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #6f7ced;
  }

  .leave-room-btn {
    flex: 1;

    div {
      border: 1px solid #f06b6b;
      border-radius: 8px;
      width: fit-content;
      margin-left: auto;
      padding: 5px 20px;
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      color: #f06b6b;
      cursor: pointer;
    }
  }
  .room-status-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
    .no-access-msg {
      padding: 18px 16px 17px 17px;
      background: rgba(231, 128, 128, 0.1);
      border: 1px solid #f06b6b;
      border-radius: 10px;
      font-family: "Lato";
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #f06b6b;
      width: max-content;
      margin: 0;
    }
    .request-options {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      border: 1px solid #6f7ced;
      border-radius: 14px;
      .accept,
      .reject {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 10px 42px;
        border-radius: 12px;
        color: #6f7ced;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
      }
      .accept {
        background: #6f7ced;
        color: #fff;
      }
    }
    .wait-msg {
      color: #6f7ced;
      margin: 0;
      border: 1px solid #6f7ced;
      border-radius: 12px;
      padding: 10px 48px;
      font-family: "Lato";
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }
  }
  .view-more-visible {
    position: relative;
    cursor: pointer;

    .arrow {
      color: #6f7ced;
      margin-bottom: 2px;
      rotate: 180deg;
    }

    .dropdown {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s all;
      z-index: 6;
      padding: 15px;
      background: #ffffff;
      width: 193px;
      height: max-content;
      box-shadow: 0px 13px 40px rgb(6 16 28 / 29%);
      border-radius: 9px;
      right: 0;
      top: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      max-height: 170px;
      overflow: auto;

      P {
        margin: 0;
        font-family: "Lato";
        font-weight: 500;
        font-size: 13px;
        color: #1c2b48;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
    .dropdown::-webkit-scrollbar {
      width: 8px;
      border-radius: 20px;
    }

    /* Track */
    .dropdown::-webkit-scrollbar-track {
      //   box-shadow: inset 0 0 3px grey;
      border-radius: 20px;
    }

    /* Handle */
    .dropdown::-webkit-scrollbar-thumb {
      background: rgb(169, 169, 169);
      border-radius: 10px;
    }

    /* Handle on hover */
    .dropdown::-webkit-scrollbar-thumb:hover {
      background: rgb(144, 144, 144);
    }
    &:focus {
      .dropdown {
        @extend .show-dropdown;
      }
      .arrow {
        rotate: 0deg;
      }
    }
  }
}

.doc-list {
  .doc-pagination {
    text-align: right;

    button {
      font-size: 30px;
      background: transparent;
      border: none;
      color: #1c2b48;
      margin-left: 20px;
      cursor: pointer;
      height: 20px;

      &:hover {
        color: #6f7ced;
      }
    }
  }

  .document-card {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;

    .header-sec {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.1);

      .thumb {
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(111, 124, 237, 0.1);
        border-radius: 6px;
      }

      .detail {
        margin-left: 12px;

        h3 {
          font-weight: 700;
          font-size: 14px;
          color: #1c2b48;
          margin: 0;
          line-break: strict;
          word-break: break-word;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          color: #1c2b48;
          opacity: 0.5;
          margin: 0;
        }
      }
    }

    .description-sec {
      p {
        font-weight: 400;
        font-size: 14px;
        color: #1c2b48;
        opacity: 0.5;
        margin-bottom: 15px;
        min-height: 65px;
        overflow: hidden;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .attach-box {
        background: rgba(111, 124, 237, 0.2);
        padding: 9px 15px;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        color: #6f7ced;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.car-arrow {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}
.room-disabled {
  .card,
  .work-group-header {
    opacity: 0.5;
    background: #e6e6e6 !important;
    pointer-events: none;
  }
  .checklist-datatable {
    background: transparent;
  }
}
