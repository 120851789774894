.visible-people-list {
  margin-right: 32px;
  display: flex;
  align-items: center;

  &.large {
    margin-right: 40px;
  }

  .thumb {
    width: 28px;
    height: 28px;
    margin-right: -12px;

    &.large {
      width: 42px;
      height: 42px;
      margin-right: -20px;
    }
  }

  .view-more {
    font-size: 10px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    cursor: pointer;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &.large {
      font-size: 13px !important;
      width: 42px;
      height: 42px;
      margin-right: -20px;
    }
  }
}
