.transaction-detail {
  padding-top: 80px;
  height: 100%;

  .transaction-header {
    position: fixed;
    top: 0;
    height: 80px;
    width: calc(100% - 580px);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    flex-wrap: wrap;

    .detail-box {
      display: flex;
      align-items: center;
      gap: 5px;

      h5 {
        margin: 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
      }

      h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        color: #1c2b48;
        max-width: 700px;
        display: block;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media only screen and (max-width: 1462px) {
          max-width: 450px;
        }

        @media only screen and (max-width: 1194px) {
          max-width: 320px;
        }
      }

      svg {
        min-width: 24px;
        min-height: 24px;
        margin-left: 10px;
      }
    }

    .skip-preview {
      border: 1px solid #f06b6b;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      width: 290px;
      padding-right: 10px;
      margin-top: 12px;
      cursor: pointer;

      span {
        background: #f06b6b;
        width: 218px;
        font-weight: 700;
        border-radius: 8px;
        font-size: 14px;
        line-height: 17px;
        padding: 8px 15px;
        color: #f8fafb;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    @media (max-width: 1194px) {
      padding-bottom: 32px;

      .dis {
        position: absolute;
        bottom: 4px;
      }
    }
  }

  .top-bar {
    width: 100% !important;
    background: #6f7ced;
    top: 80.5px;
    position: fixed;
    z-index: 7;
    width: calc(100% - 250px);
    font-family: "Lato";

    // .trans-top-bar {
    //   max-width: 14.3%;
    // }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0px;
      font-family: "Lato";

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: "Lato";

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        span {
          font-family: "Lato";
        }

        cursor: pointer;

        .lst-btn {
          padding: 15px 20px;
          min-height: 70px;
          min-width: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sub-title {
          display: block;
          max-width: 120px;
          margin-right: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .thumb {
          margin-right: 10px;
          position: relative;

          &::before {
            width: 15px;
            height: 8px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border: 3px solid #dddddd;
            border-top: 0;
            position: absolute;
            bottom: -6px;
            left: -9px;
            transform: rotate(30deg);
          }
        }

        .dropdown-caret {
          position: absolute;
          right: 15px;
          font-size: 12px;
        }

        .dropdown-content {
          position: absolute;
          top: 65px;
          background: #3e4798;
          z-index: 99;
          width: 100%;
          display: block;
          border-radius: 0 0 20px 20px;
          overflow: hidden;

          .dropdown-container {
            max-height: 184px;
            overflow: auto;

            li {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .dropdown-container::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #5a67d8;
          }

          .dropdown-container::-webkit-scrollbar {
            width: 5px;
            background-color: #5a67d8;
          }

          .dropdown-container::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #3e4798;
          }

          li {
            display: block;
            min-height: auto;
            padding: 12px 18px;
            background: #5a67d8;

            span {
              font-family: "Lato";
            }

            &:hover,
            &.active {
              background: #6c79ea;
            }

            &.removed {
              opacity: 0.5;
            }

            &.transparent {
              background: transparent;
            }

            &.rounded {
              border-radius: 20px;
            }
          }

          @media (max-width: 1194px) {
            position: fixed;
            top: unset;
            margin-top: 160px;
            width: 204px;
          }
        }

        &.active,
        &:hover {
          background: #5a67d8;
        }

        &.active {
          span {
            font-family: "Lato";

            &::before {
              content: "";
            }
          }
        }
      }
    }

    @media (max-width: 1194px) {
      overflow-x: auto;
    }
  }

  .room-detail {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .thumb {
      background: #6f7ced;
      color: #fff;
      width: 42px;
      height: 42px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      border-radius: 50px;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 15px;
    }

    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 20px;
      color: #1c2b48;
      line-height: 24px;
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      color: #1c2b48;
      line-height: 17px;
    }

    .filter-sec {
      padding: 20px 30px;
      background: #ffffff;
      background: #ffffff;
      border: 1px solid rgba(28, 43, 72, 0.1);
      box-shadow: 0px 47px 54px rgba(6, 16, 28, 0.08);
      border-radius: 12px;
      position: absolute;
      width: 100%;
      top: 60px;
      z-index: 1;

      .close-btn {
        position: absolute;
        right: 25px;
        top: 20px;
        background: #1c2b48;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 17px;
        height: 17px;
        font-size: 11px;
        cursor: pointer;
      }

      h3 {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
        color: #1c2b48;
        color: #1c2b48;
      }

      .ant-select-selector,
      .ant-picker {
        width: 100%;
        background: #f8fafb !important;
        background: #f8fafb !important;
        height: 44px !important;
        border: 1px solid rgba(28, 43, 72, 0.1) !important;
        border-radius: 10px !important;
      }

      .btn-style {
        min-height: 44px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }

  .tbl-list {
    .thumb {
      width: 43px !important;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(111, 124, 237, 0.1);
      border-radius: 50px;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }
  }

  .tbl-list,
  .data-tbl {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      margin: 0;

      li {
        ul {
          display: flex;
          flex-direction: column;
        }
      }
    }

    ul,
    tbody {
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      li,
      td {
        .detail {
          margin-left: 10px;
          width: calc(100% - 50px);

          .point {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #1c2b48;
            margin: 0;
          }
        }

        .title {
          display: block;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          max-width: 110px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .created {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #1c2b48;
          opacity: 0.8;
        }

        .listed {
          margin-top: 5px;
          font-weight: 600;
          font-size: 10px;
          color: #1c2b48;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .groups-list {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 10px;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 19px;
              height: 19px;
              font-size: 8px;
              color: #fff;
              text-transform: uppercase;
              border-radius: 50px;
              margin-left: -5px;
              border: 1px solid #fff;
            }
          }
        }

        .type {
          font-weight: 400;
          font-size: 14px;
          letter-spacing: -0.02em;
          color: #1c2b48;
          opacity: 0.5;
          min-width: 180px;

          b {
            margin-left: 8px;
          }
        }

        .status {
          position: relative;
          width: 95px;
          background: rgba(63, 200, 170, 0.1);
          border-radius: 12px;
          margin-right: 20px;
          font-weight: 700;
          font-size: 10px;
          color: #3fc8aa;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 8px;

          .pointer {
            position: absolute;
            right: 5px;
            border-radius: 20px;
            width: 18px;
            height: 18px;
            background: #3fc8aa;
          }

          &.pending {
            background: rgba(214, 171, 22, 0.1);
            color: #d6ab16;
            justify-content: center;

            .pointer {
              background: #d6ab16;
              left: 5px;
            }
          }
        }

        .action {
          svg {
            font-size: 20px;
            cursor: pointer;
          }
        }

        &.column {
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &.bordered {
      ul {
        border-top: 1px solid rgba(28, 43, 72, 0.1);

        &:first-child {
          border-top: none;
        }
      }
    }
  }

  .work-group-header {
    background: #fff;
    border-radius: 12px;
    padding: 10px 18px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-weight: 700;
      font-size: 14px;
      width: 54px;
      color: #1c2b48;
      margin: 0;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: calc(100% - 60px);

      .group-list {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 33px);

        li {
          display: block;
          border-radius: 50px;
          color: #fff;
          font-weight: 600;
          font-size: 10px;
          margin-left: -10px;
          background: #6f7ced;
          border: 1px solid #fff;

          .thumb {
            width: 29px;
            height: 29px;
            border-radius: 50px;
            overflow: hidden;
            // display: block;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &.view-all {
            padding: 8px 10px;
            cursor: pointer;
            border-radius: 200px;
          }
        }
      }

      .add-group-btn {
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(111, 124, 237, 0.01);
        border: 1px dashed #6f7ced;
        border-radius: 30px;
        color: #6f7ced;
        font-size: 13px;
        cursor: pointer;
      }

      .close-group-btn {
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #262626;
        border-radius: 30px;
        color: #fff;
        font-size: 13px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .work-group-detail {
    margin-left: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -13px;
    height: 100%;
    overflow-y: scroll;
  }
}
