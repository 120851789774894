.pending-approval-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px 21px 30px 35px;

    .modal-body {
      h3,
      span,
      p {
        font-family: "Lato";
        margin: 0;
        color: #1c2b48;
      }

      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .left-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 28px;
        border-right: 1px solid rgba(28, 43, 72, 0.1);
        padding: 15px 30px 0 0;
        p {
          text-align: center;
          text-transform: capitalize;
          width: 240px;
          max-width: 100%;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
      .right-wrapper {
        padding: 10px 0 0 26px;
        .admin-wrapper {
          margin-top: 16px;
          .admin-list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            gap: 15px;
            .left {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 16px;
              max-width: calc(100% - 95px);
              overflow: hidden;
              .thumb {
                height: 32px;
                min-width: 32px;
                max-width: 32px;
                border-radius: 50%;
              }
              .info {
                overflow: hidden;
                h3 {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                p {
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 12px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  opacity: 0.5;
                }
              }
            }
            .status {
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              border-radius: 12px;
              // &.pending {
              //   background: rgba(214, 171, 22, 0.1);
              //   padding: 5px 24px 6px 23px;
              //   color: #d6ab16;
              // }
              // &.approved {
              //   background: rgba(63, 200, 170, 0.1);
              //   color: #569e92;
              //   padding: 5px 20px 6px 20px;
              // }
            }
          }
        }
      }
    }
  }
}
