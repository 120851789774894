.userdetail-drawer {
  z-index: 99999 !important;
  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 12px;
      padding-left: 25px;
      border: none;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 16px;
      color: #1c2b48;
      margin-bottom: 0;
    }

    .flag-dropdown {
      border-radius: 50px;
      border: none;
      margin: 5px 0;
      background: transparent;

      .selected-flag {
        padding: 0;

        .arrow {
          display: none;
        }
      }
    }

    .flag-dropdown:hover {
      background: transparent !important;
    }

    .open {
      z-index: 3 !important;
    }
  }

  .react-tel-input :disabled {
    cursor: default;
  }

  .user-profile-top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    .thumb {
      width: 110px;
      height: 110px;
      cursor: auto;
    }

    h3,
    p {
      margin-bottom: 0;
    }

    h3 {
      font-family: "Lato";
      font-weight: 700;
      font-size: 24px;
      color: #1c2b48;
      text-align: center;
      width: 100%;
      line-break: strict;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .deactivated {
      color: #f06b6b;
      margin: 0;
    }

    p {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 16px;
      color: #93969e;
    }

    .locked-profile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 500px;

      img {
        width: 150px;
        height: 150px;
        margin-bottom: 15px;
      }

      h4 {
        color: #1c2b48;
        margin: 0;
        font-size: 17px;
      }
    }
  }

  .user-profile-title {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    color: #93969e;
    margin-bottom: 5px;
  }
  // .comp-detail {
  //   max-width: calc(100% - 50px);
  //   overflow: hidden;
  // }
  .user-profile-detail {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;

    p {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 16px;
      color: #1c2b48;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .thumb {
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      border-radius: 50px;
    }
  }

  .chat-options-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    padding: 0 10px;
    button {
      font-size: 16px;
      font-weight: 500;
      font-family: "Lato";
      border-radius: 12px;
      width: 100%;
      padding: 12px 0;
    }
    .secondary {
      background: #fff;
      color: #6f7ced;
      border: 1px solid #6f7ced;
    }
    .secondary-red {
      background: #fff;
      color: #f06b6b;
      border: 1px solid #f06b6b;
    }
  }
  .ant-drawer-header {
    border: none !important;
  }
}
