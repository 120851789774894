@use "../../../master.scss";

.messages-container {
  padding: 0px 20px;
  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }
  .chat-row {
    height: calc(100vh - 220px);
  }
  .messages-header {
    margin: 35px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-wrapper {
      h3,
      p {
        font-family: "Lato";
        color: #1c2b48;
      }
      h3 {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;
        margin: 0;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        opacity: 0.5;
      }
    }
    .chat-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      button {
        padding: 15px 18px;
        border-radius: 12px;
        font-family: "Lato";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      .secondary {
        background: transparent;
        color: #6f7ced;
        border: 1px solid #6f7ced;
        &:disabled {
          background-color: transparent !important;
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}
