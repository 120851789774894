.confirm-doc-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        margin-top: 35px;
        h3 {
          font-family: "Lato";
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #1c2b48;
          margin: 0;
        }

        p {
          font-family: "Lato";
          font-weight: 500;
          font-size: 14px;
          opacity: 1;
          color: #1c2b48;
          text-align: center;
          width: 60%;
          margin: 0;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .confirmation-form {
        width: 50% !important;
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: #6f7ced;
        }
        .ant-select-selection-item {
          color: rgba(28, 43, 72, 0.5);
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin: 15px 0 20px;

      .cancel-btn {
        cursor: pointer;
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #6f7ced;
        border: 1px solid #6f7ced;
        border-radius: 12px;
        padding: 12px 56px;
      }

      button {
        background: #6f7ced;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        border-radius: 12px;
        padding: 15px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;

        color: #fcfbfe;
      }
    }
  }
}
