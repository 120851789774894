.create-transaction-wrapper {
  position: relative;

  .ant-select-dropdown {
    z-index: 1 !important;
  }

  .top-heading-wrapper {
    padding: 40px 80px 20px 50px;
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;

    .top-heading {
      .back-btn-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;
        cursor: pointer;

        p {
          margin: 0;
          color: #1c2b48;
          font-size: 16px;
          font-weight: 500;
          opacity: 1;
        }
      }

      h3 {
        color: #131623;
        font-size: 34px;
        font-weight: 700;
      }
    }

    .questions-top-options {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 0;

      span {
        display: flex;
        align-items: center;
        gap: 25px;
      }

      .back-btn-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        p {
          margin: 0;
          // margin-top: 2px;
          color: #1c2b48;
          font-size: 16px;
          font-weight: 500;
          opacity: 1;
        }
      }
    }

    .continue-btn {
      padding: 16px 60px;
      border-radius: 12px;
      align-self: flex-end;
      margin-top: 20px;
      font-size: 16px;
    }
  }

  .create-form {
    padding: 0 80px 20px 50px;

    &.question-form {
      padding-bottom: 80px;
    }

    .question-box {
      position: relative;

      .popover {
        width: calc(100% + 30px);
        left: -15px;
        height: 55px;
        bottom: 8px;
        z-index: 99;
        border-radius: 12px;
        position: absolute;
      }
    }

    .fields-wrapper {
      position: relative;

      .blocker {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 55px;
        width: 100%;
        border-radius: 15px;
      }
    }

    .create-form-item {
      label {
        color: #131623 !important;
        font-size: 20px !important;
        font-weight: 700 !important;
      }

      .create-form-input {
        border-radius: 12px;
        background-color: #f2f2f2;
        padding: 26px 30px;
        font-size: 16px;
      }

      .other-select-input-box {
        border-radius: 12px;
        background-color: #f2f2f2;
        font-size: 16px;

        .placeholder-box {
          padding: 14px 30px;
        }

        .options-box {
          background-color: #f2f2f2;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;

          li {
            padding: 8px 30px;
            font-size: 14px;
          }
        }
      }

      .create-form-input::placeholder {
        font-size: 16px;
      }

      .create-form-select {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 8px;

        .ant-select-selector {
          width: 100%;
          border-radius: 12px;
          background-color: #f2f2f2;
          padding: 26px 30px;
          font-size: 16px;
          display: flex;
          align-items: center;

          .ant-select-selection-search-input {
            height: 50px !important;
            padding-left: 20px;
          }
        }

        .ant-select-arrow {
          svg {
            color: #131623;
          }
        }
      }

      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #5b68db;
      }

      .ant-select-selection-placeholder {
        font-size: 16px;
      }

      .create-form-select::placeholder {
        font-size: 16px;
      }

      .create-form-radio-wrapper {
        .create-form-radio {
          color: rgba(28, 43, 72, 0.8) !important;

          span {
            font-size: 16px !important;
            font-weight: 500 !important;
            font-family: "Lato" !important;
          }

          .ant-radio-inner {
            background-color: #d9d9d9;
            border-color: #d9d9d9;
          }

          .ant-radio-checked .ant-radio-inner {
            background-color: #5b68db;
            border-color: #5b68db;
          }

          .ant-radio-checked .ant-radio-inner::after {
            background-color: #fff;
          }
        }
      }
    }

    .hr-style {
      border-style: dashed;
      border-color: rgba(0, 0, 0, 0.2);
      margin-top: 50px;
      margin-bottom: 10px;
    }
  }

  .progress-bar {
    position: sticky;
    top: 0;
    left: 0;
    height: 2px;
    background: #5b68db;
    z-index: 99;
    transition: 0.3s all;
  }

  .previous {
    margin-top: 20px;
  }

  .create-transaction-tooltip-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .create-form-item {
      width: 100%;
    }

    .create-transaction-info-icon {
      margin-top: 30px;
      cursor: pointer;

      &:hover * {
        fill: #6f7ced;
      }
    }
  }
}

.create-transaction-tooltip {
  padding-top: 5px !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border: 1px solid #535ec2;
    border-radius: 10px;
    border-top-right-radius: 0px;
    color: #1c2b48;
    margin-left: 10px;
    margin-bottom: 15px;
  }
}