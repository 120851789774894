.remove-member-room-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .ant-modal-body {
    position: relative;
    padding: 28px 30px 10px 30px;

    .modal-body {
      .title {
        margin-bottom: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .selected-partymembers {
        position: relative;

        .member-box {
          margin-top: 10px;
          padding: 7px 10px;
          position: relative;
          background: #f2f5fa;
          border-radius: 10px;
          min-width: 98px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          font-size: 12px;
          margin-right: 10px;

          .thumb {
            width: 30px;
            height: 30px;
            border-radius: 50px;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 5px;
          }

          .close-btn {
            right: -8px;
            top: -6px;
            width: 18px;
            height: 18px;
            font-size: 11px;
          }

          .user-details {
            display: flex;
            flex-direction: column;

            b {
              color: #1c2b48;
            }

            p {
              margin: 0;
              font-size: 10px;
              color: rgba(28, 43, 72, 0.5);
            }
          }
        }
      }

      .selected-partymembers::-webkit-scrollbar {
        display: none;
      }

      .ant-select-selector,
      .ant-picker,
      .ant-input {
        width: 100%;
        background: #f8fafb !important;
        height: 44px !important;
        border: 1px solid rgba(28, 43, 72, 0.1) !important;
        border-radius: 10px !important;
      }
    }

    .modal-footer {
      margin-top: 15px;
      text-align: right;

      .leave-btn {
        background: #f06b6b;
        margin-top: 20px;

        &:hover {
          background: #eb7979;
        }

        &:disabled {
          background-color: rgba(240, 107, 107, 0.6) !important;
        }

      }

      .make-btn {
        margin-top: 20px;
        background: #6F7CED;

        &:hover {
          background: #5660be;
        }

        &:disabled {
          background-color: rgba(111, 124, 237, 0.6) !important;
        }
      }


      button {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 160px;
        color: #fff;

        &:hover {
          background: #535ec2;
        }
      }
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .select-form-item {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .ant-select-arrow {
    color: #1c2b48;
  }
}

.create-breakout-tooltip {
  padding-top: 5px !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border: 1px solid #535ec2;
    border-radius: 10px;
    border-top-left-radius: 0px;
    color: #1c2b48;
    margin-left: 10px;
    margin-bottom: 15px;
  }
}

.company-select-options {
  display: flex;
  align-items: center;
  gap: 15px;

  .company-logo {
    width: 30px;
    height: 30px;
    border-radius: 20px;
  }

  p {
    margin: 0;
  }
}