.edit-access-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;
    padding-right: 20px;
    .edit-visibility-form {
      margin-bottom: 50px;
    }
    .ant-input {
      width: 100%;
      background: #f8fafb !important;
      height: 44px !important;
      border: 1px solid rgba(28, 43, 72, 0.1) !important;
      border-radius: 10px !important;

      &.textarea {
        height: 130px !important;
        resize: none;
      }
    }

    .title {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
      }
    }

    .close-btn {
      position: absolute;
      right: 28px;
      top: 15px;
      background: #1c2b48;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50px;
      width: 23px;
      height: 23px;
      cursor: pointer;
    }

    .selected-partymembers {
      display: flex;
      overflow-x: scroll;

      .member-box {
        min-width: 120px !important;
        margin-bottom: 10px;

        .user-detail {
          white-space: nowrap;
          b {
            display: block;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .selected-partymembers::-webkit-scrollbar {
      display: none;
    }
    .create-button button {
      border-radius: 12px;
      font-family: "Lato", sans-serif !important;
      font-weight: 500;
      font-size: 16px;
      margin-top: 15px;
      padding: 12px 60px;
    }

    .cureent-access-wrapper {
      border-left: 3px solid rgba(28, 43, 72, 0.1);
      padding-left: 20px;
      .section-title {
        font-family: "Lato" !important;
        font-weight: 700;
        font-size: 18px;
        color: #1c2b48;
      }
    }
  }
  .edit-access-left-wrapper {
    padding-right: 24px;
    position: relative;
    .select-dropdown {
      max-height: 250px;
    }
    .edit-access-btn {
      position: absolute;
      bottom: 0;
      right: 24px;
      width: 160px;
      padding: 12px 0;
      border-radius: 12px;
      font-size: 16px;
      font-family: "Lato" !important;
    }
  }
}
