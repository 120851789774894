.profile-thumbbox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  overflow: unset !important;
  text-transform: uppercase;
  position: relative;
  border-radius: 50%;

  .blocked-icon {
    position: absolute;
    top: -1px;
    right: -1px;
    height: 14px;
    width: 14px;
    background: #fff;
    border-radius: 50%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
