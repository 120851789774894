.link-card-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  padding: 14px 11px;
  max-width: 100%;
  gap: 15px;

  .visible-people-list {
    margin-right: 12px;
  }

  .link-card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;

    .top-left-wrapper {
      display: flex;
      align-items: center;
      width: calc(100% - 70px);
      gap: 10px;

      .basic-detail {
        width: calc(100% - 46px);
        h3 {
          margin: 0;
          color: #1c2b48;
          font-weight: 700;
          font-size: 14px;
          font-family: "Lato" !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        p {
          margin: 0;
          color: #1c2b48;
          font-weight: 400;
          font-size: 12px;
          font-family: "Lato" !important;
          opacity: 0.5;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .top-right-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        cursor: pointer;
      }
    }

    .working-groups-options {
      padding-bottom: 5px;
      display: flex;

      p {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: rgba(28, 43, 72, 0.8);
        cursor: pointer;
        margin: 0;
      }
    }
  }

  .link-card-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bottom-left-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 50%;
      border-right: 1px solid rgba(112, 126, 174, 0.5);

      h3 {
        margin: 0;
        color: #1c2b48;
        font-weight: 500;
        font-size: 14px;
        font-family: "Lato" !important;
        max-width: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        margin: 0;
        color: #1c2b48;
        font-weight: 400;
        font-size: 12px;
        font-family: "Lato" !important;
        opacity: 0.5;
      }

      .thumb {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }

    .bottom-right-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      .visibility-text {
        font-size: 12px;
        color: #1c2b48;
        font-weight: 500;
        margin: 0;
      }
    }

    .working-groups-options {
      padding-bottom: 5px;
      display: flex;

      p {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: rgba(28, 43, 72, 0.8);
        cursor: pointer;
        margin: 0;
      }
    }
  }
}
