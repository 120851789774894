.move-file-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .title {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
      }
    }

    .close-btn {
      position: absolute;
      right: 28px;
      top: 15px;
      background: #1c2b48;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50px;
      width: 23px;
      height: 23px;
      cursor: pointer;
    }
    .ant-select-selector{
      padding: 0 27px !important;
    }

    .create-button button {
      border-radius: 12px;
      font-family: "Lato", sans-serif !important;
      font-weight: 500;
      font-size: 16px;
      margin-top: 5px;
      padding: 12px 60px;
    }
  }
}

.file-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #1C2B48;
  padding: 0 24px;
}

.file-path {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: #1C2B48;
  opacity: 0.5;
  padding: 0 24px;
}