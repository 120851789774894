.upload-doc-box {
  position: relative;
  background: #f8fafb;
  border: 1px dashed rgba(28, 43, 72, 0.5);
  border-radius: 10px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .drop_area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 99;
  }

  .content {
    text-align: center;
    width: 120px;

    svg {
      width: 30px;
      height: 30px;
    }

    p {
      margin: 0;
      margin-top: 5px;
      color: #1c2b48;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.5;
    }
  }

  &.active {
    border-color: #6f7ced;

    .content {
      width: 100%;

      svg path {
        fill: #6f7ced;
        opacity: 1;
      }

      p {
        color: #6f7ced;
        opacity: 1;
      }
    }
  }
}

.ant-form-item-has-error .upload-doc-box {
  border-color: #ff4d4f;
}
