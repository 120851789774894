.share-document-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px 0 30px 35px;

    .modal-body {
      .title {
        &.visible {
          margin-left: 30px;
        }
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }
      .ant-form-item-has-error {
        .room-select-wrapper {
          border-color: #ff4d4f !important;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .share-form-col {
        padding-right: 33px;
        position: relative;
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: #6f7ced !important;
        }

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
          background: #f5f5f5 !important;
        }
        .ant-select {
          .ant-select-selector {
            border-color: #6f7ced;
          }

          .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .ant-select-selection-placeholder {
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-left: 13px;
            color: #1c2b48 !important;
            opacity: 1;
          }

          .ant-select-arrow {
            svg {
              fill: #1c2b48;
            }
          }
        }

        .note {
          font-family: "Lato";
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #1c2b48;
        }

        .modal-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 40px;
          margin-top: 25px;

          button {
            background: #6f7ced;
            font-weight: 600;
            font-size: 18px;
            color: #1c2b48;
            border-radius: 12px;
            padding: 12px;
            font-family: "Lato";
            font-weight: 500;
            font-size: 16px;

            color: #fcfbfe;
          }
        }
      }

      .visibility-wrapper {
        border-left: 3px solid rgba(28, 43, 72, 0.1);
        // padding-left: 33px;
      }
    }
  }
}
.share-option-odd {
  background: rgba(112, 126, 174, 0.05) !important;
}
