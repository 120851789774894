.no-access-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  img {
    width: 100px;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 16px;
  }
}
