.delete-milestone {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .milestone-detail {
        position: relative;
        margin-top: 25px;
        background: #f8fafb;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        padding: 20px 18px;
        margin-bottom: 30px;

        .ttl {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 15px;

          .thumb {
            width: 43px;
            height: 43px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            svg {
              width: 43px;
              height: 43px;
            }
          }

          h4 {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
            width: 140px;
            color: #1c2b48;
            font-family: "Lato";
          }
        }

        .status {
          position: absolute;
          font-weight: 700;
          font-size: 10px;
          padding: 6px 10px;
          min-width: 70px;
          text-align: center;
          border-radius: 80px;
          color: #3fc8aa;
          background: rgba(63, 200, 170, 0.2);
          right: 18px;
          font-family: "Lato";
          top: 20px;

          &.pending {
            color: #d6ab16;
            background: rgba(214, 171, 22, 0.2);
            font-family: "Lato";
          }
        }

        .config {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #1c2b48;
            opacity: 0.5;
            font-family: "Lato";

            b {
              font-weight: 600;
              margin-left: 4px;
              font-family: "Lato";
            }

            &.note {
              display: block;
              margin-top: 30px;
              font-family: "Lato";
              -webkit-line-clamp: 3;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;

      .cancel-btn {
        cursor: pointer;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: rgb(28, 43, 72, 0.5);
      }

      button {
        background: transparent;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        opacity: 0.5;
        border-radius: 12px;
        padding: 12px;
        font-family: "Lato";

        &.delete-btn {
          opacity: 1;
          background-color: #f06b6b;
          font-weight: 500;
          font-size: 16px;
          min-width: 160px;
          color: #fff;
          font-family: "Lato";

          &:hover {
            background-color: #d14e4e !important;
          }

          &:disabled {
            background-color: #f07e7e !important;
          }
        }
      }
    }
  }
}