.btn-container {
  h4 {
    font-weight: 700;
    line-height: 29px;
    font-family: "Lato";
    margin-bottom: 5px;
  }

  .view-all {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #6f7ced;
      margin-bottom: 0px;
    }
  }
}
