@use "../../../master.scss";

.transactions-wrapper {
  padding: 0 20px;

  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    p,
    h3 {
      margin: 0;
    }
  }

  .table-title {
    font-family: master.$font-lato;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: master.$default-text-color;
    display: flex;
    align-items: center;

    .sort-icon {
      width: 13px;
      height: 13px;
      margin-left: 3px;
    }

    &.active {
      .sort-icon {
        color: #6f7ced;
      }
    }
  }

  .table-container {
    background: #ffffff;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    display: grid;
    overflow: auto;

    li {
      list-style: none;
      width: 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      .ant-tooltip-inner {
        white-space: normal;
      }

      @media (max-width: 1024px) {
        width: 120px;
      }

      &:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        min-height: 40px;
        min-width: 180px;

        // p {
        //   width: calc(100% - 40px);
        // }

        // .trans-name {
        //   display: block;
        //   white-space: nowrap;
        //   text-overflow: ellipsis;
        //   max-width: 250px;
        //   overflow: hidden;
        // }

        .count {
          position: absolute;
          width: 30px;
          right: 3px;
        }
      }
    }
    .t-name {
      overflow: hidden;
    }
    .trans-name {
      display: list-item;
      white-space: nowrap;
      text-overflow: ellipsis;
      // max-width: 200px !important;
      overflow: hidden;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      li:last-child {
        width: 50px;
      }

      P {
        @extend .table-title;
        margin-bottom: 0px;
      }

      & > li > div > div {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      & > li > div p:last-child {
        font-size: 10px;
      }

      .count {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #6f7ced;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgb(28, 43, 72, 0.2);
  }

  .back-gray {
    background: #f2f5fa;
  }
}
