.edit-responsible-modal {
  border-radius: 22px;
  overflow: hidden;
  user-select: none;
  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        margin-bottom: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .responsible-parties-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 480px;
        overflow-y: scroll;

        .responsible-parties {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 5px;
          svg {
            cursor: pointer;
            height: 16px;
            width: 16px;
          }
          .ant-avatar {
            background: #077e8c;
          }
          &:nth-child(even) .ant-avatar {
            background: #e78080;
          }
          .reponsible-party-info {
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: calc(100% - 30px);
            overflow: hidden;
            .thumb {
              min-width: 32px;
              max-width: 32px;
              height: 32px;
              border-radius: 50%;
            }
            .user-info {
              max-width: calc(100% - 30px);
              overflow: hidden;
              p {
                color: #1c2b48;
                font-family: "Lato" !important;
                margin: 0;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              .name {
                font-weight: 600;
                font-size: 14px;
              }
              .email {
                font-weight: 400;
                font-size: 10px;
              }
            }
          }
        }
      }
      /* width */
      .responsible-parties-wrapper::-webkit-scrollbar {
        width: 8px;
      }
      /* Track */
      .responsible-parties-wrapper::-webkit-scrollbar-track {
        //   box-shadow: inset 0 0 3px grey;
        border-radius: 10px;
      }

      /* Handle */
      .responsible-parties-wrapper::-webkit-scrollbar-thumb {
        background: rgb(169, 169, 169);
        border-radius: 10px;
      }

      /* Handle on hover */
      .responsible-parties-wrapper::-webkit-scrollbar-thumb:hover {
        background: rgb(144, 144, 144);
      }
    }

    .modal-footer {
      margin-top: 25px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .remove-all-btn {
        display: flex;
        justify-content: center;
        width: 50%;
        color: #f06b6b;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
      }

      button {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 170px;
        color: #fff;

        &:hover {
          background: #535ec2;
        }
      }
    }
  }
}
