@use "../../master.scss";

.create-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-select-selector {
  background: #f8fafb;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #1c2b48 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none !important;
  border: 1px solid #6f7ced !important;
}
