@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primary-color: #6f7ced;
$primary-hover-color: #6f7ced;
$primary-disabled-color: rgba(111, 124, 237, 0.6);
$text-color: rgb(28 42 71 / 50%);
$text-color-primary: #4d5bd0;
$font-lato: "Lato", sans-serif;
$font-montserrat: "Montserrat", sans-serif;
$font-poppins: "Poppins", sans-serif;
$font-DMsans: "DM Sans", sans-serif;
$default-text-color: #1c2b48;

.primary-color {
  color: $primary-color !important;
}

.font-lato {
  font-family: $font-lato !important;
}

.font-montserrat {
  font-family: $font-montserrat !important;
}

.font-poppins {
  font-family: $font-poppins !important;
}

.font-dmsans {
  font-family: $font-DMsans !important;
}

.drop-down-box {
  transition: 0.3s all;
  z-index: 6;
  padding: 15px;
  box-shadow: 0 0 5px rgba(28, 43, 72, 0.2);
  background: #ffffff;
  border-radius: 10px;
}

.show-dropdown {
  visibility: visible !important;
  opacity: 1 !important;
}
