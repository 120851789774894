.checklist-dashboard {
  .suggestions-box {
    margin-top: 20px;

    h2 {
      font-weight: 700;
      font-size: 18px;
      color: #1c2b48;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 5px;
      cursor: pointer;

      button {
        background: transparent;
        border: none;
        font-size: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          transition: 0.4s all;
        }

        &.active svg {
          transform: rotate(-180deg);
        }
      }
    }

    .suggestions-list {
      height: 0px;
      overflow-x: hidden;
      transition: 0.4s height;

      &.active {
        overflow-y: auto;
        height: 155px;
        padding-right: 5px;
      }

      .suggestion-card {
        margin-top: 12px;
        background: #f8fafb;
        border: 1px solid rgba(28, 43, 72, 0.1);
        padding: 14px 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .suggestion-card-info {
          display: flex;
          flex-direction: column;
          width: calc(100% - 30px);

          h4 {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #1c2b48;
            margin: 0;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            margin: 0;
            margin-top: 4px;
            color: #1c2b48;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .card-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-header {
      width: 100%;

      .filter .filteration .filter-tab li {
        width: 160px;
        padding: 10px;
      }
    }

    &.deleted .card-header {
      width: calc(100% - 50px);
    }

    .right-box {
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px solid rgba(28, 43, 72, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
