.transaction-messages {
  padding-bottom: 20px;
  margin: 0px 35px 30px;
  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }
  .title {
    margin-bottom: 23px;
    h3,
    p {
      font-family: "Lato";
      color: #1c2b48;
      margin: 0;
    }
    h3 {
      font-size: 34px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .chat-box-container {
    .chat-wrapper {
      max-height: calc(100vh - 480px);
      min-height: calc(100vh - 480px);
    }
    .replies-wrapper {
      max-height: calc(100vh - 667px);
      min-height: calc(100vh - 667px);
    }
  }
}
