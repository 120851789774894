.remove-responsible-group-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 3px;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .member-detail {
        position: relative;
        margin-top: 35px;
        background: rgba(111, 124, 237, 0.1);
        border: 1px solid #6f7ced;
        border-radius: 10px;
        padding: 14px 20px;
        margin-bottom: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .thumb {
          width: 52px;
          height: 52px;
          border: 2px solid #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          border-radius: 50%;

          img {
            border-radius: 50%;
            width: 100%;
          }
        }

        .detail {
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 1px;
            color: #1c2b48;
            font-family: "Lato";
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #1c2b48;
            opacity: 0.5;
            font-family: "Lato";
          }
        }
      }
    }

    .modal-footer {
      text-align: right;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      button {
        background: transparent;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        opacity: 0.5;
        border-radius: 12px;
        padding: 12px;
        width: 50%;
        &.cancel {
          &:hover {
            background: none;
          }
        }
        &.delete-btn {
          opacity: 1;
          background-color: #f06b6b;
          font-weight: 500;
          font-size: 16px;
          min-width: 160px;
          color: #fff;

          &:hover {
            background-color: #d14e4e !important;
          }
          &:disabled {
            background-color: #f07e7e !important;
          }
        }
      }
    }
  }
}
