.upload-link-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .ant-input {
      width: 100%;
      background: #f8fafb !important;
      height: 44px !important;
      border: 1px solid rgba(28, 43, 72, 0.1) !important;
      border-radius: 10px !important;

      &.textarea {
        height: 130px !important;
        resize: none;
      }
    }

    .title {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
      }
    }

    .close-btn {
      position: absolute;
      right: 28px;
      top: 15px;
      background: #1c2b48;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50px;
      width: 23px;
      height: 23px;
      cursor: pointer;
    }

    .selected-partymembers {
      display: flex;
      overflow-x: scroll;

      .member-box {
        min-width: 120px !important;
        margin-bottom: 10px;

        .thumb {
          min-width: 22px;
          height: 22px;
        }

        .user-detail {
          white-space: nowrap;
          max-width: calc(100% - 10px);
          overflow: hidden;
          b {
            max-width: 90px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
          }
        }
      }
    }
    .selected-partymembers::-webkit-scrollbar {
      display: none;
    }
    .create-button button {
      border-radius: 12px;
      font-family: "Lato", sans-serif !important;
      font-weight: 500;
      font-size: 16px;
      margin-top: 15px;
      padding: 12px 60px;
    }
  }
}
