.select-document-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px 35px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .create-doc-form {
        .ant-form-item {
          .ant-select-selection-item {
            font-family: "Lato";
            font-weight: 500;
            font-size: 14px;
            color: #1c2b48;
            // opacity: 0.5;
            display: flex;
            align-items: center;
          }
          .ant-select-arrow {
            color: #1c2b48;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;

      button {
        background: #6f7ced;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        border-radius: 12px;
        padding: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;

        color: #fcfbfe;
      }
    }
  }
}
.ant-select-item-option-disabled {
  cursor: default !important;
}
