.custom-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  margin: 10px 0;
  .custom-select-input-open {
    border-color: #6f7ced !important;
  }
  .custom-select-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8fafb;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 10.12px 12px;
    cursor: pointer;
    p {
      margin: 0;
      color: #1c2b48;
      font-family: "Lato" !important;
      font-size: 14px;
      font-weight: 500;
      user-select: none;
    }
    .placeholder-close {
      opacity: 0.5;
      transition: all 0.2s ease-out;
    }
    .placeholder-open {
      transition: all 0.2s ease-in;

      opacity: 1;
    }
    .select-arrow {
      width: 12px;
      transition: all 0.2s ease-out;
      height: 10px;
    }
    .select-arrow-rotated {
      transition: all 0.2s ease-out;
      transform: rotate(180deg);
    }
  }
  .custom-select-dropdown::-webkit-scrollbar {
    display: none;
  }
  .custom-select-dropdown {
    width: 100%;
    background: #ffffff;
    border: 1px solid #6f7ced;
    border-radius: 10px;
    transition: all 2s ease-in;
    display: flex;
    flex-direction: column;
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: 0.8s ease-out;

    .select-all-option {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 14px 12px;
      cursor: pointer;
      width: 100%;
      p {
        margin: 0;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #707eae;
      }
    }
    .select-all-option:hover {
      background: rgba(112, 126, 174, 0.15);
    }

    .custom-select-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      user-select: none;
      svg {
        cursor: pointer;
      }
      .select-option-left {
        display: flex;
        align-items: center;
        gap: 10px;
        max-width: calc(100% - 50px);
        .thumb {
          height: 32px;
          min-width: 32px;
          max-width: 32px;
        }
        .name-email {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .select-option-name {
            font-family: "Lato" !important;
            font-weight: 500;
            font-size: 14px;
            color: #1c2b48;
            margin: 0;
          }
          .select-option-email {
            font-family: "Lato" !important;
            font-weight: 400;
            font-size: 10px;
            color: #1c2b48;
            opacity: 0.5;
            margin: 0;
          }
        }
      }
    }
  }
}
