.dataroom-notes-wrapper {
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  padding: 16px;
  width: 33%;
  height: fit-content;
  max-height: 100%;
  h3 {
    font-family: "Lato" !important;
    font-weight: 700;
    font-size: 18px;
    color: #1c2b48;
  }
  .add-note-btn {
    width: 100%;
    border-radius: 12px;
    padding: 12px 0;
    font-size: 16px;
    margin-top: 20px;
  }
  @media (max-width: 1194px) {
    width: 47%;
  }
}
