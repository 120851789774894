.invite-modal {
  border-radius: 22px;
  overflow: hidden;
  padding: 20px;

  .ant-select-selection-item {
    display: flex;
  }
  .invite-advised-party-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    button {
      padding: 11px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: max-content;
      margin-bottom: 19px;
      font-size: 22px;
    }

    .ant-form-item {
      width: 100%;
    }

    .invite-info-icon {
      margin-bottom: 15px;

      &:hover * {
        fill: #6f7ced;
      }
    }

    .ant-tooltip {
      max-width: 350px !important;
    }

    .invite-participant-tooltip {
      padding-top: 5px !important;

      .ant-tooltip-arrow {
        display: none;
      }

      .ant-tooltip-inner {
        border-radius: 10px;
        border-top-left-radius: 0px;
        color: #1c2b48 !important;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 12px !important;
      }
    }

    // p {
    //   bottom: -2px;
    //   position: absolute;
    //   margin: 0;
    // }
  }

  .selected-individuals-wrapper::-webkit-scrollbar {
    display: none;
  }

  .selected-individuals-wrapper {
    display: flex;
    overflow-x: scroll;

    .member-box {
      margin-top: 10px;
      padding: 7px 10px;
      position: relative;
      background: #f2f5fa;
      border-radius: 10px;
      min-width: 180px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      font-size: 12px;
      margin: 10px 10px 5px 0;
      max-width: 185px;

      .thumb {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        overflow: hidden;
        // display: block;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .close-btn {
        right: -8px;
        top: -6px;
        width: 18px;
        height: 18px;
        font-size: 11px;
      }

      .user-details {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 45px);
        overflow: hidden;
        b,
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        b {
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-size: 10px;
          color: rgba(28, 43, 72, 0.5);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .heading {
    font-family: "Lato";
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1c2b48;
  }

  .last-modified-text {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(28, 43, 72, 0.5);
    margin-top: 5px;
  }

  .invite-form-wrapper {
    margin-top: 25px;

    .ant-select-selector,
    .ant-input {
      border: 1px solid rgba(28, 43, 72, 0.1) !important;
      border-radius: 10px !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      padding: 20px 24px !important;
      color: #1c2b48;

      * {
        font-family: "Lato";
      }

      &:focus {
        border-color: #6f7ced !important;
      }

      input {
        padding: 13px 14px !important;
      }

      &.ant-input-status-error {
        border-color: #ff4d4f !important;
      }
    }

    .ant-select-status-error .ant-select-selector {
      border-color: #ff4d4f !important;
    }

    .Add-company-button {
      text-align: right;
      margin-top: 20px;
    }
  }
}

.add-company-form,
.add-individual-form {
  height: 100%;
  .placeholder-box {
    padding: 10px 24px;
  }
  .Add-individual-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {
      margin-top: 20px;
    }
  }

  button {
    padding: 10px 60px;
  }
  .company-tab-tooltip-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    .ant-form-item {
      width: 100%;
    }
    .invite-info-icon {
      margin-bottom: 20px;

      &:hover * {
        fill: #6f7ced;
      }
    }
    .invite-participant-tooltip {
      padding-top: 5px !important;

      .ant-tooltip-arrow {
        display: none;
      }

      .ant-tooltip-inner {
        border-radius: 10px;
        border-top-left-radius: 0px;
        color: #1c2b48 !important;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 12px !important;
      }
    }
  }
}

.ant-select-selection-search {
  input {
    height: 100% !important;
  }
}

.ant-input {
  border-radius: 10px;
}

.already-assign-advisor {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;

  .user-card {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
      font-family: "Lato";
      letter-spacing: -0.02em;
    }

    p:first-child {
      font-weight: 600;
      font-size: 14px;
      color: #1c2b48;
    }

    p:last-child {
      font-weight: 400;
      font-size: 10px;
      color: rgb(28, 43, 72, 0.5);
    }

    svg {
      flex: 1;
      cursor: pointer;
    }
  }
}
