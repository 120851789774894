.request-card {
  border: 1px solid #6f7ced;
  border-radius: 10px;
  width: 326px;
  overflow: hidden;
  padding-bottom: 20px;

  .bag-image {
    background-image: url("../../assets/backgrounds/request-bag-mask.svg");
    height: 73px;
    background-color: #d4d8fa;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-view {
    padding-inline: 20px;
  }

  .thumb {
    width: 71px;
    height: 71px;
    border: 2px solid #ffffff;
    margin-top: -60px;
  }

  .details {
    display: flex;
    justify-content: space-between;

    div {
      width: 45%;
    }

    p {
      font-family: "Lato";
      font-weight: 400;
      font-size: 14px;
      color: #1c2b48;
      opacity: 0.5;
      margin-bottom: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name {
      opacity: 1;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #6f7ced;
      border: 1px solid #6f7ced;
      border-radius: 12px;
      padding: 10px 35px;
      font-weight: 400;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
    }

    .remove {
      border: 1px solid #f06b6b;
      color: #f06b6b;
      background: #fff;
    }
  }
}