.filter-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  background: #f8fafb;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 5px;
  user-select: none;

  .content-wrapper {
    width: calc(100% - 30px);

    h3,
    p {
      color: #1c2b48;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
    }

    p {
      font-size: 12px;
      opacity: 0.5;
    }

    &.selected-text h3,
    &.selected-text p {
      color: #fff;
    }
  }

  svg {
    min-height: 17px;
    min-width: 17px;
  }

  .cancel-icon {
    path {
      fill: #fff;
    }
  }
}

.selected-filter {
  background: #6f7ced;
  border: 1px solid #6f7ced;
}