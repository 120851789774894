.activity-logs {
  padding: 30px 0 0;
  background: #ffffff;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  margin-left: 18px;

  // overflow-y: scroll;
  // margin-bottom: 20px;
  // max-height: 975px;
  .title-box {
    margin: 0 25px 8px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #1c2b48;
      margin: 0;
      font-family: "Lato" !important;
    }

    p {
      margin: 0;
      margin-top: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #1c2b48;
      opacity: 0.5;
      font-family: "Lato" !important;
    }
  }

  .logs-list {
    padding: 20px 25px 0;
    margin-right: 2px;
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 28px;
      position: relative;

      .thumb {
        z-index: 4;
        width: 40px;
        height: 40px;
        background: #fff;
        margin-right: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .detail {
        width: calc(100% - 55px);

        .title {
          display: block;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #1c2b48;
          font-family: "Lato" !important;
        }

        .time {
          display: block;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #1c2b48;
          font-family: "Lato" !important;
          opacity: 0.5;
        }

        .activity {
          display: block;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          margin-top: 10px;
          color: #1c2b48;
          opacity: 0.8;
          font-family: "Lato" !important;
        }
      }

      &::before {
        position: absolute;
        content: "";
        opacity: 0.5;
        border: 1px dashed #1c2b48;
        height: 100%;
        left: 20px;
      }

      &:last-child {
        &::before {
          content: none;
        }
      }
    }
  }
  .logs-list::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .logs-list::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  .logs-list::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  .logs-list::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  @media (max-width: 1194px) {
    margin-left: 0;
    margin-top: 20px;
  }
}
