.responsible-individuals {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-weight: 700;
    font-size: 18px;
    font-family: "lato";
    color: #1c2b48;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  button {
    font-size: 16px;
    border: 1px solid #6f7ced;
  }
}

.responsible-parties {
  &-card {
    position: relative;
    padding: 12px 20px;
    background: rgba(111, 124, 237, 0.1);
    border: 1px solid #6f7ced;
    border-radius: 10px;
    align-items: center;
    display: flex;
    .text-container {
      max-width: calc(100% - 160px);
      overflow: hidden;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .delete-icon {
      position: absolute;
      right: 20px;
    }

    p {
      margin-bottom: 0px;
      font-family: "Lato";
      color: #1c2b48;
      cursor: pointer;
    }

    p:nth-child(1) {
      font-weight: 500;
      font-size: 14px;
    }

    p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      opacity: 0.5;
    }
  }
}

.note-para {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c2b48;
  opacity: 0.5;
  max-height: 120px;
  overflow: hidden;
  overflow-y: scroll;
}

.visibility-rooms {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-weight: 700;
    font-size: 18px;
    font-family: "lato";
    color: #1c2b48;
    margin-bottom: 0;
  }

  .rooms {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-avatar-group .ant-avatar:not(:first-child) {
      margin-left: -10px;
    }

    .ant-avatar-group .ant-avatar {
      width: 28px;
      height: 28px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;

      * {
        display: flex;
        align-items: center;
        justify-content: center;
        left: auto;
        top: 0;
        line-height: normal;
        width: 100%;
        height: 95%;
        transform: none !important;
      }
    }

    .add-btn {
      border: 1px dashed #6f7ced;
      width: 24px;
      height: 24px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      font-size: 10px;
      color: #6f7ced;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        background: #6f7ced;
        color: #fff;
      }
    }
  }
}

// start

.milestone-detail-modal {
  display: flex;
  height: 650px;
  overflow: hidden;
  border-radius: 22px !important;
  .milestone-edit {
    height: 24px;
    width: 24px;
  }
  .milestone-delete {
    height: 26px;
    width: 26px;
  }
  .card {
    width: 70%;
  }

  .activity-logs {
    width: 30%;
    border: none;
  }

  .ant-modal-body {
    padding: 28px 5px;
  }

  .ant-popover {
    z-index: 9999 !important;
  }

  .ant-popover .ant-avatar-group-popover .ant-popover-placement-bottom {
    z-index: 9999 !important;
  }

  .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    z-index: 9999 !important;
  }
}

.milestones-details {
  .flag {
    height: 50px;
    width: 50px;
  }

  .start-end-date-container {
    display: flex;
    gap: 30px;
    width: max-content;
  }
}
