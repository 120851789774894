@use "../../master.scss";

.three-dot-icon {
  position: relative;
  cursor: pointer;

  svg {
    height: 20px;
    user-select: none;
  }
}

.dot-option-box {
  margin: 0;
  font-family: master.$font-lato;
  font-weight: 400;
  font-size: 13px;
  padding: 3px 0;
  line-height: 17px;
  color: rgb(28, 43, 72, 0.8);

}