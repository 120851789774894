.create-milestone {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        margin-bottom: 14px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .selected-partymembers {
        display: flex;
        overflow-x: auto;
        position: relative;
        padding-bottom: 10px;

        .member-box {
          margin-top: 10px;
          padding: 7px 10px;
          position: relative;
          background: #6f7ced;
          border-radius: 10px;
          min-width: 135px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          font-size: 12px;
          margin-right: 10px;

          .thumb {
            width: 20px;
            height: 20px;
            border-radius: 50px;
            overflow: hidden;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 5px;

            .blocked-icon {
              width: 8px;
              height: 8px;
            }
          }

          .close-btn {
            right: -8px;
            top: -6px;
            width: 18px;
            height: 18px;
            font-size: 11px;
          }

          b {
            max-width: 85px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      // .selected-partymembers::-webkit-scrollbar {
      //   display: none;
      // }

      .ant-form-item {
        margin-top: 25px;
      }

      .ant-select-selector,
      .ant-picker,
      .ant-input {
        width: 100%;
        background: #f8fafb !important;
        height: 44px !important;
        border: 1px solid rgba(28, 43, 72, 0.1) !important;
        border-radius: 10px !important;

        &.ant-picker-focused,
        &:focus {
          box-shadow: none;
          border: 1px solid #6f7ced !important;
        }

        &.ant-picker-status-error {
          border-color: #ff4d4f !important;
        }

        &.textarea {
          height: 145px !important;
          resize: none;
        }
      }
    }

    .modal-footer {
      margin-top: 15px;
      text-align: right;

      button {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 170px;
        color: #fff;

        &:hover {
          background: #535ec2;
        }
      }
    }
  }
}

.visibility-options {
  display: flex;
  align-items: center;
  max-width: calc(100% - 10px);
  overflow: hidden;
  p {
    margin: 0;
    font-family: "Lato";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    width: 33px;
    height: 33px;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-family: "Lato";
  }

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .email {
    font-family: "Lato";
    font-weight: 400;
    font-size: 10px;
    letter-spacing: -0.02em;
    color: rgb(28, 43, 72, 0.5);
  }
}
