.thread-wrapper {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  margin-left: 15px;
  height: 100%;
  .thread-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 9px;
    border-bottom: 1px solid #d9d9d9;
    h3 {
      color: #000;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
    svg {
      height: 14px;
      width: 14px;
    }
  }
  .message-item {
    gap: 15px;
    .thumb {
      min-width: 22px;
      max-width: 22px;
      height: 22px;
      // align-self: flex-start;
    }
    .message-content-wrapper {
      max-width: unset;
      // width: 100%;
      .message-box {
        width: 100%;
        .message-content {
          font-size: 10px;
        }
      }
      .date-wrapper {
        p {
          color: rgba(28, 43, 72, 0.5);
          font-size: 10px;
        }
      }
      .message-react-options {
        .emoji-wrapper {
          .emoji-dropdown-wrapper {
            z-index: 999999;
            // left: 0;
            right: -40px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  .parent-message {
    padding: 21px 12px 14px 9px;
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    margin-bottom: 15px;
    .reply-count {
      position: absolute;
      left: 0;
      bottom: -10px;
      background: #fff;
      padding: 0 9px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .visible-people-list {
        margin: 0;
        .thumb,
        .view-more {
          width: 16px;
          height: 16px;
          margin-right: -7px;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.5);
        font-family: "Lato";
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        margin-left: 12px;
      }
    }
  }

  .replies-wrapper {
    display: flex;
    flex-direction: column-reverse;
    max-height: calc(100vh - 635px);
    min-height: calc(100vh - 635px);
    overflow-y: auto;
    // overscroll-behavior: initial;
    padding-right: 5px;
    .replies-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 5px 9px;
      .chat-date {
        width: 100%;
        text-align: center;
        color: #434445;
        font-family: "Lato";
        font-size: 10px;
        font-weight: 400;
      }

      .highlight {
        width: 100%;
        font-size: 8px;
        text-align: center;
        opacity: 0.5;
      }
    }
  }
  .reply-input {
    padding: 5px 9px;
    .chat-input-container {
      .chat-input-wrapper {
        .inputBox_inputWrapper-input {
          max-height: 52px;
          width: 100%;
          max-width: calc(100% - 65px);
          overflow-y: auto;
          .inputBox_inputWrapper-input__highlighter {
            max-height: 52px;
          }
          textarea {
            width: 100%;
            border: none;
            font-family: "Poppins", "color-emoji" !important;
            resize: none;
            height: 16px;
            overflow-y: auto !important;
            font-size: 10px;

            &::placeholder {
              color: rgba(67, 68, 69, 0.5) !important;
              font-family: "Poppins" !important;
              font-size: 10px !important;
              font-weight: 400 !important;
              font-style: italic;
            }

            &:disabled {
              background: transparent;
            }
          }
        }
        .chat-options {
          gap: 8px;
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
