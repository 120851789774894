@use "../../master.scss";

.transaction-details-card {
  width: 100%;
  gap: 20px;
  display: flex;

  p {
    margin-bottom: 0px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
  }

  .numbers {
    font-family: master.$font-DMsans;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}

.transactionsComplete {
  background: linear-gradient(135deg, #6f7ced 0%, #3440a7 100%) !important;
  align-items: center;
  justify-content: space-between;

  & > * p {
    color: #fff !important;
    opacity: 1 !important;
  }
}
