@use "../../../../../../master.scss";

.remove-enterprise-admin-modal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        margin-bottom: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .select-form-item {
        display: flex;
        align-items: center;
        gap: 10px;
        .tooltip-icon {
          margin-bottom: 20px;
          cursor: pointer;
          &:hover {
            path {
              fill: #1c2b48 !important;
            }
          }
        }
      }
      .submit-btn {
        background: #6f7ced;
        &:hover {
          background-color: master.$primary-hover-color !important;
          color: #fff;
        }
        &:disabled {
          background-color: master.$primary-disabled-color !important;
          cursor: auto;
        }
      }
    }

    .modal-footer {
      margin-top: 0;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end !important;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .leave-btn {
        border-radius: 12px;
        padding: 12px;
        background: #f06b6b;
        font-weight: 500;
        font-size: 16px;
        min-width: 170px;
        color: #fff;

        &:hover {
          background: #d14e4e !important;
        }

        &:disabled {
          background-color: rgba(240, 107, 107, 0.6) !important;
        }
      }
    }
  }
}
