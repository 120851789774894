.features-wrapper {
	position: relative;
	&:before {
		content: "";
		width: 336px;
		height: 379px;
		border-radius: 379px;
		opacity: 0.15;
		background: #6f7ced;
		filter: blur(150px);
		position: absolute;
		left: 0px;
		bottom: 0;
	}
	.content {
		h1 {
			margin-bottom: 45px;
		}
		.features-list {
			display: flex;

			.vertical-tabs {
				display: flex;
				flex-direction: column;
			}

			.vertical-tab {
				cursor: pointer;
				padding: 9px 25px;
				border-radius: 12px;
				h3 {
					color: #1c2b48;
					font-family: "Poppins";
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.4px;
					text-transform: capitalize;
					margin-bottom: 3px;
				}
				p {
					color: #1c2b48;
					font-family: "Poppins";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.32px;
					margin-bottom: 2px;
					opacity: 0.8;
				}
			}

			.vertical-tab:hover {
				background-color: #f1f2fd;
			}

			.vertical-tab.active {
				background-color: #6f7cee;
				box-shadow: 0px 26px 50px 0px rgba(111, 124, 238, 0.2);
				h3,
				p {
					color: #fff;
				}
			}

			@keyframes imageTransition {
				0% {
					opacity: 0;
				}
				50% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			.vertical-tabs-content {
				display: flex;
				align-items: stretch;
				justify-content: flex-end;
				max-width: 719px;
				width: 100%;
				margin-left: auto;
				margin-top: 52px;
				transform: translateZ(0);

				.content-image {
					filter: drop-shadow(0px 26px 50px rgba(119, 137, 164, 0.1));
					// opacity: 0;
					// animation: imageTransition 0.4s ease-in-out;
					// animation-fill-mode: forwards;
					// -webkit-transition: opacity 1s ease-in-out;
					// -moz-transition: opacity 1s ease-in-out;
					// -o-transition: opacity 1s ease-in-out;
					// transition: opacity 1s ease-in-out;
					width: 100%;
					height: auto;
					opacity: 1;
					-webkit-transition: opacity 0.4s ease-in-out;
					-moz-transition: opacity 0.4s ease-in-out;
					-o-transition: opacity 0.4s ease-in-out;
					transition: opacity 0.4s ease-in-out;
				}

				.animate {
					-webkit-animation: fadeIn 0.3s ease-in-out;
					-o-animation: fadeIn 0.3s ease-in-out;
					-moz-animation: fadeIn 0.3s ease-in-out;
					animation: fadeIn 0.3s ease-in-out;
				}

				@keyframes fadeIn {
					0% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}
		}
	}
}
