.custom-trans-select {
  z-index: 5;
  position: relative;
  .company-autocomplete-dropdown {
    z-index: 999999;
    left: 0 !important;
    right: 0 !important;
    top: 50px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .ant-select {
    position: relative;
    padding: 23px 4px 25px 35px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      background-image: url(../../assets/icons/company.svg);
      position: absolute;
      left: 12px;
      width: 20px;
      height: 20px;
      background-size: cover;
      background-position: center;
    }
  }
  .ant-input-prefix {
    // position: absolute;
    top: 15px;
    left: 12px;
    z-index: 999;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 0 !important;
  }

  .ant-select-selector {
    border: 0 !important;
    padding: 0 !important;
    outline: none;
    padding-left: 10px !important;
  }

  .ant-select-selection-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    right: 0;
  }

  span[title="Company Name"] {
    color: rgb(28, 43, 72, 0.2);
  }

  .ant-input-affix-wrapper {
    ::before {
      visibility: visible !important;
    }
    .ant-select.ant-select-in-form-item {
      width: 100% !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      height: 100%;

      &:disabled {
        color: #b3bac0 !important;
      }
    }
  }

  .ant-select-selection-placeholder {
    font-family: "Lato";
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(28, 43, 72, 0.2);
  }
}
.autocomplete-option-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .thumb {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .name {
    display: block;
    width: calc(100% - 32px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }
}
.input-disabled {
  .ant-select {
    background: #f5f5f5 !important;
    .ant-select-selection-search-input {
      color: rgba(112, 128, 139, 0.5) !important;
    }
  }
}
