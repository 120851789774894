.create-group-modal {
  border-radius: 22px;
  overflow: hidden;

  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .ant-modal-body {
    position: relative;
    padding: 33px;

    .modal-body {
      .title {
        margin-bottom: 30px;

        h3 {
          color: #1c2b48;
          font-family: "Lato";
          font-size: 18px;
          font-weight: 700;
        }
      }

      .selected-partymembers {
        display: flex;
        overflow-x: auto;
        position: relative;
        padding-bottom: 10px;

        .close-btn {
          position: absolute;
          right: 15px;
          top: 15px;
          background: #1c2b48;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 50px;
          width: 23px;
          height: 23px;
          cursor: pointer;
        }

        .member-box {
          margin-top: 10px;
          padding: 7px 10px;
          position: relative;
          background: #f2f5fa;
          border-radius: 10px;
          min-width: 160px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          font-size: 12px;
          margin-right: 10px;

          .thumb {
            min-width: 30px;
            height: 30px;
            border-radius: 50px;
            overflow: hidden;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 5px;

            img {
              border-radius: 20px;
            }

            .blocked-icon {
              width: 8px;
              height: 8px;
            }
          }

          .close-btn {
            right: -8px;
            top: -6px;
            width: 18px;
            min-height: 18px;
            font-size: 11px;
            display: flex;
          }

          .user-details {
            display: flex;
            flex-direction: column;

            b {
              color: #1c2b48;
              max-width: 90px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p {
              margin: 0;
              font-size: 10px;
              color: rgba(28, 43, 72, 0.5);
              max-width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .selected-partymembers::-webkit-scrollbar {
        display: none;
      }
      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .modal-footer {
        margin-top: 15px;
        text-align: right;
        * {
          margin-bottom: 0;
        }
        button {
          border-radius: 12px;
          padding: 12px;
          background: #6f7ced;
          font-weight: 500;
          font-size: 16px;
          min-width: 139px;
          color: #fff;

          &:hover {
            background: #535ec2;
          }
        }
      }
    }
  }
}
