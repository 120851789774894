.version-preview-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 27px 35px 25px 34px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }
      .preview-data {
        max-height: 125px;
        overflow-y: auto;
        background: #f8fafb;
        padding: 13px 16px 13px 24px;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        p {
          margin: 0;
          color: rgba(28, 43, 72, 0.5);
          white-space: pre-line;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          //   box-shadow: inset 0 0 3px grey;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgb(169, 169, 169);
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgb(144, 144, 144);
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
      margin-top: 19px;

      button {
        background: #6f7ced;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        border-radius: 12px;
        padding: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
        color: #fcfbfe;
      }
    }
  }
}
