@use "../../master.scss";

.ac-box-wrapper {
  width: 334px;
  max-width: 95%;

  .ac-ip-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #363645;
    font-family: master.$font-poppins;
    display: flex;
    gap: 5px;
    p {
      color: red;
      font-size: 16px;
      margin: 0;
    }
  }

  .ac-ip-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px !important;

    .ac-ip-input {
      padding: 22px 30px !important;
      width: 100% !important;
      background: #f8f9fb !important;
      border-radius: 15px !important;
      border: none !important;
      margin: 0 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #70808b !important;

      &::placeholder {
        opacity: 0.5;
      }
    }

    .edit-btn {
      display: flex;
      position: absolute;
      right: 20px;
    }
  }
}
