@use "../../../../../../../master.scss";

.add-doc-modal {
  border-radius: 22px;
  overflow: hidden;

  * {
    font-family: master.$font-lato;
  }

  .ant-modal-body {
    position: relative;
    padding: 36px 40px 32px 36px;

    .modal-body {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
      }
      .title {
        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 25px;
        top: 30px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .new-doc-btn {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 170px;
        color: #fff;
        border: 1px solid #6f7ced;
        width: 100%;
        &:hover {
          background: #535ec2;
        }
      }
    }

    .existing-docs-wrapper {
      margin: 10px 0;
      .doc-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 18px;
        user-select: none;
        &::after {
          content: "";
          border-bottom: 1px solid rgba(111, 124, 237, 0.2);
          width: 10px;
          display: block;
          position: absolute;
          bottom: 0;
          width: 90%;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
        h3,
        p {
          font-family: "Lato";
          color: #1c2b48;
          margin: 0;
        }
        svg {
          height: 25px;
          width: 25px;
        }
        .left-wrapper {
          display: flex;
          align-items: center;
          gap: 15px;
          width: 40%;
          .doc-thumb {
            padding: 10px;
            background: rgba(111, 124, 237, 0.1);
            height: 43px;
            width: 43px;
            border-radius: 6px;
          }
          .doc-info {
            p,
            h3 {
              max-width: 200px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            h3 {
              font-weight: 700;
              font-size: 14px;
            }
            p {
              font-weight: 400;
              font-size: 14px;
              opacity: 0.5;
            }
          }
        }
        .right-wrapper {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .doc-child-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: max-content;
            .child-wrapper {
              svg {
                height: 19px;
                width: 19px;
              }
              width: max-content;
              background: rgba(111, 124, 237, 0.2);
              gap: 10px;
              display: flex;
              padding: 10px 32px;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              p {
                font-weight: 700;
                font-size: 14px;
                color: #6f7ced;
              }
            }
          }
          .doc-plus {
            path {
              fill: #000;
            }
          }
        }
      }
    }

    .existing-docs-wrapper ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    .existing-docs-wrapper ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    .existing-docs-wrapper ::-webkit-scrollbar-thumb {
      background: rgb(169, 169, 169);
      border-radius: 10px;
    }

    /* Handle on hover */
    .existing-docs-wrapper ::-webkit-scrollbar-thumb:hover {
      background: rgb(144, 144, 144);
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
      button {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 170px;
        color: #fff;
        border: 1px solid #6f7ced;

        &:hover {
          background: #535ec2;
        }
      }
    }
  }
}
