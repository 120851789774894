html,
body {
  scroll-behavior: smooth;
  min-height: 95%;
  margin: 0;
  background-color: #fafcfe !important;
  // overflow: hidden !important;
}

*,
input,
button {
  outline: none;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  font-size: 4px;
}

.layout-wrapper {
  position: relative;
  overflow: hidden;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: center;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 100%;
}

.ant-picker-dropdown {
  z-index: 999999 !important;
}

// // scrollbar style
// ::-webkit-scrollbar {
//   width: 6px;
// }
// /* Track */
// ::-webkit-scrollbar-track {
//   background: transparent;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
//   border-radius: 50px;
// }
// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

::-webkit-file-upload-button {
  cursor: pointer;
}

@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}

body > iframe {
  display: none !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  font-size: 12px;
}

.__react_modal_image__modal_container {
  z-index: 9999999999;
}

.ant-breadcrumb li {
  color: #000 !important;
}

.ant-breadcrumb a {
  color: inherit !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.input-icon {
  margin-right: 10px;
}

.trans {
  transition: all 0.3s;
}

.card {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
}

/* width */
.card ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.card ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
.card ::-webkit-scrollbar-thumb {
  background: rgb(169, 169, 169);
  border-radius: 10px;
}

/* Handle on hover */
.card ::-webkit-scrollbar-thumb:hover {
  background: rgb(144, 144, 144);
}

.design-shadow {
  border: 1px solid #f4f6f9;
  box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.08);
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-10 {
  margin-right: 10px;
}

.p-l-0 {
  padding-left: 0 !important;
}

.c-p {
  cursor: pointer;
}

.na-p {
  cursor: default;
}

.rowgap-15 {
  row-gap: 15px;
}

.u-s-n {
  user-select: none;
}

.btn-transparent {
  border-style: none;
  background: transparent;
}

.ant-select-item {
  font-size: 13px !important;
}

body .ant-select-dropdown {
  border-radius: 10px !important;
  box-shadow: 0px 47px 54px rgba(6, 16, 28, 0.08) !important;
  z-index: 999999;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #f8fafb !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #e2e5ff !important;
}

.ant-modal-mask {
  z-index: 999;
}

.ant-modal-wrap {
  z-index: 999;
}

.selected-btn {
  background-color: #6f7ced;
  color: #fff;
}

.ant-modal-close {
  display: none;
}

.ant-table-measure-row {
  visibility: collapse;
}

.ant-modal-body {
  position: relative;
  padding: 28px 30px;

  .modal-body {
    .title {
      margin-bottom: 20px;

      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
        font-family: "Lato";
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
        font-family: "Lato";
      }
    }

    .close-btn {
      position: absolute;
      right: 15px;
      top: 15px;
      background: #1c2b48;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50px;
      width: 23px;
      height: 23px;
      cursor: pointer;
    }

    .selected-partymembers {
      position: relative;
      scrollbar-width: none;

      .member-box {
        margin-top: 10px;
        padding: 7px 10px;
        position: relative;
        background: #6f7ced;
        border-radius: 10px;
        min-width: 98px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        color: #fff;
        font-size: 12px;
        margin-right: 10px;

        .thumb {
          min-width: 20px;
          width: 20px;
          height: 20px;
          border-radius: 50px;
          display: flex;
          align-content: center;
          justify-content: center;
          margin-right: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .close-btn {
          right: -8px;
          top: -6px;
          width: 18px;
          height: 18px;
          font-size: 11px;
        }
      }
    }

    .selected-partymembers::-webkit-scrollbar {
      display: none;
    }

    .ant-select-selector,
    .ant-picker,
    .ant-input {
      width: 100%;
      background: #f8fafb !important;
      height: 44px !important;
      border: 1px solid rgba(28, 43, 72, 0.1);
      border-radius: 10px !important;

      &.ant-input-status-error,
      &.ant-picker-status-error {
        border-color: #ff4d4f !important;
      }
    }
  }

  .modal-footer {
    margin-top: 15px;

    button {
      border-radius: 12px;
      padding: 12px;
      background: #6f7ced;
      font-weight: 500;
      font-size: 16px;
      min-width: 170px;
      color: #fff;

      &:hover {
        background: #535ec2;
      }
    }
  }
}

.copyright-text {
  font-family: "Lato";
  font-size: 16px;
  color: #1c2b48;
}

.ant-input {
  font-weight: normal !important;
}

.invite-modal .modal-column-1 .add-company-container .ant-select-selector:hover,
.ant-input:focus,
.ant-input:hover,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #6f7ced !important;
  box-shadow: none !important;
  border-width: 1px;
  outline: 0;

  .input-icon {
    color: #6f7ced;
  }
}

.ant-select-selection-placeholder,
input::placeholder {
  color: rgba(28, 43, 72, 0.5) !important;
  font-weight: 400 !important;
  font-size: 14px;
}

.colored-select .ant-select-selection-placeholder,
.colored-select input::placeholder {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 400 !important;
  font-size: 14px;
  opacity: 1;
}

textarea.ant-input {
  font-weight: 500;
  padding: 10px;
  height: 125px !important;

  &::placeholder {
    color: #96a7c1 !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6f7ced !important;
  border-color: #6f7ced !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6f7ced !important;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  border: 0.5px solid #6f7ced !important;
  border-radius: 6px !important;
}

.ant-checkbox-inner::after {
  left: 28.5% !important;
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden !important;
}

.PhoneInput {
  width: 100%;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 12px;
  padding: 4px 11px;
  padding-right: 20px;

  input {
    height: 40px;
    border: none;
    background: transparent !important;
    margin-left: 4px;
  }

  &.PhoneInput--disabled {
    background: rgb(245, 245, 245);

    input {
      color: rgba(112, 128, 139, 0.5) !important;
    }
  }
}

.react-tel-input {
  z-index: 99;

  &.required {
    z-index: 100;
  }
}

.ant-form-item-has-error .react-tel-input .form-control {
  border-color: #ff4d4f !important;
}

.ant-select-in-form-item {
  height: 40px;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.ant-select-item-option-state {
  display: flex;
  align-items: center;
}

.uploaded-file-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000;
  position: relative;
  padding: 12px;
  background: rgba(111, 124, 237, 0.1);
  border: 1px dashed #6f7ced;
  border-radius: 10px;
  margin-bottom: 20px;

  .left-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: calc(100% - 40px);

    .file-info {
      max-width: calc(100% - 50px);

      p,
      h3 {
        margin: 0;
        color: #1c2b48;
        font-family: "Lato";
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3 {
        font-weight: 500;
        font-size: 14px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        opacity: 0.5;
      }
    }

    .doc-thumb {
      height: 30px;
      min-width: 30px;
    }
  }
}

.notification-alert {
  border-radius: 15px !important;
  padding: 15px 20px !important;
  cursor: pointer !important;

  .noti-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .thumb {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    h4 {
      margin: 0;
      width: calc(100% - 40px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: "Lato" !important;
      font-weight: 600;
    }
  }

  .ant-notification-notice-description {
    font-family: "Lato" !important;
  }
}
.Toastify__toast-body {
  line-break: strict;
  word-break: break-word;
}

// .anticon svg {
//   width: 20px;
//   height: 20px;
// }

.ant-modal-content {
  padding: 0 !important;
}
