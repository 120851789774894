.admin-approval-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px 25px 30px 32px;

    .modal-body {
      .title {
        color: #1c2b48;
        font-family: "Lato";
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;

        &.sub {
          font-size: 18px;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .left-wrapper {
        border-right: 1px solid rgb(28, 43, 72, 0.2);
        padding-right: 25px;
        position: relative;

        .request-creator {
          margin: 20px 0;
          display: flex;
          align-items: flex-start;
          border-bottom: 1px solid rgb(28, 43, 72, 0.2);
          padding-bottom: 15px;

          .thumb {
            width: 56px;
            height: 56px;
            margin-right: 13px;
          }

          .info {
            max-width: calc(100% - 70px);

            p {
              color: #6f7ced;
              font-family: "Lato";
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.28px;
              margin-bottom: 3px;
            }

            h4 {
              color: #1c2b48;
              font-family: "Lato";
              font-size: 16px;
              font-weight: 700;
            }
          }
        }

        h2 {
          color: #1c2b48;
          font-family: "Lato";
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 12px;
        }

        .detail {
          padding: 16px 11px;
          border-radius: 10px;
          border: 1px solid #6f7ced;
          background: rgba(111, 124, 237, 0.1);
          display: flex;
          flex-direction: column;
          max-height: 400px;
          overflow-y: auto;

          .compareline {
            background: #6F7CED;
            margin-left: 10%;
            height: 100%;
            width: 1px;
          }

          .data-col {
            display: flex;
            align-items: flex-start;
            padding-bottom: 15px;

            &:last-child {
              padding-bottom: 0;
            }

            .thumb {
              border-radius: 6px;
              background: rgba(111, 124, 237, 0.2);
              width: 49px;
              height: 49px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              padding: 14px;

              * {
                color: #6f7ced;
                fill: #6f7ced;
              }

            }

            .info {
              min-height: 49px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: flex-start;

              h4 {
                color: #6f7ced;
                font-family: "Poppins";
                font-size: 13px;
                font-weight: 500;
                margin: 0;
              }

              p {
                color: #6f7ced;
                font-family: "Poppins";
                font-size: 11px;
                font-weight: 500;
                line-height: 16px;
                margin: 0;
              }

              .visibility {
                .thumb {
                  margin-right: -5px;
                }

                .thumb,
                .view-more {
                  width: 20px;
                  height: 20px;
                  border-radius: 50px;
                  padding: 0;

                }
              }

              .responsible-parties {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 5px;

                .responsible-party {
                  border-radius: 3.085px;
                  border: 0.308px solid #6F7CED;
                  background: rgba(111, 124, 237, 0.10);
                  padding: 3px 8px;
                  display: inline-flex;
                  align-items: center;

                  .name {
                    margin-left: 10px;
                    color: #1C2B48;
                    text-align: center;
                    font-family: "Lato";
                    font-size: 7.32px;
                    font-weight: 500;
                  }

                  .visibility {
                    margin: 0;

                    .thumb,
                    .view-more {
                      width: 17px;
                      height: 17px;
                    }
                  }
                }
              }

              .attachment-box {
                border-radius: 3.085px;
                border: 0.308px solid #6F7CED;
                background: rgba(111, 124, 237, 0.10);
                padding: 1px 8px;
                color: #6f7ced;
                font-family: "Poppins";
                font-size: 11px;
                font-weight: 500;
                min-width: 73px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-height: 22px;
                cursor: pointer;
              }

              .document-list {
                margin: 0;

                li {
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 11px;
                  color: #6f7ced;
                  font-family: "Poppins";
                  padding-left: 8px;

                  &::before {
                    content: "";
                    width: 4px;
                    height: 4px;
                    background: #6F7CED;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                  }
                }
              }

              .answer-wrapper .answer-box {
                margin-bottom: 4px;

                p {
                  font-size: 9px;
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            &.edited {
              .thumb {
                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }

            &.old * {
              color: #000 !important;
              fill: #000;
            }
          }

          &.deleted {
            border: 1px solid #F06B6B;
            background: rgba(231, 128, 128, 0.10);

          }
        }

        .responded {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: default;

          p {
            padding: 18px 16px 17px 17px;
            background: rgba(231, 128, 128, 0.1);
            border: 1px solid #f06b6b;
            border-radius: 10px;
            font-family: "Lato";
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #f06b6b;
            width: max-content;
            margin-bottom: 0;
          }
        }

        .action-btn-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          gap: 10px;
          margin-top: 15px;

          button {
            padding: 12px 32px;
            width: 160px;
            border-radius: 12px;
            font-size: 16px;

            &:hover {
              background-color: #5f6bd6;
            }

            transition: all 0.2s ease-in;
          }

          .reject {
            background-color: #e78080;

            &:disabled {
              background-color: rgba(231, 128, 128, 0.6) !important;
            }

            &:hover {
              background-color: #e27070;
            }
          }
        }
      }

      .right-wrapper {
        padding: 10px 0 0 25px;

        .admin-wrapper {
          margin-top: 5px;

          .admin-list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;
            gap: 15px;

            .left {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 16px;
              max-width: calc(100% - 95px);
              overflow: hidden;

              .thumb {
                height: 32px;
                min-width: 32px;
                max-width: 32px;
                border-radius: 50%;
              }

              .info {
                overflow: hidden;

                h3 {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }

                p {
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 12px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  opacity: 0.5;
                  margin: 0;
                }
              }
            }

            .status {
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              border-radius: 12px;

              // &.pending {
              //   background: rgba(214, 171, 22, 0.1);
              //   padding: 5px 24px 6px 23px;
              //   color: #d6ab16;
              // }

              // &.approved {
              //   background: rgba(63, 200, 170, 0.1);
              //   color: #569e92;
              //   padding: 5px 20px 6px 20px;
              // }
            }
          }
        }
      }
    }
  }
}