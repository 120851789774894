@use "../../../master.scss";

.top-heading {
  font-family: master.$font-lato;
  color: master.$default-text-color;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    margin-bottom: 0;
    margin-top: 34px;
    font-family: master.$font-lato;
  }

  h3 {
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    font-family: master.$font-lato;
    text-transform: capitalize;
    line-break: strict;
    word-break: break-word;
  }
}

.transaction-details {
  font-family: master.$font-lato;
  color: master.$default-text-color;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.all-transactions {
  font-family: master.$font-lato;
  color: master.$default-text-color;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }
}

.all-transactions-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.all-transactions-bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .salesPurchaseCard {
    background: #ffffff;
    border: 1px solid rgba(28, 43, 72, 0.1);
    min-width: 380px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      p {
        width: 200px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: auto;
      }

      svg {
        cursor: pointer;
      }
    }

    .bar {
      margin-top: 10px;
      margin-bottom: 15px;
      height: 6px;
      background: #3fc8aa;
      border-radius: 3px;

      &.pending {
        background: #d6ab16;
      }
    }

    p {
      margin-bottom: 5px;
    }
  }
}

.activityLogsMilestone {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  & > * {
    flex: 1;
  }

  @media (max-width: 1160px) {
    flex-direction: column;
  }
}

.activity-logs-container {
  margin-top: 20px;

  .infinite-scroll-component {
    min-height: 150px;
  }
}

.activity-log {
  &:not(:last-child) {
    margin: 10px 0;
  }

  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-right: 5px;
  position: relative;

  .user-thumb {
    width: 33px;
    height: 33px;
    border-radius: 50px;
    overflow: hidden;
  }

  .activity-detail {
    width: calc(100% - 50px);
    .member {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  p {
    margin-bottom: 0px;
    font-family: master.$font-lato;
  }

  .detail {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    color: #1c2b48;
  }

  .milestone-title {
    font-weight: 700;
    font-size: 16px;
  }

  .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgb(28, 43, 72, 0.8);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.milestone-log {
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgb(28, 43, 72, 0.1);
  }
}

.typeDatePera {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgb(28, 43, 72, 0.5);
  margin-bottom: 5px !important;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    font-weight: 600;
  }
}

.doc-pagination {
  text-align: right;
  margin-bottom: 10px;

  button {
    font-size: 30px;
    background: transparent;
    border: none;
    color: #1c2b48;
    margin-left: 20px;
    cursor: pointer;
    height: 20px;

    &:hover {
      color: #6f7ced;
    }
  }
}

.milestone-tile-wrap {
  display: flex;
  align-items: center;
  width: 170px;

  svg {
    width: 31px;
    height: 31px;
    margin-right: 15px;
  }

  .detail {
    width: calc(100% - 46px);
    margin-bottom: 0;

    .milestone-title {
      margin-bottom: 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.dashboard-data-col {
  .card {
    min-height: 100%;
  }
}
