.permission-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 25px 28px 36px;

    .title {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
        margin-bottom: 5px !important;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
      }
    }

    .close-btn {
      position: absolute;
      right: 30px;
      top: 30px;
      background: transparent !important;
      width: 23px;
      height: 23px;
      cursor: pointer;
    }
    .search-box {
      margin: 0 11px 15px 0;
      border-radius: 10px;
      border: 1px solid rgba(28, 43, 72, 0.1);
      background: #f8fafb;
      input {
        &::placeholder {
          color: rgba(28, 43, 72, 0.8) !important;
          font-family: Lato;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .room-users-wrapper {
      max-height: 360px;
      overflow-y: auto;
      padding-right: 11px;

      /* width */
      *::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      *::-webkit-scrollbar-track {
        //   box-shadow: inset 0 0 3px grey;
        border-radius: 10px;
      }

      /* Handle */
      *::-webkit-scrollbar-thumb {
        background: rgb(169, 169, 169);
        border-radius: 10px;
      }

      /* Handle on hover */
      *::-webkit-scrollbar-thumb:hover {
        background: rgb(144, 144, 144);
      }

      .infinite-scroll-component {
        min-height: 260px;
      }
      .permission-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 9px 21px 7px 22px;

        &.odd {
          background: rgba(112, 126, 174, 0.05);
        }
        .user-info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 6px;
          max-width: calc(100% - 140px);
          overflow: hidden;
          .thumb {
            height: 32px;
            width: 32px;
          }
          .info {
            overflow: hidden;
            max-width: calc(100% - 50px);

            p,
            h3 {
              margin: 0;
              color: #1c2b48;
              font-family: "Lato" !important;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            h3 {
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
            }
            p {
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              opacity: 0.5;
            }
          }
        }
        .dropdown-select {
          min-width: 150px !important;

          * {
            transition: all 0.2s ease-out;
          }
          &.ant-select-open {
            .ant-select-arrow {
              transform: rotate(180deg);
              path {
                fill: #6f7ced;
              }
            }
          }
          .ant-select-selection-item {
            color: rgba(28, 43, 72, 0.5) !important;
            font-family: "Lato";
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
          }

          .ant-select-selector {
            background: transparent !important;
            border: none !important;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .ant-select-selection-item {
              text-align: right;
            }
          }
          .permission-dropdown {
            min-width: 150px !important;
            &.ant-select-dropdown-placement-bottomRight {
              top: 40px !important;
            }
            .ant-select-item-option-active:not(
                .ant-select-item-option-disabled
              ) {
              background: transparent !important;
            }
            .ant-select-item-option-selected:not(
                .ant-select-item-option-disabled
              ) {
              background: transparent !important;
              .ant-select-item-option-content {
                font-weight: 600;
                font-size: 12px;
              }
            }
            .ant-select-item {
              border-bottom: 1px solid rgba(28, 43, 72, 0.1);
              margin: 0 14px 0 10px;
              padding: 10px 10px;
              .ant-select-item-option-content {
                color: rgba(28, 43, 72, 0.8) !important;
                font-family: Lato !important;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
