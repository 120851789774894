.detail-wrapper {
  padding: 7px 15px;
  position: relative;

  .top-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .thumb {
      min-width: 51px;
      height: 51px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: rgb(111, 124, 237, 0.1);
      border-radius: 6px;
      margin-right: 20px;
    }

    h3 {
      margin: 0;
      font-weight: 700;
      line-break: strict;
      word-break: break-word;

      width: 80%;
      align-self: center;
    }

    .action-btns {
      position: absolute;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        margin-left: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        &.del-icon {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .note-box {
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-left: none;
    border-right: none;
    margin-top: 20px;
    padding: 30px 0;
    margin-bottom: 15px;

    h3 {
      font-family: "Lato";
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #1c2b48;
    }

    p {
      font-family: "Lato";
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1c2b48;
      opacity: 0.5;
      white-space: pre-line;
    }
  }

  .exhibits-version-wrapper {
    // .infinite-scroll-component {
    //   min-height: 180px;
    // }

    .options-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        gap: 17px;

        h3 {
          font-family: "Lato";
          font-weight: 700;
          font-size: 18px;
          color: #1c2b48;
        }

        .options-tabs {
          border-radius: 6px;
          height: 35px;
          width: 272px;

          .trans {
            height: 35px;
            border-radius: 6px;

            p {
              font-family: "Lato";
              font-weight: 500;
              font-size: 16px;
            }
          }
        }
      }

      .add-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 54px;
        border-radius: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
        color: #fcfbfe;
      }
    }

    .doc-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(111, 124, 237, 0.1);
      border-radius: 6px;
      padding: 10px;
      height: 43px;
      width: 43px;
    }

    .uploaded-text {
      font-size: 8px !important;
      opacity: 1 !important;

      .thumb {
        height: 9px;
        width: 9px;
        border-radius: 50%;
        margin: 0 2px;
      }
      .username {
        max-width: calc(100% - 125px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .versions-wrapper {
      border: 1px solid rgba(28, 43, 72, 0.1);
      border-radius: 10px;
      padding: 20px;

      h3,
      p {
        font-family: "Lato";
        color: #1c2b48;
        margin: 0;
      }

      .uploader-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(28, 43, 72, 0.1);
        gap: 15px;
        padding-bottom: 12px;
        margin-bottom: 17px;

        .thumb {
          height: 39px;
          min-width: 39px;
          max-width: 39px;
        }

        .uploaded-info {
          overflow: hidden;
          h3 {
            font-weight: 600;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .version-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 15px;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;

        .version-card-left {
          display: flex;
          align-items: center;
          gap: 12px;
          width: calc(100% - 40px);

          .version-info {
            width: calc(100% - 55px);

            h3 {
              font-weight: 700;
              font-size: 14px;
              line-height: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
              margin-top: 4px;
            }

            p,
            .uploaded-text {
              font-size: 14px;
              opacity: 0.5;
              display: flex;
              align-items: center;

              .thumb {
                width: 9px;
                height: 9px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .exhibits-wrapper {
      .exhibit-card-wrapper {
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        padding: 17px 0;
        user-select: none;
        padding-bottom: 0;

        h3,
        p {
          color: #1c2b48;
          font-family: "Lato";
          margin: 0;
        }

        .uploader-info {
          padding: 0 20px 17px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;

          .thumb {
            height: 39px;
            min-width: 39px;
            max-width: 39px;
          }
          .name {
            overflow: hidden;
            h3 {
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            p {
              font-weight: 500;
              font-size: 12px;
              opacity: 0.5;
            }
          }
        }

        .attachment-wrapper {
          padding: 10px 20px 17px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .attachment-info-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            // width: calc(100% - 40px);

            .attachment-left-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 15px;
              width: calc(100% - 40px);

              .doc-detail {
                width: calc(100% - 55px);

                h3 {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 100%;
                  margin-top: 4px;
                }

                p {
                  font-weight: 400;
                  font-size: 14px;
                  opacity: 0.5;
                  display: flex;
                  align-items: center;

                  .thumb {
                    margin: 0 2px;
                  }
                }
              }
            }
          }

          .child-option-wrapper {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 2px;
            margin: 10px 0;
            border-bottom: 1px solid rgba(28, 43, 72, 0.1);

            .option {
              padding: 6px 18px 7px 11px;
              width: 50%;

              p {
                font-weight: 500;
                font-size: 14px;
                color: #1c2b48;
              }
            }
          }
        }

        .child-attach {
          padding: 0 0 17px;
          &:last-of-type {
            padding: 0;
          }
        }

        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #f1f2fd;
          padding: 6px 29px;

          svg {
            height: 6px;
            width: 11px;
          }

          p {
            font-weight: 700;
            font-size: 14px;
            color: #1c2b48;
          }
        }

        .option-disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        .active-option {
          background: #6f7ced;

          p {
            color: #fff !important;
          }

          svg {
            transform: rotate(180deg);

            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
