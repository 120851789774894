.upload-document-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 36px;

    .modal-body {
      .ant-input {
        margin-top: 5px;
        width: 100%;
        background: #f8fafb !important;
        height: 48px !important;
        border: 1px solid rgba(28, 43, 72, 0.5) !important;
        border-radius: 10px !important;

        &:hover,
        &:focus {
          border-color: #6F7CED !important;
        }
      }

      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .ant-form-item-has-error .upload-doc-box {
        border-color: #ff4d4f;
      }

      .upload-doc-box {
        position: relative;
        background: #F8FAFB;
        border: 1px dashed rgba(28, 43, 72, 0.5);
        border-radius: 10px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        .drop_area {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 99;
        }

        .content {
          text-align: center;
          width: 120px;

          svg {
            width: 30px;
            height: 30px;
          }

          p {
            margin: 0;
            margin-top: 5px;
            color: #1C2B48;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            opacity: 0.5;
          }
        }

        &.active {
          border-color: #6F7CED;

          .content {
            width: 100%;

            svg path {
              fill: #6f7ced;
              opacity: 1;
            }

            p {
              color: #6F7CED;
              opacity: 1;
            }
          }
        }

      }

      .close-btn {
        position: absolute;
        right: 28px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .create-button button {
        border-radius: 12px;
        font-family: "Lato", sans-serif !important;
        font-weight: 500;
        font-size: 16px;
        padding: 12px 60px
      }
    }
  }
}