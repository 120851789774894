.file-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 21px;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 12px;
  gap: 15px;
  user-select: none;
  h3,
  p {
    color: #1c2b48;
    margin: 0;
    white-space: nowrap;
  }
  .card-left {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    .file-info {
      display: flex;
      width: max-content;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: 14px !important;
        font-weight: 700;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        font-size: 14px !important;
        opacity: 0.5;
        width: max-content;
      }
    }
    .doc-icon-wrapper {
      padding: 10px 12px 8px 12px;
      background: rgba(111, 124, 237, 0.1);
      border-radius: 6px;
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        min-width: 24px;
        min-height: 24px;
      }
    }
  }
  .doc-download {
    min-width: 26px;
    margin-left: 20px;
  }
}
