@use "../../../master.scss";

.enterprise-setting {
  padding: 0 20px;

  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    p,
    h3 {
      margin: 0;
    }
  }

  .heading-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .teamMemberRequestView-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
    }

    h4 {
      margin: 0;
      font-family: "Lato";
      font-weight: 700;
      font-size: 24px;
      color: #1c2b48;
    }
  }

  .table-title {
    font-family: master.$font-lato;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: master.$default-text-color;

    .sort-icon {
      width: 13px;
      height: 13px;
      margin-left: 3px;
    }

    &.active {
      .sort-icon {
        color: #6f7ced;
      }
    }
  }

  .table-container {
    background: #ffffff;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    display: grid;
    overflow: auto;

    li {
      list-style: none;
      width: 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;

      @media (max-width: 1024px) {
        width: 120px;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      gap: 5px;

      li:last-child {
        width: 50px;
      }


      P {
        @extend .table-title;
        margin-bottom: 0px;
      }

      &>li>div>div {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &>li>div p:last-child {
        font-size: 10px;
      }

      .count {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #6f7ced;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgb(28, 43, 72, 0.2);
  }

  .back-gray {
    background: #f2f5fa;
  }
}