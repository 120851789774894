.enabled {
  display: flex;
  align-items: center;
  &.disabled-wrapper {
    opacity: 0.5;
    * {
      cursor: not-allowed !important;
    }
  }
  * {
    display: block;
  }
}
