.add-responsible-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        margin-bottom: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .selected-partymembers {
        display: flex;
        overflow-x: auto;
        position: relative;
        padding-bottom: 10px;

        .member-box {
          margin-top: 10px;
          padding: 7px 10px;
          position: relative;
          background: #f2f5fa;
          border-radius: 10px;
          min-width: 190px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 10px;

          .thumb {
            width: 32px;
            height: 32px;
            border-radius: 50px;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 5px;
          }

          .detail {
            overflow: hidden;
            p,
            h4 {
              max-width: 120px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #1c2b48;
            }
            h4 {
              margin-bottom: 2px;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.02em;
            }

            p {
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              letter-spacing: -0.02em;
              margin: 0;
              opacity: 0.5;
            }
          }

          .close-btn {
            right: -6px;
            top: -6px;
            width: 18px;
            height: 18px;
            font-size: 10px;
            background: #2b3674;
            border: 1px solid #ffffff;
            color: #fff;
          }
        }
      }
      .selected-partymembers::-webkit-scrollbar {
        display: none;
      }
      .tab-wrapper {
        background: rgba(28, 43, 72, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 25px;

        li {
          display: block;
          width: 50%;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.02em;
          padding: 14px;
          text-align: center;
          cursor: pointer;

          &.active {
            background: #6f7ced;
            color: #fff;
            cursor: auto;
          }

          color: #2b3674;
        }
      }

      .ant-select-selector,
      .ant-picker,
      .ant-input {
        width: 100%;
        background: #f8fafb !important;
        height: 44px !important;
        border: 1px solid rgba(28, 43, 72, 0.1) !important;
        border-radius: 10px !important;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }

    .modal-footer {
      margin-top: 25px;
      text-align: right;

      button {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 170px;
        color: #fff;

        &:hover {
          background: #535ec2;
        }
      }
    }
  }
}
