.status-toggle-wrapper {
  cursor: pointer !important;
  .ant-switch {
    min-width: 85px !important;
    background: rgb(214, 171, 22, 0.1) !important;
    display: flex !important;
    align-items: center !important;
    padding: 3px 0 3px 3px !important;
    .ant-switch-inner {
      color: #d6ab16 !important;
      font-size: 10px !important;
      font-family: "Lato" !important;
      font-weight: 700;
    }
    .ant-switch-handle::before {
      background: #d6ab16 !important;
    }
  }
  .ant-switch-checked {
    background: rgb(63 200 170 / 10%) !important;
    .ant-switch-inner {
      color: #3fc8aa !important;
      font-size: 10px !important;
      font-family: "Lato" !important;
      font-weight: 700;
    }
    .ant-switch-handle::before {
      background: #3fc8aa !important;
    }
  }
  .ant-switch:focus {
    box-shadow: none !important;
  }
  .ant-switch-disabled,
  * {
    cursor: pointer !important;
    opacity: 1;
  }
}
