.version-visibility-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 27px 0 31px 0;

    .modal-body {
      .title {
        margin-left: 37px;
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }
      .doc-visiblity-wrapper {
        .doc-visiblity-item {
          padding: 6px 32px 9px 36px;
        }
      }

      .custom-scroll-wrapper {
        margin-right: 3px;
        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          //   box-shadow: inset 0 0 3px grey;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgb(169, 169, 169);
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgb(144, 144, 144);
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }
  }
}
