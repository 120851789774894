.upload-box {
  position: relative;
  border: 1px solid #6f7ced;
  border-radius: 10px;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #6f7ced;
  cursor: pointer;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .label {
    display: flex;
    font-family: "Poppins";
  }

  &:hover {
    background: #6f7ced;
    color: #fff;
  }
}
.isIcon-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  input {
    opacity: 0;
    z-index: 99999999999;
  }
  img {
    cursor: pointer;
    position: absolute;
  }
}
