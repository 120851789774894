.upload-transaction-document-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px 30px 40px 30px;

    .modal-body {
      .title {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato";
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
          font-family: "Lato" !important;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .ant-input {
        &.textarea {
          height: 130px !important;
          resize: none;
        }
        &::placeholder {
          color: rgba(28, 43, 72, 0.5) !important;
          font-family: "Lato" !important;
          font-weight: 500 !important;
          font-size: 14px !important;
        }
      }

      .upload-tabs {
        margin-bottom: 25px;

        .trans {
          width: 50%;
          height: 52px;

          p {
            font-weight: 500;
            font-size: 16px;
          }
          h6 {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .upload-trans-doc-form {
        .check-fields {
          margin-top: 15px;

          .ant-input.textarea {
            height: 72px !important;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
      margin-top: 0;

      button {
        background: #6f7ced;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        border-radius: 12px;
        padding: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;

        color: #fcfbfe;
      }
    }
  }
}

.exhibit-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 15px 10px 10px !important;

  h3,
  p {
    margin: 0;
    font-family: "Lato";
    color: #1c2b48;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 310px;
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  svg {
    height: 25px;
    width: 25px;
  }

  .exhibit-info {
    p {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      opacity: 0.5;
    }
  }
}
