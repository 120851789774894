.checklist-detail {
  .card {
    padding: 29px 27px;
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    p,
    h3 {
      margin: 0;
      font-family: "Lato";
      color: #1c2b48;
    }

    .checklist-detail-info-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.1);
      .detail-info {
        h3 {
          font-weight: 700;
          font-size: 16px;
        }
        .sub-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          p {
            font-weight: 400;
            font-size: 14px;
            opacity: 0.5;
          }
        }
      }
      .checklist-options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 11px;
        svg {
          height: 24px;
          width: 24px;
          &.delete {
            height: 26px;
            width: 26px;
          }
        }
      }
    }

    .visibility-section {
      padding-bottom: 17px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-weight: 700;
        font-size: 18px;
      }
      .visibility-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .visible-people-list {
          margin-right: 21px;
          .thumb,
          .view-more {
            width: 31px;
            height: 31px;
          }
        }
      }
    }

    .checklist-notes-wrapper {
      padding-bottom: 17px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.1);

      .notes-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 17px;
        h3 {
          font-weight: 700;
          font-size: 18px;
        }
      }
      .content {
        font-weight: 400;
        font-size: 16px;
        opacity: 0.5;
        max-height: 150px;
        overflow-y: auto;
        white-space: pre-line;
      }
    }

    .checklist-responsibility-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 12px;
      border-bottom: 1px solid rgba(28, 43, 72, 0.1);
      padding-bottom: 14px;
      .responsibility-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
          font-weight: 700;
          font-size: 18px;
        }
      }
      .parties-wrapper {
        max-height: 145px;
        overflow-y: auto;
        .party-card-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          padding: 10px 20px 9px 15px;
          background: rgba(111, 124, 237, 0.1);
          border: 1px solid #6f7ced;
          border-radius: 10px;
          svg {
            &.delete {
              height: 22px;
              min-width: 22px;
            }
          }
          .card-left-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // gap: 12px;
            width: 80%;
            // .visible-people-list {
            //   &.large {
            //     margin: 0;
            //   }
            // }

            .company-info {
              // max-width: calc(100% - 60px);
              h3,
              p {
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              h3 {
                font-weight: 500;
                font-size: 14px;
              }
              p {
                font-weight: 400;
                font-size: 12px;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    .checklist-doc-wrapper {
      .doc-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        h3 {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 15px;
        }
        .doc-options {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          gap: 12px;
          .add-doc-btn {
            padding: 11px 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #6f7ced;
            border-radius: 12px;
            color: #6f7ced;
            font-family: "Lato";
            font-weight: 500;
            font-size: 16px;
            transition: all 0.3s ease-in-out;
            &:hover {
              color: #fff;
              background: #6f7ced;
            }
          }
          .view-icons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 11px;
            .view-active {
              g {
                opacity: 1;
              }
              path {
                fill: #6f7ced;
              }
            }
          }
        }
      }
    }
  }

  /* width */
  .card ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .card ::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  .card ::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  .card ::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .unlink {
    padding: 7px;
    border-radius: 6px;
    background: rgba(240, 107, 107, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 16px;
      width: 16px;

      path {
        fill: #f06b6b;
      }
    }
  }
}
