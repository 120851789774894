@use "../../master.scss";

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  .title {
    h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 34px;
      margin: 0;
      color: master.$default-text-color;
      font-family: master.$font-lato;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin: 0;
      color: #6f7ced;
      cursor: pointer;
      display: inline-block;
      user-select: none;
    }
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .filteration {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      button {
        color: #6f7ced;
        cursor: pointer;
        background: transparent;
        border: none;
        font-weight: 600;
        font-size: 20px;
        margin-right: 15px;
      }

      .search-dropdown {
        @extend .drop-down-box;
        position: absolute;
        right: 0;
        width: 350px;
        margin-top: 10px;
      }

      .filter-tab {
        border-radius: 12px;
        background: rgba(28, 43, 72, 0.1);
        margin: 0;
        display: flex;

        li {
          border-radius: 12px;
          cursor: pointer;
          display: inline-block;
          padding: 12px;
          width: 120px;
          font-size: 12px;
          text-align: center;

          &.active {
            color: #fff;
            background: #6f7ced;
          }
        }
      }
    }
  }
}

.search-box {
  background: #ffffff;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;

  input {
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    color: master.$default-text-color;
    width: 100%;

    &::placeholder {
      font-family: master.$font-lato;
      opacity: 0.5;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  svg {
    cursor: pointer;
    color: #6f7ced;
    font-size: 18px;
    margin-right: 10px;
  }
}
