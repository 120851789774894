.milestone-datatable {
    border: 1px solid rgba(28, 43, 72, 0.1);
    border-radius: 10px;
    background: #fff;
    padding-bottom: 10px;

    .table-head {
        border-bottom: 1px solid rgba(28, 43, 72, 0.2);
        display: flex;
        align-items: center;

        .head-ttl {
            display: block;
            padding: 12px 24px;
            font-weight: 600 !important;
            font-size: 14px !important;
            color: #1c2b48;

            svg {
                margin-left: 10px;
            }

            &:first-child {
                width: calc(50% - 3px);
            }

            &:nth-child(2) {
                width: 28%;
            }

            &.active {
                .sort-icon {
                    color: #6f7ced;
                }
            }
        }
    }

    .table-body {
        .body-row {
            margin: 0 24px;
            border-bottom: 1px solid rgba(28, 43, 72, 0.2);
            display: flex;
            flex-wrap: wrap;

            .body-col {
                padding: 16px 0;
                display: flex;
                align-items: center;

                .thumb {
                    width: 31px;
                    height: 31px;
                    margin-right: 12px;
                }

                .detail {
                    width: calc(100% - 45px);
                    padding-right: 20px;
                    display: block;

                    h4 {
                        color: #1C2B48;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17px;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    p {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #1C2B48;
                        opacity: 0.8;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .date {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.02em;
                    color: #1C2B48;
                    opacity: 0.5;
                }

                .action {
                    margin-left: 14px;

                    .three-dot-icon {
                        display: flex;
                    }
                }

                &:first-child {
                    width: calc(50% + 23px);
                }

                &:nth-child(2) {
                    width: 30%;
                }

                &:last-child {
                    width: calc(20% - 33px);
                }
            }

            &:first-child {
                padding-top: 20px;
            }

            &:last-child {
                border-color: transparent;
            }
        }
    }
}