.dashboard-invite-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .ant-modal-body {
    position: relative;
    padding: 28px 30px 10px 30px;

    .modal-body {
      .title-wrapper {
        margin-bottom: 20px;
        text-align: center;

        h3 {
          font-family: "Lato" !important;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 3px;
        }

        p {
          font-family: "Lato" !important;
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .invitation-detail {
        border: 1px solid #cccdd6;
        border-radius: 10px;
        padding: 20px;
      }

      .invitedby-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 16px;
        border-bottom: 1px solid #cccdd6;

        .user-dtl {
          display: flex;
          max-width: calc(100% - 85px);
          overflow: hidden;
          // max-width: calc(100% - 45px);
          .thumb {
            min-width: 31px;
            max-width: 31px;
            height: 31px;
            margin-right: 10px;

            img {
              width: 100%;
              border-radius: 50%;
              height: 100%;
              object-fit: cover;
            }
          }

          .dtl {
            overflow: hidden;

            p {
              font-family: "Poppins";
              margin: 0;
              font-weight: 500;
              font-size: 11px;
              line-height: 18px;
              color: #6f7ced;
            }

            h4 {
              margin: 0;
              font-family: "Poppins";
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #000000;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .user-company {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1c2b48;
          display: flex;
          align-items: center;
          span {
            font-family: "Lato";
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            margin-left: 5px;
          }
        }
      }

      .transaction-dtl {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &.tr-dtl {
            border-left: 1px solid #cccdd6;
            justify-content: flex-end;
            h3 {
              margin-right: 15px;
            }
          }

          svg {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }

          h3 {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            max-width: calc(100% - 50px);
            color: #000000;
            margin: 0;

            span {
              font-family: "Poppins";
              color: #6f7ced;
              font-weight: 500;
              font-size: 11px;
              display: block;
            }
          }
        }
      }
    }

    .modal-footer {
      margin: 25px 0;
      display: flex;
      justify-content: space-between;
      gap: 8px;

      button {
        width: 322px;
        height: 45px;
        background: #6f7ced;
        border: 1px solid #6f7ced;
        border-radius: 12px;
        color: #fff;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: 0;

        &:hover {
          border-color: #535ec2;
          background: #535ec2;
        }

        &.reject-btn {
          color: #6f7ced;
          background: transparent;
          width: 128px;
        }
      }
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .select-form-item {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .ant-select-arrow {
    color: #1c2b48;
  }
}
