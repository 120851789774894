.milestones {
  padding-bottom: 20px;

  * {
    font-family: "Lato", sans-serif !important;
  }

  margin: 30px 35px;


  .room-detail {
    justify-content: space-between !important;

    .left-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 192px);
      padding-right: 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        height: 80%;
        background: rgba(28, 43, 72, 0.1);
        width: 1px;

        @media (max-width: 1194px) {
          display: none;
        }
      }

      .description p {
        opacity: 0.5;
        margin-top: 3px;
      }

      .search-box {
        background: #ffffff;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        padding: 12px 15px;
        width: 380px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          color: #6f7ced;
          font-size: 20px;
          margin-right: 10px;
        }

        input {
          background: transparent;
          border: none;
          font-weight: 500;
          font-size: 14px;
          color: #1c2b48;
          opacity: 0.5;
          width: 300px;
          padding: 0 4px;
          border-right: 1px solid rgba(28, 43, 72, 0.1);
          margin-right: 15px;

          @media (max-width: 1194px) {
            width: calc(100% - 60px);
          }
        }

        .filter-btn {
          margin: 0;
          position: relative;
          cursor: pointer;
        }

        @media (max-width: 1194px) {
          width: calc(100% + 212px);
          margin-top: 30px;
        }
      }

      @media (max-width: 1194px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .right-wrapper {
      width: 192px;
      padding-left: 20px;
      display: flex;
      justify-content: flex-start;

      button {
        cursor: pointer;
        width: 100%;
        border: none;
        background: #6f7ced;
        border-radius: 12px;
        padding: 12px;
        font-weight: 500;
        font-size: 16px;
        color: #fcfbfe;
      }
    }

    @media (max-width: 1194px) {
      align-items: flex-start !important;
    }
  }

  @media (max-width: 1194px) {
    .custom-scroll-wrapper {
      min-height: 400px !important;
      max-height: 400px !important;
    }
  }
}

.milestone-status-threeDotIcon {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding-right: 10px;
}