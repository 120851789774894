.skip-modal {
  border-radius: 10px;
  overflow: hidden;

  position: relative;
  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #1c2b48;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50px;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    gap: 5px;
    h3 {
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
      color: #1c2b48;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #1c2b48;
    }
    .skip-btn {
      padding: 12px 32px;
      border-radius: 12px;
      font-size: 16px;
    }
  }
}
