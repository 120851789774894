.team-list {
  // margin-top: 30px;
  padding-right: 15px;
  overflow-y: scroll;
  height: 360px;

  .team-detail {
    margin-bottom: 25px;

    .company-detail {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 20px;
      cursor: pointer;

      .thumb {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }

      .detail {
        width: calc(100% - 44px);

        h4 {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          margin: 0;
          color: #1c2b48;
          font-family: "Lato", sans-serif;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        p {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          margin: 0;
          color: #2b2b2b;
          opacity: 0.8;
          font-family: "Lato", sans-serif;
        }
      }

      &.not-exist {

        p,
        h4,
        .thumb {
          opacity: 0.5;
        }
      }

      .dropdown-caret {
        position: absolute;
        right: 0;
      }
    }

    .member-detail {
      margin-left: 16px;

      .not-member {

        p,
        h4,
        .user-thumb {
          opacity: 0.5;

          .thumb {
            cursor: default !important;
          }
        }
      }

      .member-li {
        position: relative;
        border-left: 1px solid #8d95a3;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 0px 10px 4px;

        .user-thumb {
          margin-right: 12px;
          position: relative;
          z-index: 4;
          width: 32px;
          height: 32px;
          border-radius: 50px;

          .thumb {
            width: 32px;
            height: 32px;
          }

          .admin-crown {
            position: absolute;
            top: 0;
            right: 0;
            height: 10px;
            width: 10px;
          }
        }

        .detail {
          width: calc(100% - 60px);

          h4 {
            cursor: default;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
            color: #1c2b48;
            font-family: "Lato", sans-serif;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          p {
            cursor: default;
            margin: 0;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: rgb(28, 43, 72, 0.5);
            font-family: "Lato", sans-serif;
          }
        }

        &::before {
          content: "";
          position: absolute;
          background: #8d95a3;
          height: 1px;
          width: 5px;
          left: 0;
        }

        &:last-child {
          &::after {
            content: "";
            position: absolute;
            background: #fff;
            height: calc(50% - 1px);
            width: 4px;
            left: -2px;
            bottom: 0;
          }
        }

        svg {
          flex: 1;
          cursor: pointer;
        }

        &.removed-member {
          background: rgba(255, 10, 10, 0.2);
          width: calc(100% + 35px);

          .detail {
            width: calc(100% - 95px);
          }
        }
      }

      .response-dot {
        background: #f06b6b;
        border: 0.612903px solid #ffffff;
        min-width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 0;
      }

      .working-groups-ellipses {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        position: relative;
        padding-left: 10px;
        height: 30px;

        .dots {
          width: 18px;
          display: block;
          position: absolute;
          font-size: 16px;
          font-weight: 900;
        }

        .working-groups-options {
          padding-bottom: 5px;
          display: flex;

          p {
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: rgba(28, 43, 72, 0.8);
            cursor: pointer;
            margin: 0;
          }
        }
      }
    }
  }

  .ant-dropdown {
    z-index: 6 !important;
  }
}