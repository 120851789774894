.members-listing-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  padding-right: 5px;
  min-height: 150px;

  .current-members-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    position: relative;

    .member-info {
      display: flex;
      align-items: center;
      gap: 15px;
      line-break: strict;
      word-break: break-word;

      .member-profile-img-wrapper {
        position: relative;
        .admin-crown {
          position: absolute;
          height: 12px;
          width: 12px;
          top: 0;
          right: 0;
          z-index: 1;
        }
      }
    }

    .thumb {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    h3 {
      font-family: "Lato" !important;
      font-weight: 600;
      font-size: 14px;
      color: #1c2b48;
      margin-bottom: 1px;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      font-family: "Lato" !important;
      font-weight: 400;
      font-size: 10px;
      color: #1c2b48;
      margin: 0;
      max-width: 130px;

      // opacity: 0.5;
    }

    .email {
      opacity: 0.5;
    }

    .remove-btn {
      position: absolute;
      right: 0;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}

/* width */
.members-listing-wrapper::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.members-listing-wrapper::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
.members-listing-wrapper::-webkit-scrollbar-thumb {
  background: rgb(169, 169, 169);
  border-radius: 10px;
}

/* Handle on hover */
.members-listing-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgb(144, 144, 144);
}
