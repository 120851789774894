@use "../../../../master.scss";

.create-transaction {
  width: 175px;
  height: 66px;
  background: rgb(111 124 237 / 10%);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 18px;

  p {
    margin-bottom: 0px;
    font-family: master.$font-lato;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #25396f;
  }

  svg {
    cursor: pointer;
  }
}

.nav-item-container {
  gap: 20px;

  .sidebar-item {
    width: 100%;
    height: 45px;
    border-radius: 18px;
    color: master.$default-text-color;
    cursor: pointer;
    padding-left: 20px;
    gap: 10px;
    justify-content: flex-start !important;

    .sidenav-icon {
      height: 22px;
      width: 22px;
    }
    p {
      margin: 0;
      font-family: master.$font-lato;
      font-size: 14px;
      width: calc(100% - 25px);
      line-height: initial;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .title-wrapp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      width: 100%;
      width: calc(100% - 45px);

      p {
        margin: 0;
        font-family: master.$font-lato;
        font-size: 14px;
        width: max-content;
        line-height: initial;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .unread-dot {
        min-height: 8px;
        min-width: 8px;
        border-radius: 50%;
        background: #f06b6b;
        margin-top: 2px;
      }
    }

    .thumb {
      // img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      // }
    }

    .sidenav-company-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      width: calc(100% - 40px);

      .admin-label {
        font-size: 9px;
        padding: 3px 6px;
        margin: 0;
        background-color: rgba(255, 154, 0, 0.1);
        width: max-content;
        font-family: "Lato";
        color: #ee8d00;
        border-radius: 5px;
        line-height: 1;
      }
    }
  }

  .active-link {
    background: #6f7ced;
    color: white;

    * {
      background: #6f7ced;
      color: #fff !important;
    }

    .see-all {
      // padding-right: 5px;
      background: #626fe0 !important;
    }

    .sidenav-company-wrapper {
      .admin-label {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .toggle-trans-drop {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .trans-drop-item {
    font-weight: 400;
    font-size: 12px;
    color: rgba(28, 43, 72, 0.5);
    cursor: pointer;
    white-space: nowrap;
    padding: 10px 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // background: #6f7ced;
    padding: 20px 30px;
    width: 100%;
    display: block;
    text-align: left;
  }

  .transaction-wrapper {
    width: 100%;
    border-radius: 18px;
    padding-right: 5px;
  }

  .trans-drop-wrapper {
    display: flex;
    flex-direction: column;
    // gap: 5px;
    align-items: flex-start;
    // padding-inline: 30px;
    border-radius: 18px;
    // padding-top: 20px;
    // padding-bottom: 40px;
    position: relative;
    overflow: hidden;
  }

  .see-all {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px 0px;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    gap: 10px;
    color: rgba(28, 43, 72, 0.8);
    justify-content: center;
  }

  .message-nav-box {
    width: 100%;
    border-radius: 18px;
    padding-right: 5px;
    overflow: hidden;

    .dropdown-btn {
      cursor: pointer;
      background: transparent;
      border: none;
      width: 20px;
      height: 20px;
    }

    .message-dropdown {
      .message-dropdown-option {
        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // padding-right: 5px;
          gap: 15px;
          .carret {
            // padding-left: 15px;
          }
        }
        .chat-list-wrapper {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          border-left: 1px solid rgba(28, 43, 72, 0.5);
          margin-left: 40px;
          gap: 8px;
          margin-bottom: 15px;
          .chat-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 9px;
            padding-left: 8px;
            .thumb {
              height: 16px;
              width: 16px;
              // background: #6f7ced !important;
              color: #fff;
            }
            p {
              color: rgba(28, 43, 72, 0.5);
              font-family: "Lato";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              margin: 0;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .chat-trans-item {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 9px;
            padding-left: 8px;
            width: 100%;
            flex-direction: column;
            .option {
              width: 100%;
              a {
                padding: 0 30px 0 0;
              }
            }
            .chat-list-wrapper {
              margin-left: 3px;
              margin-bottom: 0;
            }
            p {
              color: rgba(28, 43, 72, 0.5);
              font-family: "Lato";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              margin: 0;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &.is-active {
            border-color: #fff;
            .chat-item {
              .thumb {
                height: 16px;
                width: 16px;
                color: #6f7ced !important;
                // background: #fff !important;
              }
              p {
                color: #fff;
              }
            }
          }
        }
      }
      li {
        display: block;

        a {
          font-size: 12px;
          font-weight: 400;
          color: rgba(28, 43, 72, 0.5);
          padding: 8px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 20px 30px;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
