.doc-no-access-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        margin-bottom: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .doc-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 36px 20px;
        border: 1px solid rgba(28, 43, 72, 0.1);
        border-radius: 10px;
        background: rgba(198, 235, 252, 0.2);
        margin-bottom: 14px;
        .doc-thumb {
          padding: 10px;
          background: rgba(111, 124, 237, 0.1);
          height: 43px;
          width: 43px;
          border-radius: 6px;
        }
        .doc-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          h3 {
            margin: 0;
            font-size: 14px;
            font-weight: 700;
            color: #1c2b48;
            line-height: 16px;
          }
          .upload-text {
            .thumb {
              min-width: 16px;
              height: 16px;
              border: 2px solid #fff;
              border-radius: 50%;
            }

            font-weight: 400;
            font-size: 14px;
            color: rgba(28, 43, 72, 0.5);
            display: flex;
            align-items: center;

            .username {
              max-width: calc(100% - 200px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #1c2b48;
            }
          }
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }
  }
}
