.add-address {
  border-radius: 10px;
  overflow: hidden;
  .ant-form-item {
    margin: 0 !important;
  }
  .fields-title {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    color: #363645;
    margin-bottom: 5px;
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px !important;
  }
}
