.documents-datatable {
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  background: #fff;
  padding-bottom: 10px;
  margin-top: 24px;

  .infinite-scroll-component {
    min-height: 300px;
  }

  .tbl-col-1 {
    width: 24% !important;
    justify-content: flex-start !important;
  }

  .tbl-col-2 {
    width: 10% !important;
  }

  .tbl-col-3 {
    width: 18% !important;
  }

  .tbl-col-4 {
    width: 12% !important;
  }

  .tbl-col-5 {
    width: 12% !important;
  }

  .tbl-col-6 {
    width: 18% !important;
  }

  .tbl-col-7 {
    width: 6% !important;
  }

  &.deleted {
    .tbl-col-1 {
      width: 30% !important;
    }

    .tbl-col-2 {
      width: 13% !important;
    }

    .tbl-col-3 {
      width: 21% !important;
    }

    .tbl-col-4 {
      width: 15% !important;
    }

    .tbl-col-5 {
      width: 12% !important;
    }

    .tbl-col-7 {
      width: 6% !important;
      justify-content: flex-end !important;
    }
  }

  .table-head {
    border-bottom: 1px solid rgba(28, 43, 72, 0.2);
    display: flex;
    align-items: center;

    .head-ttl {
      display: block;
      padding: 12px 5px;
      font-weight: 600 !important;
      font-size: 14px !important;
      color: #1c2b48;

      span {
        position: relative;
        display: inline-flex;
        align-items: center;
      }

      svg {
        position: absolute;
        right: -15px;
      }

      &.active {
        .sort-icon {
          color: #6f7ced;
        }
      }

      &.tbl-col-1 {
        padding-left: 24px;
      }
    }
  }

  .table-body {
    .body-row {
      position: relative;

      .detail-row {
        width: calc(100% + 15px);
        display: flex;
        position: relative;

        .body-col {
          position: relative;
          padding: 15px 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // overflow: hidden;
          user-select: none;
          height: 65px;

          .no-data {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #1c2b48;
            margin: 0;
            display: flex;
            align-items: center;
          }

          .tbl-detail {
            font-family: "Lato";
            font-weight: 400;
            font-size: 12px;
            color: #1c2b48;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .sort-btn {
              height: 5px;
              width: 9px;
              margin-left: 5px;
            }

            &.active {
              color: #6f7ced;

              path {
                fill: #6f7ced;
              }
            }
          }

          .doc-thumb {
            min-width: 37px;
            height: 37px;
            background: #fff;
            margin-right: 12px;
            border-radius: 6px;
            overflow: hidden;
            z-index: 6;

            span {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(111, 124, 237, 0.1);

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }

          .doc-title {
            font-family: "Lato";
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #1c2b48;
            // overflow: hidden;
            // -webkit-line-clamp: 2;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // line-break: strict;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
          }

          .uploaded-by {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;

            .thumb {
              height: 25px;
              width: 25px;
            }

            p {
              margin: 0;
              font-family: "Lato";
              font-weight: 400;
              font-size: 12px;
              color: #1c2b48;
              width: calc(100% - 35px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block;
            }
          }

          .visible-to {
            height: 100%;
            display: flex;
            align-items: center;
          }

          &.tbl-col-1 {
            padding-left: 24px;
          }
        }

        &.sec {
          .tbl-col-1 {
            padding-left: 72px;
          }

          .body-col .doc-title {
            font-size: 12px;
          }
        }

        &.third {
          .tbl-col-1 {
            padding-left: 82px;
          }

          .body-col .doc-title {
            font-size: 12px;
          }
        }
      }

      .expanded-box {
        padding-bottom: 30px;
        margin-top: -10px;
        position: relative;

        &::before {
          content: "";
          width: 1px;
          height: calc(100% - 62px);
          position: absolute;
          background: rgba(28, 43, 72, 0.1);
          left: 43px;
          top: 0;
          pointer-events: none;
        }

        .expanded-box:last-child {
          &::after {
            content: "";
            position: absolute;
            background: #fff;
            left: 43px;
            top: -22px;
            width: 1px;
            height: 100%;
            pointer-events: none;
          }
        }

        .expanded-box {
          &::before {
            left: 57px;
            top: -22px;
            height: calc(100% - 10px);
          }

          .expanded-box::before,
          .expanded-box::after {
            content: none;
          }
        }

        .detail-row {
          &.sec {
            .tbl-col-1::before {
              width: 24px;
              content: "";
              height: 1px;
              position: absolute;
              background: rgba(0, 0, 0, 0.1);
              left: 44px;
            }
          }

          &.third {
            .tbl-col-1::before {
              width: 17px;
              content: "";
              height: 1px;
              position: absolute;
              background: rgba(0, 0, 0, 0.1);
              left: 58px;
            }
          }
        }
      }

      .expanded-box .expanded-box {
        padding: 0;
      }

      &::before {
        position: absolute;
        bottom: 0;
        content: "";
        background: rgba(28, 43, 72, 0.2);
        width: calc(100% - 15px);
        height: 1px;
        left: 15px;
        z-index: 6;
      }

      &:last-child {
        border-color: transparent;
      }
    }
  }
}
