.changenumber-modal {
  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 12px;
      padding: 24px 45px;
      border-color: rgba(28, 43, 72, 0.1);
    }
    .form-control::placeholder {
      font-family: "Lato";
      font-weight: 500;
      font-size: 16px;

      color: #1c2b48;

      opacity: 0.2;
    }
    .flag-dropdown {
      border-radius: 50px;
      border: none;
      margin: 5px;
      background: transparent;
    }
    .flag-dropdown:hover {
      background: transparent !important;
    }
    .open {
      z-index: 3 !important;
    }
  }
  .ant-modal-content {
    box-shadow: 0px 13px 40px rgba(6, 16, 28, 0.29) !important;
    border-radius: 22px !important;

    .ant-modal-body {
      padding: 28px 38px;

      .title {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #1c2b48;
        margin: 0;
      }

      .sub-title {
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-top: 3px;
        color: #1c2b48;
        opacity: 0.5;
      }

      .user-number {
        margin: 0;
        margin-top: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        color: #1c2b48;
        opacity: 0.7;
        font-family: "Lato";
      }

      .input-form {
        margin-top: 10px;

        &.mt-32 {
          margin-top: 32px;
        }
        .resend-wrapper {
          margin: 0;
          margin-top: 5px;
        }

        .ant-input-affix-wrapper,
        .PhoneInput {
          border: 1px solid rgba(28, 43, 72, 0.1);
          border-radius: 12px;
          padding: 5px 12px;
          padding-right: 20px;
          &.ant-input-affix-wrapper-status-error {
            border-color: #ff4d4f !important;
          }
          &:focus {
            border-color: #6f7ced !important;
            box-shadow: none !important;
          }

          .input-icon {
            color: #1c2b48;
            height: 20px;
          }

          ::placeholder {
            opacity: 0.2 !important;
          }

          input,
          input::placeholder {
            font-family: "Lato";
            font-weight: 500 !important;
            font-size: 16px;
            line-height: 19px;
            color: #1c2b48 !important;
          }
        }
      }

      .submit-wrapper {
        margin-top: 40px;

        .submit-btn {
          width: 139px;
          min-height: 43px;
          border-radius: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #fcfbfe;
        }
      }
      .formatted-phone {
        .react-tel-input {
          .selected-flag {
            padding: 0;
            background: transparent;
          }
          .form-control {
            font-weight: 500;
            font-family: "Lato";
            font-size: 16px;
            color: #1c2b48;
            opacity: 0.7;
            border: none;
            padding: 0 0 0 30px;
            cursor: default;
          }
        }
      }
    }
  }
}
