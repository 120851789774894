@use "../../../../../master.scss";

.requests-list-modal {
  border-radius: 22px;
  box-shadow: 0px 13px 40px 0px rgba(6, 16, 28, 0.29);
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 30px;

    .modal-body {
      h3,
      p {
        margin: 0;
      }
      .title {
        margin-bottom: 32px;

        h3 {
          font-family: "Lato";
          font-weight: 700;
          font-size: 20px;
          line-height: 22px;
          color: #1c2b48;
        }
      }

      .close-btn {
        position: absolute;
        right: 30px;
        top: 30px;
        background: transparent;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .request-list {
        max-height: 345px;
        overflow-y: auto;
        padding-right: 5px;
        .list-wrapper {
          border-radius: 10px;
          border: 1px solid #f06b6b;
          .list-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 14px 25px 14px 17px;
            border-bottom: 1px solid #f06b6b;
            background: rgba(240, 107, 107, 0.1);
            gap: 10px;
            position: relative;

            .item-logo {
              background: rgba(111, 124, 237, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              padding: 10px;
            }
            h3,
            p {
              font-family: "Poppins";
            }
            h3 {
              font-size: 12px;
              font-weight: 500;
            }
            p {
              font-weight: 400;
              font-size: 10px;
            }
            .nav-arrow {
              position: absolute;
              right: 25px;
              opacity: 0.6;
            }

            &:last-child {
              border: none;
            }
          }
        }

        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          //   box-shadow: inset 0 0 3px grey;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgb(169, 169, 169);
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgb(144, 144, 144);
        }
      }
    }
  }
}
