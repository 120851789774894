.chat-details-modal {
  border-radius: 10px;
  overflow: hidden;

  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .ant-modal-body {
    position: relative;
    padding: 33px;

    .modal-body {
      .info-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(28, 43, 72, 0.1);
        .left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          width: 100%;
          max-width: calc(100% - 60px);

          .thumb {
            height: 51px;
            min-width: 51px;
            max-width: 51px;
          }
          .room-name-wrapper {
            max-width: calc(100% - 10px);
            overflow: hidden;
            .date,
            .name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .date {
              color: rgba(28, 43, 72, 0.5);
              font-family: "Lato";
              font-size: 14px;
              font-weight: 400;
              margin: 0;
            }
            .name {
              color: #1c2b48;
              font-family: "Lato";
              font-size: 16px;
              font-weight: 700;
              margin: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        .c-p {
          min-width: 19px;
        }
      }
      .content-wrapper {
        border-radius: 10px;
        border: 1px solid rgba(28, 43, 72, 0.1);
        margin: 14px 0;
        display: flex;
        flex-direction: column;
        h3 {
          padding: 12px 21px;
          border-bottom: 1px solid rgba(28, 43, 72, 0.1);
          color: #1c2b48;
          font-family: "Lato";
          font-size: 16px;
          font-weight: 700;
          margin: 0;
        }
        .wrapper {
          padding-top: 11px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          max-height: 150px;
          overflow-y: auto;
          overflow-x: hidden;
          margin-right: 5px;
          .link-item {
            padding: 0 11px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            width: 100%;
            .option {
              height: 15px;
              width: 15px;
            }
            .left {
              display: flex;
              align-items: center;
              gap: 8px;
              justify-content: center;
              max-width: calc(100% - 50px);
              svg {
                min-width: 36px;
                height: 36px;
              }
              .link-info-text {
                max-width: 100%;
                overflow: hidden;
                p {
                  color: #1c2b48;
                  font-family: "Lato";
                  font-size: 14px;
                  font-weight: 500;
                  margin: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                span {
                  .thumb {
                    min-width: 13px;
                    max-width: 13px;
                    height: 13px;
                  }
                  margin-top: 2px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  color: rgba(28, 43, 72, 0.5);
                  font-family: "Lato";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  gap: 4px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
      .block-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        button {
          padding: 13px 18px;
          background: transparent;
          border-radius: 12px;
          border: 1px solid #f06b6b;
          color: #f06b6b;
          text-align: center;
          font-family: "Lato";
          font-size: 16px;
          font-weight: 500;
          width: 50%;
          &:disabled {
            background-color: transparent !important;
            cursor: not-allowed;
          }
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }
  }
}
