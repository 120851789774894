.group-members-modal {
  border-radius: 22px;
  overflow: hidden;
  right: 20px;
  float: right;
  top: 0;
  bottom: 0;
  margin-top: auto !important;
  height: max-content;
  margin-bottom: auto !important;
  position: absolute !important;

  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .ant-modal-body {
    position: relative;
    padding: 14px 19px 26px 19px;

    .modal-body {
      .title {
        margin-bottom: 14px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h3 {
          color: #1c2b48;
          font-family: "Lato";
          font-size: 14px;
          font-weight: 700;
        }
      }

      .search-box {
        padding: 12px 18px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        svg {
          path {
            fill: rgba(166, 166, 166, 1);
          }
        }
        input {
          &::placeholder {
            color: rgba(166, 166, 166, 1) !important;
            font-family: "Lato";
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .people-wrapper {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: flex-start;
        // gap: 13px;
        margin-top: 13px;
        max-height: calc(100vh - 250px) !important;
        // overflow: auto;
        // padding-right: 5px;
        // width: 100%;

        .people-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 8px 0 4px;
          border-radius: 8px;
          margin-bottom: 13px;
          .group-member-options {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
              color: #1c2b48;
              font-family: "Lato";
              font-size: 13px;
              font-weight: 500;
              margin: 0;
            }
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 80%;
            gap: 6px;

            .user-thumb {
              position: relative;
              .admin-crown {
                position: absolute;
                top: 0;
                right: 0;
              }
              .thumb {
                height: 32px;
                width: 32px;

                .blocked-icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }

            .name {
              max-width: calc(100% - 40px);
              overflow: hidden;

              h3,
              p {
                font-family: "Lato";
                color: #1c2b48;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              h3 {
                font-size: 14px;
                font-weight: 600;
              }

              p {
                font-size: 10px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .modal-options-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9px;
        align-self: flex-start;
        svg {
          width: 23px;
          height: 23px;
          cursor: pointer;
        }
      }
    }
  }
}
