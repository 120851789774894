@use "../../../../master.scss";

.enterprise-admin-management {
  padding: 0 20px;

  .title-wrapper {
    margin: 35px 0 30px;

    h3 {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      color: #1c2b48;
      font-family: "Lato";
      margin: 0;
    }

    p {
      margin: 0;
      font-family: "Lato";
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1c2b48;
      opacity: 0.5;
    }
  }

  .card {
    padding: 12px 16px;
    background: #ffffff;
    border-radius: 10px;

    &.admins-list {
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .admin-box {
          position: relative;
          padding: 18px 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 6px;
          cursor: pointer;
          width: 100%;

          .thumb {
            width: 46px;
            height: 46px;
          }

          .detail {
            margin-left: 15px;
            width: calc(100% - 61px);

            * {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            h4 {
              margin: 0;
              font-family: "Lato";
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-transform: capitalize;
              color: #1c2b48;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              line-break: strict;
              word-break: break-word;
            }

            p {
              margin: 0;
              font-family: "Lato";
              font-weight: 400;
              font-size: 16px;
              line-height: 14px;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              color: #93969e;
              white-space: nowrap;
            }
          }

          &::before {
            content: "";
            height: 1px;
            width: calc(100% - 40px);
            background: rgba(28, 43, 72, 0.1);
            position: absolute;
            bottom: 0;
          }

          &:last-child::before {
            background: none;
          }

          &.active {
            background: rgba(232, 234, 237, 0.5);

            &::before {
              background: none;
            }
          }

          @media (max-width: 1194px) {
            width: 50%;
          }
        }
      }
    }

    &.admin-detail {
      padding: 29px 34px;
      position: sticky;
      top: 20px;

      h3 {
        border-bottom: 1px solid #dddddd;
        padding-bottom: 23px;
        font-family: "Lato";
        font-weight: 700;
        font-size: 20px;
        line-height: 21px;
        color: #1c2b48;
        margin: 0;
      }

      .profile-detail {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        .deactivated {
          color: #f06b6b;
          margin: 0;
        }
        .profile-box {
          margin-right: 45px;
          width: 74px;

          .profile-pic {
            width: 74px;
            height: 74px;
            cursor: default;
          }

          .social-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 100%;
            margin-top: 10px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .title {
          font-family: "Lato";
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;
          color: #363645;
          margin-bottom: 10px;
          margin-top: 20px;
          margin-bottom: 8px;
        }

        .data {
          margin: 0;
          font-family: "Lato";
          font-weight: 400;
          font-size: 14px !important;
          line-height: 15px;
          color: #93969e;
          overflow: hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-break: strict;
          word-break: break-word;
        }

        .react-tel-input {
          .form-control {
            margin: 0;
            font-family: "Lato";
            font-weight: 400;
            font-size: 14px !important;
            line-height: 15px;
            color: #93969e;
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-break: strict;
            word-break: break-word;
          }
        }

        .detail-wrapper {
          width: calc(100% - 120px);

          .detail-box {
            h5 {
              font-family: "Lato";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 21px;
              color: #363645;
              margin-bottom: 10px;
            }

            p,
            span {
              font-family: "Lato";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 21px;
              color: #93969e;
              overflow: hidden;
              display: block;
              width: 100%;
              // white-space: nowrap;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              line-break: strict;
              word-break: break-word;

              .react-tel-input {
                pointer-events: none;
                padding-top: 4px;

                .form-control {
                  width: 100%;
                  border-radius: 12px;
                  padding-left: 25px;
                  border: none;
                  font-family: "Lato";
                  font-weight: 400;
                  font-size: 13px !important;
                  color: #93969e;
                  margin-bottom: 0;
                  height: auto;
                }

                .flag-dropdown {
                  border-radius: 50px;
                  padding-top: 2px;
                  border: none;
                  margin: 0;
                  background: transparent !important;

                  .arrow {
                    display: none;
                  }

                  .selected-flag {
                    padding: 0;
                    background: none !important;
                  }
                }
              }
            }
          }
        }

        @media (max-width: 1194px) {
          flex-direction: column;

          .detail-wrapper {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}
