@use "../../../../master.scss";

.enterprise-setting-wrapper {
  padding: 0 20px 20px;

  .title-wrapper {
    margin: 35px 0 30px;

    h3 {
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      color: #1c2b48;
      font-family: "Lato";
      margin: 0;
    }

    p {
      margin: 0;
      font-family: "Lato";
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #1c2b48;
      opacity: 0.5;
    }
  }

  .management-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
    width: 100%;

    h4 {
      margin: 0;
      font-family: "Lato";
      font-weight: 700;
      font-size: 34px;
      color: #1c2b48;
    }
  }

  .company-profile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .company-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;

      .company-logo-wrapper {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        padding-bottom: 10px;
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        align-items: flex-start;

        .company-logo {
          height: 115px;
          width: 115px;
          border-radius: 50%;
        }

        h3 {
          font-family: "DM Sans";
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.02em;
          margin-top: 10px;
          color: #1c2b48;
        }
      }

      .social-icons-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 20px;

        svg {
          margin: 0;
        }
      }

      .detail-box {
        width: calc(100% - 30px);
        a,
        p,
        h5,
        span {
          // max-width: 450px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h5 {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: #363645;
          margin-bottom: 13px;
        }

        p,
        span {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: #93969e;
          overflow: hidden;
          display: block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;

          .react-tel-input {
            pointer-events: none;

            .form-control {
              width: 100%;
              border-radius: 12px;
              padding-left: 25px;
              border: none;
              font-family: "DM Sans";
              font-weight: 400;
              font-size: 16px;
              color: #93969e;
              margin-bottom: 0;
              height: auto;
            }

            .flag-dropdown {
              border-radius: 50px;
              border: none;
              margin: 0;
              background: transparent !important;

              .arrow {
                display: none;
              }

              .selected-flag {
                padding: 0;
                background: none !important;
              }
            }
          }
        }
      }
    }

    .future-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 25px;
      padding: 20px 50px;
      height: 100%;

      h3 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 22px;
        line-height: 31px;

        text-align: center;

        color: #6f7ced;
      }
    }
  }
}
