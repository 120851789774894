.question-prompt-modal {
  border-radius: 10px;
  overflow: hidden;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    gap: 5px;
    h3 {
      margin-top: 15px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
      color: #1c2b48;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #1c2b48;
    }
    .question-modal-btn-wrapper {
      display: flex;
      gap: 10px;
      .skip-btn {
        padding: 12px 32px;
        border-radius: 12px;
        font-size: 16px;
      }
    }
  }
}
