.ant-form-item-has-error {
  .other-select-input-box {
    &,
    .options-wrapper {
      border-color: #ff4d4f !important;
    }
  }
}

.other-select-input-box {
  border-radius: 10px;
  background-color: #f8fafb;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  font-size: 14px;

  .placeholder-box {
    padding: 13px 24px;
    color: #9b9b9b;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      position: relative;
      left: 5px;
      font-size: 15px;
      color: #1c2b48;
      transition: 0.3s all;
    }

    &.active {
      color: #1c2b48;
    }
  }

  .other-dropdown-wrapper {
    position: relative;

    .options-wrapper {
      left: -1px;
      background-color: #f8fafb;
      border: 1px solid #6f7ced;
      border-top: 0;
      position: absolute;
      width: calc(100% + 2px);
      z-index: 999;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .options-box {
        position: relative;
        height: 192px;
        .custom-scroll-wrapper {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
        li {
          padding: 6px 24px;
          font-size: 13px;
          color: #1c2b48;
          transition: 0.3s all;
          cursor: pointer;

          &:hover,
          &.selected {
            background: rgba(111, 124, 237, 0.1);
          }

          &.other {
            position: absolute;
            top: 0;
            width: 100%;

            .txt {
              width: 100%;
              display: block;
            }

            input {
              background: #f8fafb;
              border: 1px solid #6f7ced;
              border-radius: 10px;
              margin-top: 6px;
              margin-bottom: 12px;
              height: 36px;
            }

            &.active {
              background: rgba(111, 124, 237, 0.1);
            }
          }
        }
      }
    }
  }

  &.active {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0 !important;

    .placeholder-box svg {
      transform: rotate(-180deg);
    }
  }

  &:hover,
  &.active {
    border-color: #6f7ced;
  }

  &.disabled {
    border-color: #d9d9d9 !important;

    * {
      cursor: auto;
    }
  }
}
