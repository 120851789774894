.trannsaction-admin-wrapper {
  padding: 0 20px;

  .title-wrapper {
    margin: 38px 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-family: "Lato";
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      color: #1c2b48;
      margin: 0;
    }

    p {
      margin: 0;
      font-family: "Lato";
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1c2b48;
      opacity: 0.5;
    }

    .buttons-container {
      .active-btn {
        background: #6f7ced;

        p {
          color: #ffffff;
        }
      }

      p {
        font-family: "Lato";
        margin: 0;
        font-size: 16px;
        opacity: 1;
      }
    }
  }

  .settings-wapper {
    .tabs-container {
      margin-bottom: 20px;

      .buttons-container > * {
        flex: 1;
      }

      h4 {
        font-family: "Lato";
        font-weight: 700;
        font-size: 24px;
        color: #1c2b48;
      }
    }

    .transaction-admin {
      // display: flex;
      // gap: 20px;
      .ant-radio-disabled {
        .ant-radio-inner {
          opacity: 0.7;
        }
      }

      .ant-radio-wrapper {
        span {
          text-transform: capitalize !important;
        }
      }
      .ant-radio-inner {
        background-color: #f2f5fa;
        border-color: rgba(28, 43, 72, 0.5);
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: transparent;
        background-color: #fff;
      }

      .ant-radio-checked .ant-radio-inner::after {
        background-color: #6f7ced;
      }

      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
        border-color: transparent !important;
      }

      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: #6f7ced;
      }

      .rooms-list {
        background: #ffffff;
        border-radius: 10px;
        padding: 10px;
        max-height: 600px;
        overflow-y: auto;
        margin-bottom: 30px;
        &::-webkit-scrollbar {
          display: none;
        }

        .user-card {
          .thumb {
            min-width: 46px;
            max-width: 46px;
          }
          .info {
            max-width: calc(100% - 46px);
            overflow: hidden;
          }
          h4 {
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-break: strict;
            word-break: break-word;
          }
        }
      }

      .links {
        flex: 1;
        margin-bottom: 30px;

        .link-heading {
          border-bottom: 1px solid rgb(28, 43, 72, 0.2);
          padding: 15px 0;
        }

        .link {
          padding: 15px;
          padding-left: 40px;
          margin-inline: -20px;
        }

        .link-background {
          background-color: #f2f5fa;
        }

        .radio-wrap {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          align-items: center;
        }
      }

      h4,
      p {
        margin-bottom: 0;
        font-family: "Lato";
        color: #1c2b48;
      }

      h4 {
        font-weight: 500;
        font-size: 15px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        opacity: 0.5;
      }

      .user-card {
        gap: 13px;
        padding: 20px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .thumb {
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }

        // div {
        //   padding-right: 70px;
        // }
      }

      .active {
        background-color: rgb(232, 234, 237, 0.5);
      }

      .horizontal-line {
        margin: 0;
        margin-inline: 30px;
        &:last-child {
          display: none;
        }
      }

      .select-all {
        padding: 10px 20px;
      }

      .ant-checkbox + span {
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
        color: #1c2b48;
      }
    }
  }

  .admins-wrapper {
    .card {
      padding: 12px 16px;
      background: #ffffff;
      border-radius: 10px;

      &.transaction-admins-list {
        .transaction-admin-box {
          position: relative;
          padding: 18px 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 6px;
          cursor: pointer;

          .thumb {
            min-width: 46px !important;
            max-width: 46px;
            height: 46px;
          }

          .detail {
            margin-left: 15px;
            max-width: calc(100% - 46px);
            overflow: hidden;
            h4 {
              margin: 0;
              font-family: "Lato";
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-transform: capitalize;
              color: #1c2b48;
              overflow: hidden;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              line-break: strict;
              word-break: break-word;
            }

            p {
              margin: 0;
              font-family: "Lato";
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              color: #93969e;
            }
          }

          &::before {
            content: "";
            height: 1px;
            width: calc(100% - 40px);
            background: rgba(28, 43, 72, 0.1);
            position: absolute;
            bottom: 0;
          }

          &:last-child::before {
            background: none;
          }

          &.active {
            background: rgba(232, 234, 237, 0.5);

            &::before {
              background: none;
            }
          }
        }
      }

      &.admin-detail {
        padding: 29px 34px;
        position: sticky;
        top: 80px;

        h3 {
          border-bottom: 1px solid #dddddd;
          padding-bottom: 23px;
          font-family: "Lato";
          font-weight: 700;
          font-size: 20px;
          line-height: 21px;
          color: #1c2b48;
          margin: 0;
        }

        .profile-detail {
          display: flex;
          margin-top: 15px;
          justify-content: space-between;
          .deactivated {
            color: #f06b6b;
            margin: 0;
          }
          .profile-box {
            margin-right: 45px;
            width: 74px;

            .profile-pic {
              width: 74px;
              height: 74px;
              cursor: default;
            }

            .social-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              width: 100%;
              margin-top: 10px;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }

          .title {
            font-family: "Lato";
            font-weight: 700;
            font-size: 13px;
            line-height: 15px;
            color: #363645;
            margin-bottom: 10px;
            margin-top: 20px;
            margin-bottom: 8px;
          }

          .data {
            margin: 0;
            font-family: "Lato";
            font-weight: 400;
            font-size: 14px !important;
            line-height: 15px;
            color: #93969e;
            overflow: hidden;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }

          .react-tel-input {
            .form-control {
              margin: 0;
              font-family: "Lato";
              font-weight: 400;
              font-size: 14px !important;
              line-height: 15px;
              color: #93969e;
              overflow: hidden;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }

          .detail-wrapper {
            width: calc(100% - 120px);

            .detail-box {
              h5 {
                font-family: "Lato";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #363645;
                margin-bottom: 10px;
              }

              p,
              span {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                color: #93969e;
                overflow: hidden;
                display: block;
                width: 100%;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                line-break: strict;
                word-break: break-word;

                .react-tel-input {
                  pointer-events: none;
                  padding-top: 4px;

                  .form-control {
                    width: 100%;
                    border-radius: 12px;
                    padding-left: 25px;
                    border: none;
                    font-family: "Lato";
                    font-weight: 400;
                    font-size: 13px !important;
                    color: #93969e;
                    margin-bottom: 0;
                    height: auto;
                  }

                  .flag-dropdown {
                    border-radius: 50px;
                    padding-top: 2px;
                    border: none;
                    margin: 0;
                    background: transparent !important;

                    .arrow {
                      display: none;
                    }

                    .selected-flag {
                      padding: 0;
                      background: none !important;
                    }
                  }
                }
              }
            }
          }

          @media (max-width: 1194px) {
            flex-direction: column;

            .detail-wrapper {
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
