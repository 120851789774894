.room-select-wrapper {
  border: 1px solid #6f7ced;
  border-radius: 10px;
  background: #f8fafb;
  padding-right: 3px;
  p,
  h3 {
    font-family: "Lato";
  }
  .selector-wrapper {
    padding: 12.5px 8px 12.5px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1c2b48;
      margin: 0;
    }
    .selected-room {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      .thumb {
        height: 18px;
        width: 18px;
      }
      h3 {
        font-size: 11px;
        margin: 0;
        font-weight: 600;
      }
    }
  }
  .room-dropdown {
    user-select: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(169, 169, 169);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(144, 144, 144);
    }
    .room-option {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 6px 25px;
      gap: 11px;
      .thumb {
        height: 35px;
        width: 35px;
      }
      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
      }
    }
    .even-option {
      background: rgba(112, 126, 174, 0.05);
    }

    .selected-room-option {
      padding: 13px 16px;
      background: #6f7ced;
      .option-top {
        padding: 0 9px 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 11px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin-bottom: 5px;
        .thumb {
          height: 35px;
          width: 35px;
        }
        h3 {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          margin: 0;
        }
      }
      .option-bottom {
        p {
          font-weight: 400;
          font-size: 11.4px;
          line-height: 14px;
          color: #ffffff;
        }
        .option-visibility {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .proceed-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            color: #fff;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            padding: 4.5px 25px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.select-open {
  background: #ffffff;
  svg {
    transform: rotate(180deg);
    path {
      fill: #6f7ced;
    }
  }
  .selector-wrapper {
    .placeholder {
      font-family: "DM Sans";
      color: #707eae;
      font-weight: 500;
    }
  }
}
