.create-breakout-room {
  border-radius: 22px;
  overflow: hidden;

  .ant-form-item {
    margin: 0 !important;
  }

  .ant-modal-body {
    position: relative;
    padding: 28px 30px 20px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      color: #1c2b48;
    }

    .add-user-label {
      margin-left: 5px;
      font-family: "Lato";
    }

    .modal-body {
      .title {
        margin-bottom: 20px;

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }

      .selected-partymembers {
        display: flex;
        overflow-x: auto;
        position: relative;
        padding-bottom: 10px;

        .member-box {
          margin-top: 20px;
          padding: 7px 10px;
          position: relative;
          background: #f2f5fa;
          border-radius: 10px;
          min-width: 160px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          font-size: 12px;
          margin-right: 10px;

          .thumb {
            min-width: 30px;
            height: 30px;
            border-radius: 50px;
            overflow: hidden;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 5px;

            img {
              border-radius: 20px;
            }

            .blocked-icon {
              width: 8px;
              height: 8px;
            }
          }

          .close-btn {
            right: -8px;
            top: -6px;
            width: 18px;
            min-height: 18px;
            font-size: 11px;
            display: flex;
          }

          .user-details {
            display: flex;
            flex-direction: column;

            b {
              color: #1c2b48;
              max-width: 90px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p {
              margin: 0;
              font-size: 10px;
              color: rgba(28, 43, 72, 0.5);
              max-width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .selected-partymembers::-webkit-scrollbar {
        display: none;
      }

      .ant-select-selector,
      .ant-picker,
      .ant-input {
        width: 100%;
        background: #f8fafb !important;
        height: 44px !important;
        border: 1px solid rgba(28, 43, 72, 0.1) !important;
        border-radius: 10px !important;
      }
    }

    .modal-footer {
      margin-top: 15px;
      text-align: right;

      button {
        border-radius: 12px;
        padding: 12px;
        background: #6f7ced;
        font-weight: 500;
        font-size: 16px;
        min-width: 160px;
        color: #fff;

        &:hover {
          background: #535ec2;
        }
      }
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .select-form-item {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 6px;

    .ant-form-item-explain {
      margin-top: 4px;
    }
  }

  .ant-select-arrow {
    color: #1c2b48;
  }
}

.create-breakout-tooltip {
  padding-top: 5px !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border: 1px solid #535ec2;
    border-radius: 10px;
    border-top-left-radius: 0px;
    color: #1c2b48;
    margin-left: 10px;
    margin-bottom: 15px;
  }
}

.company-select-options {
  display: flex;
  align-items: center;
  gap: 15px;

  .thumb {
    width: 30px;
    height: 30px;
    border-radius: 20px;
  }

  p {
    margin: 0;
  }
}