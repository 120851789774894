.doc-visiblity-wrapper {
  margin-top: 17px;
  max-height: 350px;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
    margin-right: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .doc-visiblity-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px 32px 9px 30px;
    cursor: default;

    &.active {
      background: rgba(86, 158, 146, 0.1);
    }

    .visibility-left-wrapper {
      display: flex;
      align-items: center;

      .thumb {
        height: 35px;
        width: 35px;
        cursor: default;
      }

      .visibility-info {
        margin-left: 11px;

        h3,
        p {
          font-family: "Lato";
          margin: 0;
        }

        h3 {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
        }

        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #569e92;
          opacity: 1;
          text-transform: uppercase;
        }
      }
    }

    .visibility-cursor {
      height: 9px;
      width: 9px;
      transform: rotate(180deg);
    }
  }
}
.history-dropdown {
  border: 1px solid #6f7ced;
  border-radius: 0px 10px 10px;
  overflow: hidden;
  width: 300px !important;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
  .ant-dropdown-menu {
    padding: 0;
    margin: 0 !important;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    cursor: default !important;
  }
  .room-option {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #1c2b48;
    opacity: 0.8;
    padding: 8px 15px;
    display: block;
    align-items: center;
    background: #f8fafb;
    line-break: strict;
    word-break: break-word;

    &.active {
      font-size: 10px;
      opacity: 1;
      background: rgba(111, 124, 237, 0.1);

      .thumb {
        width: 11px;
        height: 11px;
        margin: 0 2px;
        position: relative;
        bottom: 1px;
        display: inline-flex;
        top: 1px;
      }
    }
  }
}
