.direct-modal {
  border-radius: 22px;
  overflow: hidden;

  *::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 3px grey;
    border-radius: 10px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: rgb(144, 144, 144);
  }

  .ant-modal-body {
    position: relative;
    padding: 33px;

    .modal-body {
      .title {
        margin-bottom: 30px;

        h3 {
          color: #1c2b48;
          font-family: "Lato";
          font-size: 18px;
          font-weight: 700;
        }
      }

      .search-input {
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(28, 43, 72, 0.1);
        background: #f8fafb;
        padding: 13px 24px;

        &::placeholder {
          color: #1c2b48;
          font-family: "Lato";
          font-size: 14px;
          font-weight: 500;
        }
      }

      .people-wrapper {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: flex-start;
        // gap: 9px;
        margin-top: 13px;
        // max-height: 250px;
        // overflow: auto;
        // padding-right: 5px;
      }

      .people-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 22px 8px 16px;
        border-radius: 8px;
        background: #f2f5fa;
        margin-bottom: 10px;

        .info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 80%;
          gap: 6px;

          .thumb {
            height: 32px;
            width: 32px;

            .blocked-icon {
              width: 12px;
              height: 12px;
            }
          }

          .name {
            max-width: calc(100% - 40px);
            overflow: hidden;

            h3,
            p {
              font-family: "Lato";
              color: #1c2b48;
              margin: 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            h3 {
              font-size: 14px;
              font-weight: 600;
            }

            p {
              font-size: 10px;
              font-weight: 400;
              opacity: 0.5;
            }
          }
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
    }
  }
}