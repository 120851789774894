.chat-list-container {
  padding: 16px 10px;
  border-radius: 12px;
  border: 1px solid rgba(67, 68, 69, 0.1);
  background: #fff;
  height: 100%;
  .trans {
    width: 50%;
  }
  .search-box {
    padding: 13px 18px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    svg {
      path {
        fill: rgba(166, 166, 166, 1);
      }
    }
  }
  .my-groups {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 13px 0;
    p {
      color: #1c2b48;
      font-family: "Lato";
      font-size: 15px;
      font-weight: 700;
      margin: 0;
    }
    svg {
      height: 18px;
      width: 18px;
    }
  }
  .chat-list {
    // display: flex;
    // flex-direction: column;
    margin-top: 11px;
    // max-height: 550px;
    // padding-right: 5px;
    // overflow: auto;
    .horizontal-line {
      min-height: 1px;
      margin: 0;
    }
    .chat-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 11px 7px;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.selected {
        border-radius: 6px;
        background: rgba(111, 124, 237, 0.1);
        // border-color: transparent;
      }
      h3,
      p {
        margin: 0;
        color: #434445;
        font-family: "Lato";
      }
      .user-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 75%;
        gap: 11px;
        .thumb {
          width: 50px;
          height: 50px;
        }
        .name {
          max-width: calc(100% - 60px);
          overflow: hidden;
          h3,
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          h3 {
            font-size: 14px;
            font-weight: 600;
          }
          p {
            font-size: 10px;
            font-weight: 400;
            span {
              font-style: italic;
              svg {
                height: 10px;
                width: 10px;
              }
            }
          }
        }
      }
      .time {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 11px;
        p {
          font-size: 10px;
          font-weight: 400;
        }
        span {
          color: #fff;
          background: #6f7ced;
          font-size: 7px;
          border-radius: 50%;
          height: 16px;
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
