.transaction-detail-documents {
  margin: 40px 35px;
  .card {
    border: 1px solid rgba(28, 43, 72, 0.1);
  }
  .top-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .doc-btn-container {
      display: flex;
      gap: 10px;

      button {
        border: 1px solid #6f7ced;
        padding: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;
        border-radius: 12px;

        &.share-btn {
          background: transparent;
          color: #6f7ced;
        }
      }
    }

    &.subpage-header {
      justify-content: flex-start;
      gap: 15px;

      h3 {
        font-size: 18px;
        margin: 0;
        color: #1c2b48;
        line-height: 30px;
      }

      p {
        margin: 0;
        line-height: 17px;
      }
    }
  }

  .deleted-btn {
    width: 100%;
    padding: 12px 18px;
    display: inline;
    white-space: nowrap;
    font-family: "Lato";
    font-weight: 400;
    font-size: 14px;
    color: #1c2b48;
    background-color: rgba(28, 43, 72, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activtybox-wrapper {
    position: sticky;
    top: 110px;

    &.disabled {
      .activity-logs {
        opacity: 0.5;
        background: #e6e6e6 !important;
        pointer-events: none;
      }
    }
  }
}
