.submit-wrapper {
  display: flex;
  justify-content: flex-end;
}

.close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  background: #1c2b48;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50px;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.opt-container .email {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-transform: lowercase;
  color: #1c2b48;
  opacity: 0.7;
}

.otp-wrapper>div {
  gap: 10px;
  margin-bottom: 20px;
}

.resend-wrapper {
  margin-bottom: 15px;
}

.resend-wrapper span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #243d4c;
}

.resend-wrapper .resend-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #6f7ced;
  margin-right: 10px;
  cursor: pointer;
}

.confirm-pass-container .password-field {
  background: #f8fafb;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
}

.confirm-pass-container .password-field>input {
  background-color: transparent !important;
  border: none !important;
}

.two-factor-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  width: 100%;
  padding-bottom: 15px;

  .two-factor-options-items {
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    user-select: none;
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid rgba(122, 122, 124, 0.5);

    p {
      margin: 0;
      color: #1c2b48;
      font-family: "Lato";
      font-weight: 500;
      font-size: 14px;
      transition: color 0.1s;
    }

    .react-tel-input {
      pointer-events: none;

      .form-control {
        width: 100%;
        border: none;
        font-family: "Lato";
        font-weight: 500;
        font-size: 14px;
        padding: 0;
        color: #1c2b48;
        margin-bottom: 0;
        background: none;
        height: auto;
      }

      .flag-dropdown {
        display: none;
      }
    }
  }

  .two-factor-options-items-selected {
    background-color: #6f7ced;
    border-color: #6f7ced;

    p,
    .form-control {
      color: #fff !important;
    }
  }
}

.confirm-pass-container,
.opt-container {
  .ant-form-item {
    margin-bottom: 10px !important;
  }

  .heading {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1c2b48;
    margin-bottom: 2px;
  }

  .description {
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    color: rgb(28, 43, 72, 0.5);
  }

  .heading-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    svg {
      cursor: pointer;
    }
  }

  .submit-btn {
    padding: 10px 50px;
    border-radius: 12px;
    margin-bottom: 0px;
  }
}