.message-item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;

  // .margin-img {
  //   margin-bottom: 34px !important;
  // }
  .message-content-wrapper {
    // min-width: 200px;
    max-width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .deets {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      width: 100%;
      gap: 10px;

      .thumb {
        min-width: 32px;
        max-width: 32px;
        height: 32px;
      }
    }
    .message-option {
      position: absolute;
      top: 13px;
      right: -14px;
      width: 4px;
      height: 20px;
    }

    .message-react-options {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 9px;
      border-radius: 7px;
      border: 1px solid #c0c0c0;
      background: #fff;
      gap: 6px;
      position: absolute;
      top: -30px;

      right: 0;
      .emoji-wrapper {
        display: flex;
        position: unset;

        .emoji-dropdown-wrapper {
          position: absolute;
          top: -440px;
          right: -308px;
          z-index: 1;
          width: max-content;
        }
      }

      svg {
        height: 14px;
        width: 14px;

        &:hover {
          path {
            fill: #6f7ced;
          }
        }
      }
    }

    .message-box {
      width: 100%;
      border-radius: 10px 10px 10px 0;
      background: #6f7ced;
      padding: 12px 10px;
      color: #fff;
      font-family: "Lato";
      font-size: 14px;
      font-weight: 500;
      position: relative;
      white-space: pre-wrap;
      word-break: break-all;
      line-break: anywhere;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .attachment-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        flex-wrap: wrap;

        .attachment-img {
          border-radius: 8px;
          width: 80px;
          height: 80px;
          overflow: hidden;

          .ant-image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      .bubble-arrow {
        position: absolute;
        left: 3px;
        bottom: 14.5px;
        transform: rotate(90deg);
      }

      .bubble-arrow:after {
        content: "";
        position: absolute;
        border-top: 15px solid #6f7ced;
        border-left: 15px solid transparent;
        border-radius: 6px 0 6px 2px;
        width: 0;
        height: 0;
      }

      .message-content {
        color: #fff;
        font-family: "Lato", "color-emoji";
        font-size: 14px;
        font-weight: 500;

        a {
          text-decoration: underline;
          color: #fff;
        }
      }

      .link-preview-wrapper {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 4px;
        .link-preview {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          background: #fff;
          .preview-img {
            height: 60px;
            width: 60px;
            object-fit: cover;
          }
          .preview-info {
            max-width: calc(100% - 60px);
            overflow: hidden;
            h3,
            p {
              margin: 0;
              color: #1c2b48;
              font-family: "Lato";
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            h3 {
              font-size: 14px;
              font-weight: 500;
            }
            p {
              font-size: 12px;
              opacity: 0.5;
              font-weight: 400;
            }
          }
        }
      }
    }
    .date-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;

      width: 100%;
      gap: 20px;
      padding-left: 32px;
      .chat-reactions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 7px;
        margin-top: 5px;
        // position: absolute;
        // bottom: -35px;
        // left: 0;
        .react-item {
          font-family: "Lato", "color-emoji";
          color: #666666;
          border-radius: 6px;
          background: rgba(111, 124, 237, 0.1);
          padding: 5px 7px 4px 5px;
          font-size: 12px;
          min-width: max-content;
        }
        .other-react-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          max-height: 120px;
          overflow: auto;
          padding-right: 5px;
          .other-react-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 130px;
            margin: 5px 0;
            p {
              margin: 0;
              font-family: "Lato", "color-emoji";
              color: #666666;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .close {
              height: 12px;
              width: 12px;
            }
          }
        }
        .ant-popover-inner {
          border-radius: 12px !important;
          box-shadow: 0px 13px 40px 0px rgba(6, 16, 28, 0.29);
        }
      }
      .reply-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 11px 4px 6px;
        border-radius: 4px;
        background: #ddd;
        margin-top: 4px;
        .visible-people-list {
          margin: 0;
          .thumb,
          .view-more {
            width: 16px;
            height: 16px;
            margin-right: -7px;
            margin-bottom: 0;
          }
        }
        p {
          margin: 0 6px 0 12px !important;
          color: rgba(0, 0, 0, 0.5) !important;
          font-family: "Lato";
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    p {
      color: #1b1b1b;
      font-family: "Lato";
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      margin-top: 2px;
    }

    .chat-doc-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 10px;
      min-width: 130px;
      border: 1px solid rgba(28, 43, 72, 0.3);
      padding: 10px;
      border-radius: 10px;
      background: #fff;
      width: 100%;

      .logo {
        width: 32px;
        height: 32px;
        margin-bottom: 0;
        position: unset;
      }

      .fileName {
        margin: 0;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 14px;
        color: "option";
        max-width: calc(100% - 80px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .option {
        position: absolute;
        height: 15px;
        width: 15px;
        right: 10px;
        left: unset;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  &.sent {
    flex-direction: row-reverse;

    .message-content-wrapper {
      align-items: flex-end;
      .deets {
        flex-direction: row-reverse;
      }
      .date-wrapper {
        flex-direction: row;
        // flex-wrap: wrap-reverse;
        justify-content: flex-end !important;
        padding-right: 32px;
        padding-left: 0;
      }
      .message-option {
        right: unset;
        left: -14px;
      }

      .message-react-options {
        left: 0;
        right: unset;

        .emoji-wrapper {
          .emoji-dropdown-wrapper {
            position: absolute;
            top: -440px;
            left: -308px;
            right: unset;
            z-index: 1;
          }
        }
      }

      p {
        color: #3d4cbc;
      }

      .message-box {
        background: #3d4cbc;
        border-radius: 10px 10px 0 10px;
      }

      .bubble-arrow {
        right: -12px;
        left: unset;
        transform: rotate(180deg);
        bottom: 0;

        &::after {
          border-top: 15px solid #3d4cbc;
        }
      }
    }
  }
  &.thread-sent {
    .message-box {
      background: #3d4cbc;
    }
    .bubble-arrow {
      &::after {
        border-top: 15px solid #3d4cbc !important;
      }
    }
  }
}
