@use "../../../../../../../master.scss";

.remove-response-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .modal-body {
      .title {
        margin-bottom: 20px;

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          color: #1c2b48;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #1c2b48;
          opacity: 0.5;
        }
      }

      .close-btn {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #1c2b48;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50px;
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .ant-form-item {
        .ant-input {
          width: 100%;
          background: #f8fafb !important;
          height: 44px !important;
          border: 1px solid rgba(28, 43, 72, 0.1) !important;
          border-radius: 10px !important;

          &.textarea {
            height: 148px !important;
            resize: none;
          }
          border-color: #6f7ced !important;
        }
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
      margin-top: 25px;

      button {
        background: #6f7ced;
        font-weight: 600;
        font-size: 18px;
        color: #1c2b48;
        border-radius: 12px;
        padding: 12px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 16px;

        color: #fcfbfe;
      }
    }
  }
}
