.checklist-datatable {
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  background: #fff;
  padding-bottom: 10px;
  margin-top: 30px;

  .table-head {
    border-bottom: 1px solid rgba(28, 43, 72, 0.2);
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .head-ttl {
      display: block;
      padding: 12px 0;
      font-weight: 600 !important;
      font-size: 14px !important;
      color: #1c2b48;

      svg {
        margin-left: 10px;
      }

      &:first-child {
        width: 28%;
        padding-left: 15px;
      }

      &:nth-child(2) {
        width: 18%;
      }

      &:nth-child(3) {
        width: 17%;
      }

      &:nth-child(4) {
        width: 20%;
      }

      &:last-child {
        width: 17%;
      }

      &.active {
        .sort-icon {
          color: #6f7ced;
        }
      }
    }

    &.deleted .head-ttl {
      &:first-child {
        width: 38%;
      }

      &:nth-child(2) {
        width: 23%;
      }

      &:nth-child(3) {
        width: 22%;
      }
    }
  }

  .table-body {
    .body-row {
      border-bottom: 1px solid rgba(28, 43, 72, 0.2);
      display: flex;
      flex-wrap: wrap;

      .body-col {
        padding: 16px 5px;
        display: flex;
        align-items: center;

        .detail-col {
          max-width: calc(100% - 20px);
          overflow: hidden;
          .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #1c2b48;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .listed {
            margin-top: 1px;
            display: flex;
            align-items: center;

            .label {
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              color: #1c2b48;
              margin-right: 3px;
            }

            .thumb,
            .view-more {
              width: 18px;
              height: 18px;
              margin-right: -4px;
              border-width: 1px !important;
              line-height: normal;
            }
          }
        }

        .date {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.02em;
          color: #1c2b48;
          opacity: 0.5;
        }

        .action {
          margin-left: 14px;

          .three-dot-icon {
            display: flex;
          }
        }

        .restore-thumb {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 14px;
        }

        &:first-child {
          width: 28%;
          padding-left: 15px;
        }

        &:nth-child(2) {
          width: 18%;
        }

        &:nth-child(3) {
          width: 17%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:last-child {
          width: 17%;
        }
      }

      &.deleted .body-col {
        &:first-child {
          width: 38%;
        }

        &:nth-child(2) {
          width: 23%;
        }

        &:nth-child(3) {
          width: 22%;
        }
      }

      &:first-child {
        padding-top: 5px;
      }

      &:last-child {
        border-color: transparent;
      }
    }
  }
}
