.folder-card-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 24px;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 12px;
  gap: 17px;
  user-select: none;
  width: 100%;
  h3,
  p {
    color: #1c2b48;
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }
  .folder-info {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    h3 {
      font-size: 16px !important;
      font-weight: 700;
      max-width: calc(100% - 10px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      font-size: 16px;
      opacity: 0.5;
    }
  }
  .attachment-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    background: rgba(111, 124, 237, 0.2);
    padding: 10px 16px;
    border-radius: 9px;
    p {
      font-family: "Lato";
      font-weight: 700;
      font-size: 16px;
      color: #6f7ced;
      opacity: 1;
    }
  }
}
