.checklist-visibility-modal {
  border-radius: 22px;
  overflow: hidden;

  .ant-modal-body {
    position: relative;
    padding: 28px 30px;

    .title {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #1c2b48;
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        opacity: 0.5;
      }
    }

    .close-btn {
      position: absolute;
      right: 28px;
      top: 15px;
      background: #1c2b48;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50px;
      width: 23px;
      height: 23px;
      cursor: pointer;
    }

    .checklist-grps {
      margin: 0;
      margin-top: 5px;
      max-height: 480px;
      overflow-y: auto;
      padding-right: 5px;
      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        //   box-shadow: inset 0 0 3px grey;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(169, 169, 169);
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(144, 144, 144);
      }

      li {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1c2b48;
        .content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          max-width: calc(100% - 30px);
          overflow: hidden;
          .thumb {
            width: 32px;
            height: 32px;
            margin-right: 12px;
          }
          .info {
            max-width: calc(100% - 40px);
            overflow: hidden;
            p,
            h3 {
              margin: 0;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: "Lato";
              color: #1c2b48;
            }
            h3 {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
            }
            p {
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
            }
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .modal-footer {
    margin-top: 25px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .remove-all-btn {
      display: flex;
      justify-content: center;
      width: 50%;
      color: #f06b6b;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      cursor: pointer;
    }

    button {
      border-radius: 12px;
      padding: 12px;
      background: #6f7ced;
      font-weight: 500;
      font-size: 16px;
      min-width: 170px;
      color: #fff;

      &:hover {
        background: #535ec2;
      }
    }
  }
}
